import React from 'react';
import FlashSaleListStyle from './index.style';
import serviceFlashSale from '../index.service';
import { Link, useNavigate } from 'react-router-dom';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import ModalRemove from '../../../components/ModalRemove';
import moment from 'moment';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { notifyError } from '../../../utils';

const FlashSaleList = () => {
    const navigate = useNavigate(); // Renamed 'history' to 'navigate'
    const [dataFlashSale, setDataFlashSale] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
    });

    React.useEffect(() => {
        onLoadData();
    }, []);

    const onLoadData = async () => {
        setIsLoading(true);
        const results = await serviceFlashSale.listFlashSale(queryString);
        if (results.isSuccess) {
            setDataFlashSale(results.response);
            setIsLoading(false);
        } else {
            notifyError(results.response.message);
        }
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Judul Flashsale',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Mulai',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (item) => moment.utc(item).format('DD MMM YYYY HH:mm:ss'),
        },
        {
            title: 'Berakhir',
            dataIndex: 'finish_date',
            key: 'finish_date',
            render: (item) => moment.utc(item).format('DD MMM YYYY HH:mm:ss'),
        },
        {
            title: 'Thumnail',
            dataIndex: 'thumbnails',
            key: 'thumbnails',
            render: (item) =>
                !!item ? <img style={{ maxWidth: '300px' }} src={item} /> : '',
        },
        {
            title: 'Aksi',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/flashsale/edit/${record.id}`)} // Replaced 'history.push'
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/flashsale/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage:
                                                'Success Remove Flash Sale',
                                        },
                                        onReGetData: () => {
                                            onLoadData(queryString);
                                        },
                                        title: 'Delete Confirmation',
                                        desc: 'Are You Sure to Delete This Flash Sale',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Flashsale',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    return (
        <FlashSaleListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => navigate(-1)} // Replaced 'history.goBack()'
                    className="site-page-header"
                    title="Flashsale"
                    breadcrumb={{ routes }}
                    subTitle="Table of flashsale"
                />
            </div>
            <div className="btn-top-table">
                <Button
                    onClick={() => navigate('/flashsale/add')} // Replaced 'history.push'
                    type="primary"
                    style={{ marginBottom: 10 }}>
                    Tambah Flashsale
                </Button>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataFlashSale.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataFlashSale.payload}
                showSorterTooltip={true}
            />
        </FlashSaleListStyle>
    );
};

export default FlashSaleList;
