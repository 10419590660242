import React from 'react';
import serviceLMSCertificate from '../index.service';
import LMSCertificateStyle from './index.style';
import { Link, useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';

const LMSCertificate = () => {
    const [isLoading, setLoading] = React.useState(false);
    const history = useNavigate();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filters: {
            'sertifikat.participant_name': '',
        },
        sorts: {
            'sertifikat.id': 'DESC',
        },
    });
    const [dataCertificate, setDataCertificate] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setLoading(true);
        let resultQuery = { ...params };
        const result = await serviceLMSCertificate.getListCertificate(
            resultQuery,
        );
        if (result.isSuccess) {
            setDataCertificate(result.response);
            setLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 60,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Nama Orang Tua',
            dataIndex: 'parent_name',
            key: 'parent_name',
        },
        {
            title: 'Nama Peserta',
            dataIndex: 'participant_name',
            key: 'participant_name',
        },
        {
            title: 'Nama Merchant',
            dataIndex: 'merchant_name',
            key: 'merchant_name',
        },
        {
            title: 'Nama Kelas',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: 'Kode Sertifikat',
            dataIndex: 'code_certificate',
            key: 'code_certificate',
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                return (
                    <Tooltip title="Edit">
                        <a href={record.image} target="_blank">
                            <Button>Lihat Sertifikat</Button>
                        </a>
                    </Tooltip>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'LMS Sertifikat',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filters['sertifikat.participant_name'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <LMSCertificateStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="LMS Sertifikat"
                    breadcrumb={{ routes }}
                    subTitle="Table of LMS Sertifikat"
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}></Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataCertificate.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataCertificate.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </LMSCertificateStyle>
    );
};

export default LMSCertificate;
