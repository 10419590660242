import React, { useEffect, useState } from 'react';
import ProviderPrefixAddStyle from './index.style';
import {
    Button,
    Col,
    Form,
    Input,
    PageHeader,
    Row,
    Select,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import serviceProviderPrefix from '../index.service';
import { notifyError, notifySuccess } from '../../../../utils';

const ProviderPrefixAdd = () => {
    const navigate = useNavigate(); // Changed from `history` to `navigate`
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dataPrefix, setDataPrefix] = useState([]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'PROVIDER PREFIX',
        },
        {
            path: '',
            breadcrumbName: 'BUAT',
        },
    ];

    useEffect(() => {
        onGetDataPrefix(); // Uncomment to load prefix data
    }, []);

    const onGetDataPrefix = async () => {
        const result = await serviceProviderPrefix.listProviderPrefix();
        if (result.isSuccess) {
            const resultPrefix = result.response.payload.map((item) => ({
                label: item.prefix,
                value: item.id,
            }));
            setDataPrefix(resultPrefix);
        } else {
            notifyError("Failed to load prefix data.");
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        const resultValues = {
            provider: values.provider,
            prefix: values.prefix.join(','), // Join array into a comma-separated string
        };

        const results = await serviceProviderPrefix.createProviderPrefix(resultValues);
        if (results.isSuccess) {
            notifySuccess('Sukses menambahkan data provider prefix');
            form.resetFields(); // Reset form after successful submission
            navigate('/ppob/provider');
        } else {
            notifyError(results.message);
        }
        setLoading(false);
    };

    return (
        <ProviderPrefixAddStyle>
            <PageHeader
                onBack={() => navigate(-1)} // Corrected to `navigate(-1)` for back navigation
                className="site-page-header"
                title="Form Provider Prefix"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="provider"
                            label="Nama Provider"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[{ required: true, message: 'Nama Provider wajib diisi' }]}>
                            <Input
                                size="large"
                                type="text"
                                placeholder="Masukkan Nama Provider"
                            />
                        </Form.Item>
                    </Col>

                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="prefix"
                            label="Nomor Prefix"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[{ required: true, message: 'Nomor Prefix wajib diisi' }]}>
                            <Select
                                mode="tags"
                                allowClear
                                style={{ width: '100%' }}
                                placeholder="Input & Select Prefix"
                                options={dataPrefix}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={loading}
                            style={{ marginRight: 10, marginTop: '24px', width: 200 }}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => navigate(-1)}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </ProviderPrefixAddStyle>
    );
};

export default ProviderPrefixAdd;
