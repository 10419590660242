import {
    DeleteFilled,
    FormOutlined,
} from '@ant-design/icons';
import { Button, Input, PageHeader, Table, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalRemove from '../../../../components/ModalRemove';
import { notifyError } from '../../../../utils';
import serviceGiftCategory from '../index.service';
import GiftCategoryStyle from './index.style';

const { Search } = Input;

export default function GiftCategory() {
    const history = useNavigate();
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
        filter: {
            name: '',
        },
    });
    const [dataCategory, setDataCategory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        handleLoadData(queryString);

        return () => {
            setDataCategory([]);
        };
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceGiftCategory.getListCategory(resultQuery);
        if (result.isSuccess) {
            setDataCategory(result.response);
        } else {
            notifyError(result.response.message);
        }
        setIsLoading(false);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }

        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            key: 'no',
            width: 60,
            render: (item, record, index) =>
                (queryString.queries.page - 1) * queryString.queries.row + index + 1,
        },
        {
            title: 'Icon Kategori',
            dataIndex: 'icon',
            key: 'icon',
            width: 300,
            render: (value) => {
                return <img src={value} width={100} alt="Icon Kategori" />;
            },
        },
        {
            title: 'Nama Kategori',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            width: 100,
            render: (_, record) => (
                <div
                    key={record.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}>
                    <Tooltip title="Edit">
                        <FormOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => history(`/gift/category/${record.id}`)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteFilled
                            style={{ cursor: 'pointer' }}
                            onClick={async () => {
                                ModalRemove({
                                    configAPI: {
                                        url: `/gift/category/${record.id}`,
                                        method: 'DELETE',
                                        version: 'v1',
                                        token: true,
                                        successMessage:
                                            'Sukses Hapus Data Kategori Gift',
                                    },
                                    onReGetData: () => {
                                        handleLoadData(queryString);
                                    },
                                    title: 'Konfirmasi Hapus Data Kategori',
                                    desc: 'Apakah anda yakin, ingin mengapus data kategori ini ?',
                                });
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'MASTER KATEGORI GIFT VOUCHER',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.queries.page = 1;
        resultQuery.filter.name = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <GiftCategoryStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="KATEGORI GIFT VOUCHER"
                    breadcrumb={{ routes }}
                    subTitle="MASTER KATEGORI GIFT"
                />
            </div>
            <div className="btn-top-table">
                <Button
                    onClick={() => history('/gift/category/add')}
                    type="primary"
                    style={{ marginBottom: 10 }}>
                    Tambah Kategori
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={onSearchByName}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    total: dataCategory?.total_payload || 0,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: queryString.queries.row,
                }}
                bordered
                columns={columns}
                dataSource={dataCategory?.payload || []}
                showSorterTooltip
                onChange={handleChange}
            />
        </GiftCategoryStyle>
    );
}
