import { useRef, useEffect } from 'react';

const Dashboard = () => {
  const dashboardRef = useRef(null);

  useEffect(() => {
    if (dashboardRef.current) {
      console.log("Dashboard component is mounted", dashboardRef.current);
    }

    // Cleanup (optional)
    return () => {
      console.log("Dashboard component is unmounted");
    };
  }, []);

  return (
    <div ref={dashboardRef} style={{ minHeight: "70vh" }}>
      This is dashboard
    </div>
  );
};

export default Dashboard;
