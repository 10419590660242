import React, { useState, useEffect } from 'react';
import FlashSaleEditStyle from './index.style';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    PageHeader,
    Row,
    Upload,
    message,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import serviceFlashSale from '../index.service';
import { notifyError, notifySuccess } from '../../../utils';
import DebounceSelectComponent from '../../../components/DebounceSelect';

const FlashSaleEdit = () => {
    const navigate = useNavigate(); // Renamed for better semantics
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [dataDetailFlashSale, setDataDetailFlashSale] = useState(null);

    const routes = [
        {
            path: '',
            breadcrumbName: 'Flash Sale',
        },
        {
            path: '',
            breadcrumbName: 'Edit',
        },
    ];

    const uploadButton = (
        <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    useEffect(() => {
        onGetDetailFlashSale(id);
    }, [id]);

    const onGetDetailFlashSale = async (flashSaleId) => {
        const result = await serviceFlashSale.getDetailFlashSale(flashSaleId);
        if (result.isSuccess) {
            const res = { ...result.response.data };
            const productDetails = await serviceFlashSale.getDetailProduct(
                res.products,
            );
            setFile(res.thumbnails);

            if (productDetails.isSuccess) {
                res.product = productDetails.response.map((item) => ({
                    label: item.activity,
                    value: item.product_id,
                }));
                res.start_date = moment.utc(res.start_date);
                res.finish_date = moment.utc(res.finish_date);
                setDataDetailFlashSale(res);
                form.setFieldsValue(res);
            }
        } else {
            notifyError(result.response.message);
        }
    };

    const onFinish = async (values) => {
        let resultsThumbnail = '';

        if (typeof values.file === 'object') {
            const formData = new FormData();
            formData.append('photo', values.file.file.originFileObj);
            const uploadThumbnail = await serviceFlashSale.onUpdateImage(
                formData,
                id,
            );
            if (uploadThumbnail.isSuccess) {
                resultsThumbnail = uploadThumbnail.response.data;
            }
        } else {
            resultsThumbnail = values.file;
        }

        const updatedFlashSale = {
            id: dataDetailFlashSale.id,
            name: values.name,
            thumbnails: resultsThumbnail,
            start_date: `${moment(values.start_date).format(
                'YYYY-MM-DDTHH:mm:ss',
            )}Z`,
            finish_date: `${moment(values.finish_date).format(
                'YYYY-MM-DDTHH:mm:ss',
            )}Z`,
            products: values.product.map((item) => Number(item.value)),
        };

        const result = await serviceFlashSale.updateFlashSale(updatedFlashSale);
        if (result.isSuccess) {
            notifySuccess('Flash Sale updated successfully');
            navigate('/flashsale');
        } else {
            notifyError(result.message);
        }
    };

    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error('File size must be smaller than 500KB');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading...' }, 1000);
            setIsLoading(true);
            setFile(info.file.originFileObj);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    return (
        <FlashSaleEditStyle>
            <PageHeader
                onBack={() => navigate(-1)}
                className="site-page-header"
                title="Edit Flash Sale"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={16} sm={16} xs={16}>
                        <Form.Item
                            name="name"
                            label="Flash Sale Title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Flash Sale title is required',
                                },
                            ]}
                        >
                            <Input size="large" placeholder="Enter flash sale title" />
                        </Form.Item>
                    </Col>
                    <Col md={16} sm={16} xs={16}>
                        <div className="form-uploader">
                            <h4>
                                <span style={{ color: 'red' }}>*</span> Image
                            </h4>
                            <Form.Item name="file">
                                <Upload
                                    accept="image/*"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    customRequest={dummyRequest}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                >
                                    {file ? (
                                        <img
                                            src={
                                                typeof file === 'object'
                                                    ? URL.createObjectURL(file)
                                                    : file
                                            }
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={16} sm={16} xs={16}>
                        <Form.Item
                            name="product"
                            label="Product"
                            rules={[
                                {
                                    required: true,
                                    message: 'At least one product is required',
                                },
                            ]}
                        >
                            <DebounceSelectComponent
                                onChange={(value) => form.setFieldValue('product', value)}
                                value={form.getFieldValue('product')}
                                placeholder="Select Product"
                                fetchList={async (search) => {
                                    const params = {
                                        queries: { page: 1, rows: 10 },
                                        filters: { title: search },
                                    };
                                    const result = await serviceFlashSale.getProduct(params);
                                    if (result.isSuccess) {
                                        return result.response.payload.map((item) => ({
                                            label: item.title,
                                            value: item.id,
                                        }));
                                    }
                                    return [];
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            name="start_date"
                            label="Flash Sale Start"
                            rules={[
                                { required: true, message: 'Start date is required' },
                            ]}
                        >
                            <DatePicker showTime />
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            name="finish_date"
                            label="Flash Sale End"
                            rules={[
                                { required: true, message: 'End date is required' },
                            ]}
                        >
                            <DatePicker showTime />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ marginRight: 10, marginTop: '24px', width: 200 }}
                    >
                        Update
                    </Button>
                    <Button
                        style={{ width: 200 }}
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        </FlashSaleEditStyle>
    );
};

export default FlashSaleEdit;
