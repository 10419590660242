import { useNavigate, useParams } from 'react-router-dom'; // Use useParams to get route params
import React, { useState, useEffect } from 'react';
import serviceProductCreate from './index.service';
import UpdateProductStyle from './index.style';
import { PageHeader, Modal } from 'antd';
import FormProduct from '../../../components/Product/FormProduct';

function ProductCreate() {
    const navigate = useNavigate(); // Use navigate from useNavigate
    const { id } = useParams(); // Use useParams to get the id from the route
    const [isLoading, setIsLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(null);

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Product',
        },
        {
            path: 'merchant-gallery',
            breadcrumbName: 'Update',
        },
    ];

    useEffect(() => {
        if (id) {
            getProductDetail(id); // Fetch product details if id exists
        }
    }, [id]);

    const handleUpdateProduct = async (data) => {
        setIsLoading(true);
        const result = await serviceProductCreate.updateProduct(data);
        if (result.isSuccess) {
            modalSuccess(); // Show success modal
            setIsLoading(false);
        } else {
            Modal.error({
                title: 'Error',
                content: 'Failed to update product details!',
            });
            setIsLoading(false);
        }
    };

    const getProductDetail = async (id) => {
        const result = await serviceProductCreate.getProductDetail(id);
        if (result.isSuccess) {
            setInitialValues(result.response); // Set the fetched product details
        } else {
            Modal.error({
                title: 'Error',
                content: 'Failed to get product details!',
            });
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Successfully updated the product data!',
            onOk: () => {
                navigate('/product'); // Navigate back to the product list page
            },
        });
    };

    return (
        <UpdateProductStyle>
            <PageHeader
                onBack={() => navigate('/product')}  // Use navigate to go back
                className="site-page-header"
                title="Product Update"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <div>
                <FormProduct
                    handleSubmit={handleUpdateProduct} // Pass the update handler
                    initVal={initialValues} // Initial form values
                    isLoading={isLoading} // Loading state
                    setIsLoading={setIsLoading} // Set loading state
                    isCreate={false} // This is an update, not a create
                    isDuplicate={false} // Not duplicating the product
                />
            </div>
        </UpdateProductStyle>
    );
}

export default ProductCreate;
