import React from 'react';
import { Card, Statistic, Row, Col, DatePicker, Select, PageHeader, Empty } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import PieChart from '../../../components/PieChart';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import serviceTrakcerUserProfile from './index.service';

const TrackerUserProfile = () => {
  const navigate = useNavigate();
  const compareDate = moment().subtract(1, 'days');
  const [queryString, setQueryString] = React.useState({
    compares: { day: 1 },
    filters: { date: compareDate },
  });
  const [dataUserProfile, setDataUserProfile] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isEmpty, setIsEmpty] = React.useState(false);

  React.useEffect(() => {
    handleLoadData();
  }, [queryString]);

  const onChangeDate = (momentDate, dateString) => {
    if (momentDate) {
      setQueryString(prev => ({
        ...prev,
        filters: { ...prev.filters, date: momentDate },
      }));
    }
  };

  const onChangeType = (value) => {
    setQueryString(prev => ({
      ...prev,
      compares: { ...prev.compares, day: value },
    }));
  };

  const handleLoadData = async (changeType = false) => {
    setIsLoading(true);
    setIsEmpty(false);

    const params = {
      ...queryString,
      filters: {
        ...queryString.filters,
        date: changeType ? queryString.filters.date : queryString.filters.date.format('YYYY-MM-DD'),
      },
    };

    try {
      const result = await serviceTrakcerUserProfile.getData(params);
      if (result.isSuccess && result.response) {
        setDataUserProfile(result.response.payload);
      } else {
        setIsEmpty(true);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      setIsEmpty(true);
    } finally {
      setIsLoading(false);
    }
  };

  const routes = [
    { path: 'index', breadcrumbName: 'Tracker Report' },
    { path: 'transaction', breadcrumbName: 'User Profile' },
  ];

  return (
    <div>
      <PageHeader onBack={() => navigate(-1)} title="Tracker Report" breadcrumb={{ routes }} subTitle="Table of Report User Profile" />
      <div>
        <DatePicker defaultValue={compareDate} onChange={onChangeDate} />
        <Select defaultValue={1} style={{ width: 120 }} onChange={onChangeType}>
          <Select.Option value={1}>1 Hari lalu</Select.Option>
          <Select.Option value={7}>7 Hari lalu</Select.Option>
          <Select.Option value={30}>30 Hari lalu</Select.Option>
        </Select>
      </div>
      <div>
        {isEmpty ? <Empty /> : (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Card loading={isLoading}>
                  <Statistic title="Number Of User" value={dataUserProfile?.current?.totalUser} />
                </Card>
              </Col>
              <Col span={12}>
                <Card loading={isLoading}>
                  <Statistic title="Number Of Customer" value={dataUserProfile?.current?.total_customer} />
                </Card>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <PieChart
                    data={[
                        { type: '0 - 3 years', value: Number(dataUserProfile?.current.participant_age_0_to_3_years) },
                        { type: '4 - 5 years', value: Number(dataUserProfile?.current.participant_age_4_to_5_years) },
                        { type: '6 - 8 years', value: Number(dataUserProfile?.current.participant_age_6_to_8_years) },
                        { type: '9 - 12 years', value: Number(dataUserProfile?.current.participant_age_9_to_12_years) },
                        { type: '12+ years', value: Number(dataUserProfile?.current.participant_age_12_plus_years) },
                        { type: 'Parent', value: Number(dataUserProfile?.current.participant_age_parents) },
                    ]}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default TrackerUserProfile;
