import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Upload,
} from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';
import LMSTestimoniEditStyle, { WysiwygStyle } from './index.style';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceLMSTestimoni from '../index.service';

export default function LMSTestimoniEdit() {
    const [form] = Form.useForm();
    const history = useNavigate();
    const routes = [
        {
            path: '',
            breadcrumbName: 'Learners Camp Testimoni',
        },
        {
            path: '',
            breadcrumbName: 'Create',
        },
    ];
    const [file, setFile] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [rtValue, setRtValue] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const params = useParams();

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    React.useEffect(() => {
        onGetDetailDataTeam(params.id);
    }, []);

    const onGetDetailDataTeam = async (id) => {
        const res = await serviceLMSTestimoni.getDetailTestimoni(id);
        if (res.isSuccess) {
            const resData = res.response.payload;
            resData.photo = `${res.response.payload.image}?id=${
                Math.floor(Math.random() * 50) + 1
            }`;
            delete resData.created_at;
            setFile(resData.photo);
            setRtValue(resData.testimoni);
            form.setFieldsValue(resData);
        } else {
            notifyError(res.message);
        }
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setIsLoading(true);
            message.loading({ content: 'Uploading...' }, 1000);
            setLoading(true);
            setFile(info.file.originFileObj);
            setTimeout(() => {
                setLoading(false);
                setIsLoading(false);
            }, 2000);
            return;
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
        // return false;
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', params.id);
        formData.append('name', values.name);
        formData.append('testimoni', values.testimoni);

        if (typeof values.photo === 'string') {
            formData.append('image_url', values.photo);
        } else {
            formData.append('files', values.photo.file.originFileObj);
        }

        const response = await serviceLMSTestimoni.putTestimoniData(formData);
        if (response.isSuccess) {
            setIsLoading(false);
            notifySuccess('Success add new tim');
            history.push('/learnerscamp/testimoni');
        } else {
            setIsLoading(false);
            notifyError(response.message);
        }
    };

    return (
        <LMSTestimoniEditStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Learners Camp Testimoni Edit"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={16} xs={16}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="name"
                                label="Nama Testimoni"
                                required
                                validateTrigger={['onChange']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama Testimoni wajib diisi',
                                    },
                                ]}>
                                <Input placeholder="Masukkan Nama Testimoni" />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="testimoni"
                                label="Testimoni"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Testimoni wajib diisi',
                                    },
                                ]}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={rtValue}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            setRtValue(val);
                                            form.setFieldsValue({
                                                testimoni: val || null,
                                            });
                                        }}
                                        placeholder="Masukkan Testimoni"
                                    />
                                </WysiwygStyle>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={8} sm={8} xs={8}>
                        <div className="foto_utama">
                            <h4>
                                <span style={{ color: 'red' }}>*</span> Foto
                            </h4>
                            <Form.Item
                                name="photo"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Foto utama wajib diisi!',
                                    },
                                ]}>
                                <Upload
                                    accept="image/*"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    customRequest={dummyRequest}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}>
                                    {file ? (
                                        <img
                                            src={
                                                typeof file === 'object'
                                                    ? URL.createObjectURL(file)
                                                    : file
                                            }
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Form.Item>
                            <small>
                                Foto akan ditampilkan paling depan. Ukuran file
                                maksimum 500Kb dan resolusi maksimum 500x500
                                pixel.
                            </small>
                        </div>
                    </Col>
                    <Form.Item name="submit">
                        <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                loading={isLoading}
                                style={{ marginRight: 10, width: 200 }}
                                disabled={isLoading ? true : false}>
                                Submit
                            </Button>
                            <Button
                                shape="round"
                                style={{ width: 200 }}
                                onClick={() => {
                                    history.goBack();
                                }}>
                                Batal
                            </Button>
                        </div>
                    </Form.Item>
                </Row>
            </Form>
        </LMSTestimoniEditStyle>
    );
}
