import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceGiftCategory from '../index.service';
import GiftCategoryEditStyle from './index.style';
import { UploadOutlined } from '@ant-design/icons';

const GiftCategoryEdit = () => {
    const history = useNavigate();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = useState([]);
    const [dataCategory, setDataCategory] = useState(null);

    const routes = [
        {
            path: '',
            breadcrumbName: 'MASTER KATEGORI',
        },
        {
            path: '',
            breadcrumbName: 'GIFT',
        },
    ];

    useEffect(() => {
        onGetDataCategory(params.id);
    }, [params.id]);

    const onGetDataCategory = async (id) => {
        const result = await serviceGiftCategory.getDetailCategory(id);
        if (result.isSuccess) {
            const tempResultData = result.response.payload;
            tempResultData.image = tempResultData.icon;
            form.setFieldsValue(tempResultData);
            setFileUpload([
                {
                    uid: '1',
                    name: 'Image',
                    status: 'done',
                    url: `${tempResultData.icon}?id=${Math.floor(Math.random() * 50) + 1}`,
                    size: 1999,
                },
            ]);
            setDataCategory(tempResultData);
        } else {
            notifyError(result.message);
        }
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        if (typeof values.image === 'string') {
            formData.append('icon', values.image);
        } else if (values.image && values.image.file) {
            formData.append('file', values.image.file.originFileObj);
        }
        formData.append('name', values.name);

        const result = await serviceGiftCategory.updateCategory(params.id, formData);
        if (result.isSuccess) {
            notifySuccess('Sukses update data kategori Gift Voucher');
            history('/gift/category');
        } else {
            notifyError(result.message);
        }
        setIsLoading(false);
    };

    const dummyRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    return (
        <GiftCategoryEditStyle>
            <PageHeader
                onBack={() => history(-1)}
                className="site-page-header"
                title="BUAT KATEGORI GIFT VOUCHER"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Icon Kategori"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Thumbnail wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}
                            >
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onChange={(info) => {
                                        const isLt2M = info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error('Thumbnail harus lebih kecil dari 2 MB!');
                                            return;
                                        }
                                        setFileUpload(info.fileList);
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}
                                >
                                    {fileUpload.length === 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Update Icon Kategori
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="name"
                                label="Nama Kategori"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama kategori wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}
                            >
                                <Input
                                    size="large"
                                    placeholder="Masukkan Nama kategori gift voucher"
                                />
                            </Form.Item>
                        </div>

                        <Form.Item>
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading}
                                >
                                    Submit
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => history(-1)}
                                >
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </GiftCategoryEditStyle>
    );
};

export default GiftCategoryEdit;
