import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import {
    DashboardOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    InboxOutlined,
    BookOutlined,
    PictureOutlined,
    FileImageOutlined,
    UsergroupAddOutlined,
    ShopOutlined,
    FundProjectionScreenOutlined,
    ContainerOutlined,
    AuditOutlined,
    PercentageOutlined,
    MessageOutlined,
    GiftOutlined,
    OrderedListOutlined,
    ShoppingOutlined,
    CameraOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import LetteredAvatar from 'react-lettered-avatar';
import MainLayoutStyle from './index.style';
import Logo from '../assets/Logo/index.logo';
import { useApp } from '../contexts/app';
import { RESET, SET_CONFIG } from '../contexts/types';

const { Sider, Header, Content } = Layout;

const MainLayout = (props) => {
    const [state, dispatch] = useApp();
    const [rightMargin, setRightMargin] = useState(state.config?.drawer ? 200 : 70);
    const navigate = useNavigate();

    // Toggle the drawer state and update the margin
    const toggle = () => {
        const newDrawerState = !state.config?.drawer;
        setRightMargin(newDrawerState ? 200 : 70);
        dispatch({
            type: SET_CONFIG,
            payload: { key: 'drawer', value: newDrawerState },
        });
    };

    // Navigate to a specified URL
    const goTo = (url) => {
        navigate(url);
    };

    // Handle logout action
    const handleLogout = async () => {
        await dispatch({ type: RESET });
        navigate('/');
    };

    // Safe access to user properties with defaults
    const username = state?.user?.username || 'USER';
    const token = state?.user?.token || ''; // Example, handle token securely

    return (
        <MainLayoutStyle collapsed={state.config?.drawer}>
            <Layout className="container-layout">
                <Sider
                    className="sider"
                    trigger={null}
                    collapsible
                    collapsed={state.config?.drawer}
                    style={{
                        overflow: 'auto',
                        height: '100vh',
                        position: 'fixed',
                        left: 0,
                        width: '240px',
                        maxWidth: '240px',
                    }}
                >
                    <div className="logo">
                        <Logo width={100} height={80} />
                    </div>
                    <Menu
                        theme="light"
                        mode="inline"
                        defaultSelectedKeys={['1']}
                    >
                        <Menu.Item
                            key="1"
                            onClick={() => goTo('/dashboard')}
                            icon={<DashboardOutlined style={{ fontSize: '18px' }} />}
                        >
                            <b style={{ fontSize: '14px' }}>Dashboard</b>
                        </Menu.Item>

                        <SubMenu
                            key="sub1"
                            icon={<OrderedListOutlined />}
                            title="Order Marketplace"
                        >
                            <Menu.Item key="sub1-1-1" onClick={() => goTo('/order-list')}>List</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub3"
                            icon={<InboxOutlined />}
                            title="Product"
                        >
                            <Menu.Item key="sub3-1-2" onClick={() => goTo('/product')}>List</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub5"
                            icon={<ShopOutlined />}
                            title="Merchant"
                        >
                            <Menu.Item key="sub5-1-1" onClick={() => goTo('/merchant/create')}>Create</Menu.Item>
                            <Menu.Item key="sub5-1-2" onClick={() => goTo('/merchant')}>List</Menu.Item>
                        </SubMenu>

                        <Menu.Item
                            key="sub13"
                            icon={<ShoppingCartOutlined />}
                            onClick={() => goTo('/flashsale')}
                        >
                            <span style={{ fontSize: '14px' }}>Flashsale</span>
                        </Menu.Item>

                        <SubMenu
                            key="sub9"
                            icon={<ShoppingOutlined />}
                            title="Promo Code"
                        >
                            <Menu.Item key="sub9-1-1" onClick={() => goTo('/codepromo/premium/list')}>Premium</Menu.Item>
                            <Menu.Item key="sub9-2-1" onClick={() => goTo('/codepromo/marketplace/list')}>Marketplace</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub15"
                            icon={<GiftOutlined />}
                            title="Campaign"
                        >
                            <Menu.Item key="sub15-1-1" onClick={() => goTo('/campaign/banner')}>Banner Campaign</Menu.Item>
                            <Menu.Item key="sub15-1-2" onClick={() => goTo('/campaign/page')}>Page Campaign</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub16"
                            icon={<GiftOutlined />}
                            title="Master Gift Voucher"
                        >
                            <Menu.Item key="sub16-1-1" onClick={() => goTo('/gift/category')}>Kategori Gift</Menu.Item>
                            <Menu.Item key="sub16-1-3" onClick={() => goTo('/gift/product/')}>Produk Gift</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub17"
                            icon={<UnorderedListOutlined />}
                            title="Gift Voucher Order"
                        >
                            <Menu.Item key="sub17-1-1" onClick={() => goTo('/order-gift')}>List Gift Order</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub18"
                            icon={<GiftOutlined />}
                            title="Master PPOB"
                        >
                            <Menu.Item key="sub22-1-1" onClick={() => goTo('/ppob/provider')}>Provider Prefix</Menu.Item>
                            <Menu.Item key="sub22-1-2" onClick={() => goTo('/ppob/product/')}>Produk PPOB</Menu.Item>
                            <Menu.Item key="sub22-1-3" onClick={() => goTo('/ppob/order/')}>Order PPOB</Menu.Item>
                        </SubMenu>

                        <Menu.Item
                            key="sub12"
                            icon={<MessageOutlined />}
                            onClick={() => goTo('/inbox')}
                        >
                            <span style={{ fontSize: '14px' }}>Pesan Masuk</span>
                        </Menu.Item>

                        <SubMenu
                            key="sub10"
                            icon={<ShopOutlined />}
                            title="Kiddofest"
                        >
                            <Menu.Item key="sub10-1-1" onClick={() => goTo('/kiddofest/banner')}>Master Banner</Menu.Item>
                            <Menu.Item key="sub10-1-2" onClick={() => goTo('/kiddofest/gallery')}>Master Galeri</Menu.Item>
                            <Menu.Item key="sub10-1-3" onClick={() => goTo('/kiddofest/testimoni')}>Master Testimoni</Menu.Item>
                            <Menu.Item key="sub10-1-4" onClick={() => goTo('/kiddofest/edufair')}>Master Mitra</Menu.Item>
                            <Menu.Item key="sub10-1-5" onClick={() => goTo('/kiddofest/schedule')}>Master Jadwal</Menu.Item>
                            <Menu.Item key="sub10-1-6" onClick={() => goTo('/kiddofest/registration')}>List Registrasi Tenant</Menu.Item>
                            <Menu.Item key="sub10-1-7" onClick={() => goTo('/kiddofest/coupon')}>List Registrasi Kupon</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub11"
                            icon={<ShopOutlined />}
                            title="Learners Camp"
                        >
                            <Menu.Item key="sub11-1-1" onClick={() => goTo('/learnerscamp/team')}>Team</Menu.Item>
                            <Menu.Item key="sub11-2-1" onClick={() => goTo('/learnerscamp/testimoni')}>Testimoni</Menu.Item>
                            <Menu.Item key="sub11-3-1" onClick={() => goTo('/learnerscamp/certificate')}>Sertifikat</Menu.Item>
                            <Menu.Item key="sub11-4-1" onClick={() => goTo('/learnerscamp/participant')}>Peserta</Menu.Item>
                            <Menu.Item key="sub11-5-1" onClick={() => goTo('/learnerscamp/quiz')}>Kuis</Menu.Item>
                            <Menu.Item key="sub11-6-1" onClick={() => goTo('/learnerscamp/class')}>Kelas</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub14"
                            icon={<CameraOutlined />}
                            title="Kio & Friends"
                        >
                            <Menu.Item key="sub14-1-1" onClick={() => goTo('/kio/banner')}>Master Banner</Menu.Item>
                            <Menu.Item key="sub14-1-2" onClick={() => goTo('/kio/gallery')}>Master Galeri</Menu.Item>
                            <Menu.Item key="sub14-1-3" onClick={() => goTo('/kio/testimoni')}>Master Testimoni</Menu.Item>
                            <Menu.Item key="sub14-1-4" onClick={() => goTo('/kio/edu')}>Master Edu</Menu.Item>
                            <Menu.Item key="sub14-1-5" onClick={() => goTo('/kio/class')}>Master Kelas</Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="sub2"
                            icon={<FundProjectionScreenOutlined />}
                            title="Tracker Report"
                        >
                            <Menu.Item key="sub2-1-1" onClick={() => goTo('/tracker-report/transaction')}>Transaction</Menu.Item>
                            <Menu.Item key="sub2-2-1" onClick={() => goTo('/tracker-report/user-profile')}>User Profile</Menu.Item>
                            <Menu.Item key="sub2-3-1" onClick={() => goTo('/tracker-report/merchant-and-product')}>Merchant And Product</Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="sub8"
                            icon={<AuditOutlined />}
                            title="Journal"
                        >
                            <Menu.Item key="sub8-1-1" onClick={() => goTo('/journal/immunization')}>Immunization</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <Layout
                    className="site-layout"
                    style={{ marginLeft: rightMargin }}
                >
                    <Header
                        className="site-layout-background header"
                        style={{ padding: 0 }}
                    >
                        <div className="account-settings">
                            <div className="left">
                                <div
                                    className="toggle"
                                    onClick={toggle}
                                >
                                    {state.config?.drawer ? (
                                        <MenuUnfoldOutlined />
                                    ) : (
                                        <MenuFoldOutlined />
                                    )}
                                </div>
                            </div>
                            <div className="right">
                                <div className="account">
                                    <LetteredAvatar
                                        name={username}
                                        size={40}
                                    />
                                </div>
                                <div className="settings">
                                    <Menu>
                                        <SubMenu
                                            key="SubMenu"
                                            icon={<SettingOutlined />}
                                        >
                                            <Menu.ItemGroup>
                                                <Menu.Item key="setting:1">Edit Profile</Menu.Item>
                                                <Menu.Item key="setting:3" onClick={handleLogout}>Logout</Menu.Item>
                                            </Menu.ItemGroup>
                                        </SubMenu>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </Header>
                    <Content className="content">
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </MainLayoutStyle>
    );
};

export default MainLayout;
