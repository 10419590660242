import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Select,
    Upload,
    Checkbox,
    InputNumber,
    DatePicker,
    Modal,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';

import serviceProductGift from './index.service';
import ProductGiftEditStyle from './index.style';

import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import WysiwygStyle from './index.style';
import moment from 'moment';
import serviceGiftCategory from '../../Category/index.service';

const GiftProductEdit = (props) => {
    const history = useNavigate();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const { Option } = Select;

    const [fieldName] = useState({
        merchant: '',
        title: '',
    });

    const { RangePicker } = DatePicker;

    const [fileUpload, setFileUpload] = React.useState([]);
    const [productId] = useState(null);
    const [checkSlug, setCheckSlug] = useState(false);
    const [selectedCategory, setSelectedCat] = React.useState(false);
    const [rtValue, setRtValue] = useState('');
    const [initVal] = useState(true);
    const [isSetDuration] = useState('');
    const [isDiskon, setIsDiskon] = useState({
        status: false,
        id: -1,
        harga_asli: '',
    });
    const [dataCategory, setDataCategory] = React.useState([]);
    const [initialValues, setInitialValues] = useState(null);

    const [dataProductGift, setDataProductGift] = React.useState([]);

    React.useEffect(() => {
        onGetCategory();
    }, []);

    const onGetCategory = async () => {
        const res = await serviceGiftCategory.getListCategory();
        if (
            res.isSuccess &&
            res?.response?.payload &&
            res.response.payload.length > 0
        ) {
            setDataCategory(
                res.response.payload.map((item) => ({
                    value: item.id,
                    text: item.name,
                })),
            );
        }
    };

    const handleSLug = (e) => {
        let val = e.target.value;
        form.setFieldsValue({
            slug:
                fieldName.merchant
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .replace(/\s+/g, '-')
                    .toLowerCase() +
                '-' +
                val
                    .replace(/[^a-zA-Z0-9]/g, '')
                    .replace(/\s+/g, '-')
                    .toLowerCase(),
        });
    };

    const checkSlugToApi = async (formData) => {
        const result = await serviceProductGift.postSlug(formData);
        setCheckSlug(!result.response.is_slug_exist);
        return !result.response.is_slug_exist;
    };

    const routes = [
        {
            path: '',
            breadcrumbName: 'MASTER PRODUK GIFT',
        },
        {
            path: '',
            breadcrumbName: 'LIST TABEL PRODUK',
        },
    ];

    useEffect(() => {
        getProductDetail(params.id);
    }, []);

    const getProductDetail = async (id) => {
        const result = await serviceProductGift.getDetailProductGift(id);
        if (result.isSuccess) {
            let tempResultData = result.response.payload;
            tempResultData.image = tempResultData.image;
            tempResultData.category = tempResultData.category_id;
            tempResultData.harga_asli = tempResultData.price;
            tempResultData.status = tempResultData.publish_status;
            if (tempResultData.sale_price) {
                tempResultData.diskon1 = true;
                tempResultData.harga_diskon1 = tempResultData.sale_price;
                tempResultData.sale_price_expired = [
                    moment(tempResultData.started_sale_price),
                    moment(tempResultData.expired_sale_price),
                ];
                setIsDiskon({ status: true, id: -1 });
            } else {
                setIsDiskon({
                    ...isDiskon,
                    harga_asli: tempResultData.price,
                });
            }
            setRtValue(tempResultData.desc);
            form.setFieldsValue(tempResultData);
            setFileUpload([
                {
                    uid: '1',
                    name: 'Image',
                    status: 'done',
                    url: `${tempResultData.image}?id=${
                        Math.floor(Math.random() * 50) + 1
                    }`,
                    size: 1999,
                },
            ]);
            setDataProductGift(result.response.payload);
        }
    };

    const handleUpdateProduct = async (data) => {
        setIsLoading(true);
        const result = await serviceProductGift.updateProductGift(data);
        if (result.isSuccess) {
            modalSuccess();
            setIsLoading(false);
        } else {
            Modal.error('Gagal mendapatkan data product detail!');
            setIsLoading(false);
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil mengubah data product',
            onOk: () => {
                history('/gift/product');
            },
        });
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', dataProductGift.id);
        if (typeof values.image === 'string') {
            formData.append('image', values.image);
        } else {
            formData.append('file', values.image.file.originFileObj);
        }
        formData.append('title', values.title);
        formData.append('slug', values.slug);
        formData.append('category_id', values.category);
        formData.append(
            'merchant_id',
            process.env.REACT_APP_DEFAULT_MERCHANT.toString(),
        );
        formData.append('desc', rtValue);
        formData.append('price', values.harga_asli);
        formData.append('sale_price', values?.harga_diskon1 || 0);
        formData.append('duration', values.duration);
        formData.append('organizer', values.organizer);
        if (values?.sale_price_expired) {
            formData.append(
                'started_sale_price',
                moment(values.sale_price_expired[0]).toISOString(),
            );
            formData.append(
                'expired_sale_price',
                moment(values.sale_price_expired[1]).toISOString(),
            );
        }
        formData.append('publish_status', values.status);

        const result = await serviceProductGift.updateProductGift(
            params.id,
            formData,
        );
        if (result.isSuccess) {
            notifySuccess('Sukses Memperbarui Produk Gift Voucher');
            history('/gift/product');
        } else {
            notifyError(result.message);
        }
        setIsLoading(false);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    function disabledDate(current) {
        return current && current < moment().startOf('day');
    }

    return (
        <ProductGiftEditStyle>
            <PageHeader
                onBack={() => history('/gift/product')}
                className="site-page-header"
                title="DETAIL & EDIT PRODUK GIFT VOUCHER"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Gambar Produk"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Gambar wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onPreview={() => {}}
                                    onChange={(info) => {
                                        const isLt2M =
                                            info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error(
                                                'Ukuran gambar harus lebih kecil dari 2 MB!',
                                            );
                                        } else {
                                            setFileUpload(info.fileList);
                                        }
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}>
                                    {fileUpload.length === 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload Gambar Produk
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="title"
                                required
                                label="Nama Produk Gift"
                                validateTrigger={['onChange']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama Aktivitas wajib diisi',
                                    },
                                    {
                                        max: 100,
                                        message:
                                            'Nama Produk maksimal 100 karakter',
                                    },
                                ]}>
                                <Input
                                    placeholder="Masukkan Nama Produk Gift"
                                    onChange={handleSLug}
                                />
                            </Form.Item>
                            <small>Contoh: Voucher 100.000.</small>

                            <Form.Item
                                label="Slug"
                                name="slug"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Slug wajib diisi',
                                    },
                                    {
                                        async validator(rule, value) {
                                            const formData = new FormData();
                                            formData.append(
                                                'slug',
                                                value
                                                    .replace(/\s+/g, '-')
                                                    .toLowerCase(),
                                            );
                                            formData.append(
                                                'id',
                                                productId == null
                                                    ? 0
                                                    : productId,
                                            );
                                            const isExistSlug =
                                                await checkSlugToApi(formData);
                                            if (isExistSlug) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(
                                                    new Error(
                                                        'Slug ini sudah digunakan di produk lain, coba masukkan slug baru',
                                                    ),
                                                );
                                            }
                                        },
                                        validateTrigger: 'onBlur',
                                    },
                                ]}>
                                <Input disabled={true} />
                            </Form.Item>

                            <div style={{ marginBottom: 20 }}>
                                <Form.Item
                                    name="category"
                                    label="Kategori"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Pilih salah satu Kategori',
                                        },
                                    ]}
                                    style={{ marginBottom: 0 }}>
                                    <Select
                                        placeholder="Pilih Kategori"
                                        onChange={(value) =>
                                            setSelectedCat(value)
                                        }>
                                        {dataCategory.map((el, index) => (
                                            <Option
                                                key={index}
                                                value={el.value}>
                                                {el.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <Form.Item
                                name="desc"
                                label="Detail Produk Gift"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Detail Deskripsi Produk wajib diisi',
                                    },
                                ]}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={rtValue}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            setRtValue(val);
                                            form.setFieldsValue({
                                                desc: val || null,
                                            });
                                        }}
                                        placeholder="Jelaskan detail produk"
                                    />
                                </WysiwygStyle>
                            </Form.Item>

                            <Form.Item
                                label="Harga Produk"
                                name="harga_asli"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Harga Produk wajib diisi',
                                    },
                                    {
                                        type: 'number',
                                        min: 0,
                                        message: 'Harga produk  minimal 0',
                                    },
                                ]}>
                                <InputNumber
                                    style={{ width: '100%' }}
                                    placeholder="Masukkan Harga Tiket"
                                    onChange={(val) => {
                                        setIsDiskon({
                                            ...isDiskon,
                                            harga_asli: val,
                                        });
                                    }}
                                    formatter={(value) =>
                                        `Rp ${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            '.',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Rp\s?|(\.*)/g, '')
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                name="diskon1"
                                valuePropName="checked"
                                onChange={() => {
                                    setIsDiskon({
                                        ...isDiskon,
                                        status: !isDiskon.status,
                                        id: -1,
                                    });
                                }}
                                style={{ marginBottom: 0 }}>
                                <Checkbox checked={isDiskon.status}>
                                    Terapkan Diskon Untuk Produk Ini
                                </Checkbox>
                            </Form.Item>
                            {isDiskon.status === true && isDiskon.id === -1 && (
                                <>
                                    <div style={{ marginBottom: 20 }}>
                                        <Form.Item
                                            label="Harga Diskon"
                                            name="harga_diskon1"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Harga Diskon wajib diisi',
                                                },
                                                {
                                                    type: 'number',
                                                    max: Number(
                                                        isDiskon.harga_asli - 1,
                                                    ),
                                                    message: `Harga Diskon harus kurang dari ${isDiskon.harga_asli}`,
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}>
                                            <InputNumber
                                                style={{
                                                    width: '100%',
                                                }}
                                                formatter={(value) =>
                                                    `Rp ${value}`.replace(
                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                        '.',
                                                    )
                                                }
                                                parser={(value) =>
                                                    value.replace(
                                                        /Rp\s?|(\.*)/g,
                                                        '',
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                        <small>
                                            {' '}
                                            Harga setelah dikurangi diskon
                                            (bukan besaran diskon).
                                        </small>
                                    </div>

                                    <div>
                                        <Form.Item
                                            name="sale_price_expired"
                                            label="Periode Diskon"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Periode Diskon wajib dipilih',
                                                },
                                            ]}>
                                            <RangePicker
                                                disabledDate={disabledDate}
                                            />
                                        </Form.Item>
                                    </div>
                                </>
                            )}

                            <Form.Item
                                name="duration"
                                label="Durasi Voucher "
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required={!isSetDuration}
                                rules={
                                    isSetDuration
                                        ? []
                                        : [
                                              {
                                                  required: true,
                                                  message:
                                                      'durasi voucher wajib diisi',
                                              },
                                          ]
                                }>
                                <Input
                                    disabled={isSetDuration}
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan durasi voucher ( Deskripsi voucher secara singkat yang di munculkan di detail produk"
                                />
                            </Form.Item>

                            <Form.Item
                                name="organizer"
                                label="Penyedia ( Merchant : Kiddo.id ) "
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama penyedia wajib diisi',
                                    },
                                ]}>
                                <Select placeholder="Pilih Nama penyedia ( yang akan tamppil mulai di halaman detail ">
                                    <Option value="Kiddo.id">Kiddo.id</Option>
                                    {/* Di get ke merchant id kiddo dan namanya akan masuk ke column organizer di database produk gift */}
                                </Select>
                            </Form.Item>
                            <small>Diisi nama merchant Kiddo.id</small>

                            <Form.Item
                                label="Set Status Produk"
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Status Wajib dipilih',
                                    },
                                ]}>
                                <Select placeholder="Pilih Status ( Publish : Tampil ; Draf : Takeout dari halaman section produk">
                                    <Option value="publish">Publish</Option>
                                    <Option value="draft">Draft</Option>
                                </Select>
                            </Form.Item>

                            {/* 
                        End css form produk */}
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    setIsLoading={setIsLoading}
                                    handleSubmit={handleUpdateProduct}
                                    initVal={initialValues}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Submit
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history(-1);
                                    }}>
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </ProductGiftEditStyle>
    );
};

export default GiftProductEdit;

