import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import AppProvider from './contexts/app'; // Context provider
import 'antd/dist/antd.css'; // Ant Design styles
import './index.scss'; // Custom global styles
import ThemeConfiguration from './shared/Theme'; // Theme setup

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeConfiguration>
    <AppProvider>
      <App />
    </AppProvider>
  </ThemeConfiguration>
);
