import React, { useState, useEffect } from 'react';
import ProviderPrefixListStyle from './index.style';
import serviceProviderPrefix from '../index.service';
import { useNavigate } from 'react-router-dom';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import ModalRemove from '../../../../components/ModalRemove';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { notifyError } from '../../../../utils';
import moment from 'moment';

const ProviderPrefixList = () => {
    const navigate = useNavigate();
    const [dataProviderPrefix, setDataProviderPrefix] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
        sorts: {
            created_at: 'desc',
        },
    });

    useEffect(() => {
        onLoadData();
    }, [queryString]);

    const onLoadData = async () => {
        setIsLoading(true);
        const results = await serviceProviderPrefix.listProviderPrefix(queryString);
        if (results.isSuccess) {
            setDataProviderPrefix(results.response);
        } else {
            notifyError(results.response.message);
        }
        setIsLoading(false);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * queryString.queries.row + index + 1,
        },
        {
            title: 'Nama Provider',
            dataIndex: 'provider',
            key: 'provider',
        },
        {
            title: 'Nomor Prefix',
            dataIndex: 'prefix',
            key: 'prefix',
        },
        {
            title: 'Tanggal & Jam Update',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (item) => moment.utc(item).format('DD MMM YYYY HH:mm:ss'),
        },
        {
            title: 'Aksi',
            key: 'actions',
            render: (_, record) => (
                <div
                    key={record.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}>
                    <Tooltip title="Edit">
                        <FormOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/ppob/provider/${record.id}`)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteFilled
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                ModalRemove({
                                    configAPI: {
                                        url: `/ppob/provider/${record.id}`,
                                        method: 'DELETE',
                                        version: 'v1',
                                        token: true,
                                        successMessage: 'Sukses menghapus data prefix',
                                    },
                                    onReGetData: () => {
                                        onLoadData();
                                    },
                                    title: 'Delete Confirmation',
                                    desc: 'Apakah Anda yakin ingin menghapus data Prefix?',
                                })
                            }
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'PROVIDER PREFIX',
        },
        {
            path: 'list',
            breadcrumbName: 'LIST',
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        const updatedQuery = { ...queryString };
        updatedQuery.queries.row = pagination.pageSize;
        updatedQuery.queries.page = pagination.current;

        setQueryString(updatedQuery);
    };

    return (
        <ProviderPrefixListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => navigate(-1)}
                    className="site-page-header"
                    title="PPOB"
                    breadcrumb={{ routes }}
                    subTitle="Table PPOB Provider"
                />
            </div>
            <div className="btn-top-table">
                <Button
                    onClick={() => navigate('/ppob/provider/add')}
                    type="primary"
                    style={{ marginBottom: 10 }}>
                    Tambah Prefix
                </Button>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    pageSize: queryString.queries.row,
                    total: dataProviderPrefix.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                columns={columns}
                dataSource={dataProviderPrefix.payload || []}
                showSorterTooltip={true}
                onChange={handleChange}
                rowKey="id"
            />
        </ProviderPrefixListStyle>
    );
};

export default ProviderPrefixList;
