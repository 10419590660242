import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import {
    PageHeader,
    Button,
    Table,
    Tag,
    message,
    Select,
    Input,
    Form,
    Menu,
    Dropdown,
} from 'antd';

import ArticleService from './index.service';

import ArticleListStyle from './index.style';
import articleService from './index.service';
import useAsync from '../../utils/customHooks/useAsync';
import { EllipsisOutlined, SearchOutlined } from '@ant-design/icons';

const ArticleList = () => {
    const history = useNavigate();
    const { Option } = Select;
    const [articleData, setArticleData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(10);
    const [form] = Form.useForm();
    const [queryString, setQueryString] = useState({
        queries: {
            page: 1,
            row: 10,
        },
    });

    const [filter, setFilter] = useState({
        category: [],
        tag: [],
    });

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Artikel Management',
        },
        {
            path: 'artikel',
            breadcrumbName: 'Artikel',
        },
    ];

    const handleLoadData = async (params) => {
        setIsLoading(true);

        let searchQuery = { ...params };
        const result = await ArticleService.getArticleList(searchQuery);
        if (result.isSuccess) {
            setArticleData(result.response);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const getCategories = useCallback(async () => {
        const result = await articleService.getArticleCategory();
        if (result.isSuccess) {
            return result.response.payload;
        } else {
            message.error('Error mendapatkan kategori');
        }
    }, []);

    const getTags = useCallback(async () => {
        const result = await articleService.getArticleTags();
        if (result.isSuccess) {
            return result.response.payload;
        } else {
            message.error('Error mendapatkan tags');
        }
    }, []);

    const getAuthor = useCallback(async () => {
        const result = await articleService.getArticleAuthor();
        if (result.isSuccess) {
            return result.response.payload;
        } else {
            message.error('Error mendapatkan Author');
        }
    }, []);

    const categoriesData = useAsync(getCategories);
    const tagsData = useAsync(getTags);
    const authorData = useAsync(getAuthor);

    const categoryOptions = useMemo(() => {
        return (
            categoriesData?.value?.map((item) => ({
                text: item.name,
                value: item.id,
            })) ?? []
        );
    }, [categoriesData]);

    const tagsOptions = useMemo(() => {
        return (
            tagsData?.value?.map((item) => ({
                text: item.name,
                value: item.id,
            })) ?? []
        );
    }, [tagsData]);

    const authorOption = useMemo(() => {
        return authorData?.value ?? [];
    }, [authorData]);

    const handleChange = (pagination, filters, sorter) => {
        let querySearch = { ...queryString };
        querySearch.queries.row = pagination.pageSize;
        querySearch.queries.page = pagination.current;
        if (!sorter.field) {
            delete querySearch.sort;
        } else {
            switch (sorter.field) {
                case 'title':
                    querySearch.sorts = {
                        ['title']: sorter.order === 'descend' ? 'desc' : 'asc',
                    };
                    break;
                case 'author':
                    querySearch.sorts = {
                        ['author']: sorter.order === 'descend' ? 'desc' : 'asc',
                    };
                    break;
                case 'published_at':
                    querySearch.sorts = {
                        ['published_at']:
                            sorter.order === 'descend' ? 'desc' : 'asc',
                    };
                    break;
                default:
            }
        }

        let categoryTag = [];

        if (filters.article_category) {
            filters.article_category.forEach((item) => {
                categoryTag.push(item);
            });
        }

        if (filters.article_tag) {
            filters.article_tag.forEach((item) => {
                categoryTag.push(item);
            });
        }

        setFilter((prevState) => ({
            ...prevState,
            tag: filters.article_tag,
            category: filters.article_category,
        }));

        querySearch.filters = {
            ...querySearch.filters,
            publish_status: filters.publish_status,
            article_category_tag_id: categoryTag,
        };

        setQueryString(querySearch);
        categoryTag = [];
    };

    const handleSearch = (
        selectedKeys,
        confirm,
        dataIndex,
        isComponentSelect = false,
    ) => {
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        if (isComponentSelect) {
            setSearchValue((prevState) => {
                return { ...prevState, [dataIndex]: selectedKeys };
            });
            resultQuery.filters = {
                [dataIndex]: selectedKeys,
            };
        } else {
            resultQuery.filters = {
                [dataIndex]: searchValue[dataIndex],
            };
        }
        setQueryString(resultQuery);
    };

    const resetFilter = () => {
        setQueryString({
            queries: {
                page: 1,
                row: 10,
            },
        });
        setCurrentPage(1);
        setTotalPage(10);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={searchValue[dataIndex] || ''}
                    onChange={(e) =>
                        setSearchValue((prevState) => {
                            return {
                                ...prevState,
                                [dataIndex]: e.target.value,
                            };
                        })
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                {/* <Space> */}
                <Button
                    type="primary"
                    onClick={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: '100%' }}
                    icon={<SearchOutlined />}
                    size="small">
                    Search
                </Button>
                {/* <Button
                        onClick={() => handleResetSearch(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button> */}
                {/* </Space> */}
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const getColumnSelectProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Select
                    showSearch
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    optionFilterProp="children"
                    value={searchValue[dataIndex] || ''}
                    onSelect={(val) => {
                        handleSearch(val, confirm, dataIndex, true);
                    }}>
                    {authorOption.map((el, idx) => {
                        return (
                            <Option key={idx} value={el}>
                                {el}
                            </Option>
                        );
                    })}
                </Select>
                <Button
                    type="primary"
                    block
                    onClick={() => {
                        resetFilter();
                    }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const handleResetFilter = () => {
        setFilter({
            category: [],
            tag: [],
        });
        setSearchValue({});
        const defaultQuery = {
            queries: {
                page: 1,
                row: 10,
            },
        };
        setQueryString(defaultQuery);
        form.setFieldsValue({
            queries: {
                page: 1,
                row: 10,
            },
        });
    };

    const handleLabelStatus = (status) => {
        let color;
        switch (status) {
            case 'published':
                color = 'green';
                break;
            case 'pending':
                color = 'geekblue';
                break;
            case 'draft':
                color = 'volcano';
                break;
            default:
        }
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    useEffect(() => {
        handleLoadData(queryString);
    }, [queryString]);

    const menu = (record) => (
        <Menu>
            <Menu.Item>
                <Button
                    onClick={() => history.push(`/article/detail/${record.id}`)}
                    style={{ width: '100%' }}
                    type="dashed">
                    Detail
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button
                    style={{ width: '100%' }}
                    type="dashed"
                    onClick={async () => {
                        var ret = window.confirm(
                            'Are you sure delete this product?',
                        );
                        if (ret == true) {
                            const result = await articleService.deleteArticle(
                                record.id,
                            );
                            if (result.isSuccess) {
                                refreshPage();
                            } else {
                            }
                        } else {
                        }
                    }}>
                    Delete
                </Button>
            </Menu.Item>
        </Menu>
    );

    const refreshPage = () => {
        window.location.reload();
    };

    const DropdownMenu = ({ record }) => (
        <Dropdown key="more" overlay={menu(record)}>
            <Button
                style={{
                    border: 'none',
                    padding: 0,
                }}>
                <EllipsisOutlined
                    style={{
                        fontSize: 20,
                        verticalAlign: 'top',
                    }}
                />
            </Button>
        </Dropdown>
    );

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => {
                return (
                    <Link to={`article/detail/${record.id}`}>{record.id}</Link>
                );
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            sorter: true,
            ...getColumnSelectProps('author'),
        },
        {
            title: 'Article Category',
            dataIndex: 'article_category',
            key: 'article_category',
            filters: categoryOptions,
            filteredValue: filter.category || null,
            render: (value) => {
                return (
                    value && value.length > 0 && <div>{value.join(', ')}</div>
                );
            },
        },
        {
            title: 'Article Tag',
            dataIndex: 'article_tag',
            key: 'article_tag',
            filters: tagsOptions,
            filteredValue: filter.tag || null,
            render: (value) => {
                return (
                    value &&
                    value.map((item) => <Tag>{item.toUpperCase()}</Tag>)
                );
            },
        },
        {
            title: 'Publish Status',
            dataIndex: 'publish_status',
            key: 'publish_status',
            render: (value) => handleLabelStatus(value),
        },
        {
            title: 'Published',
            dataIndex: 'published_at',
            key: 'published_at',
            sorter: true,
            render: (value, record) => {
                return (
                    record.publish_status === 'published' && (
                        <span>
                            {moment(value).format('DD-MM-YYYY HH:mm:ss')}
                        </span>
                    )
                );
            },
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: '',
            render: (_, record) => {
                return <DropdownMenu record={record} key="more" />;
            },
        },
    ];

    return (
        <ArticleListStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Artikel List"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <div style={{ minHeight: '60vh' }}>
                <Link to="/article/create">
                    <Button type="primary" style={{ marginBottom: '10px' }}>
                        + Add New Article
                    </Button>
                </Link>
                <Button onClick={handleResetFilter} style={{ float: 'right' }}>
                    Reset Filter
                </Button>
                {/* <Row>
          <Col span={24}></Col>
        </Row> */}
                <Table
                    loading={isLoading}
                    pagination={{
                        current: queryString.queries.page,
                        defaultCurrent: 1,
                        className: 'pagination',
                        total: articleData.total_payload,
                        showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`,
                        defaultPageSize: 10,
                    }}
                    bordered
                    columns={columns}
                    dataSource={articleData.payload}
                    showSorterTooltip={true}
                    onChange={handleChange}
                />
            </div>
        </ArticleListStyle>
    );
};

export default ArticleList;
