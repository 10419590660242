import Cookies from 'js-cookie';

const TOKEN_KEY = 'auth_token'; // Key for storing token in cookies

const localStorages = () => {
    return {
        // Cookie methods
        cookies: {
            get: (key) => Cookies.get(key),
            save: (key, value, options = {}) => {
                Cookies.set(key, value, options); // Optional options like expiration, path
            },
            remove: (key) => Cookies.remove(key),
        },
        // Token management methods
        getToken: () => Cookies.get(TOKEN_KEY),
        saveToken: (token, expiresDays = 7) => Cookies.set(TOKEN_KEY, token, { expires: expiresDays }),
        removeToken: () => Cookies.remove(TOKEN_KEY),
        // Local storage methods
        get: (key) => {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        },
        save: (key, value) => {
            localStorage.setItem(key, JSON.stringify(value));
        },
        remove: (key) => {
            localStorage.removeItem(key);
        },
        clear: () => {
            localStorage.clear();
        },
    };
};

export default localStorages;
