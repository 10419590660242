import React from 'react';
import {
    DeleteFilled,
    EditOutlined,
    FileSearchOutlined,
    FormOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, PageHeader, Space, Table, Tooltip } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import KiddofestEdufairListStyle from './index.style';
import serviceKiddofestEdufair from '../index.service';
import { notifyError } from '../../../../utils';
import ModalRemove from '../../../../components/ModalRemove';

const { Search } = Input;

export default function KiddofestEdufairList() {
    const history = useNavigate();
    const [dataEdufair, setDataEdufair] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
    });

    React.useEffect(() => {
        handleLoadData(queryString);

        return () => {
            setDataEdufair([]);
        };
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceKiddofestEdufair.getListEdufair(
            resultQuery,
        );
        if (result.isSuccess) {
            setDataEdufair(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Image',
            dataIndex: 'image_url',
            key: 'image_url',
            render: (value) => {
                return <img src={value} width={180} />;
            },
        },
        {
            title: 'Name Brand',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link  ',
            ellipsis: true,
            render: (value) => {
                const result = value[0]?.split(',') || [];
                return result.length > 0 ? (
                    <div style={{ display: 'block' }}>
                        {result.map((item, key) => (
                            <>
                                <a key={key} href={item} target="_blank">
                                    {item}
                                </a>
                                <br />
                            </>
                        ))}
                    </div>
                ) : (
                    '-'
                );
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history.push(
                                        `/kiddofest/edufair/${record.id}`,
                                    )
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/kiddofest/edufair/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage:
                                                'Success Remove Edufair',
                                        },
                                        onReGetData: () => {
                                            handleLoadData(queryString);
                                        },
                                        title: 'Delete Confirmation',
                                        desc: 'Are You Sure to Delete This Edufair',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Immunization List',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filter = {
            'kiddofest_edufair.name': value,
        };
        if (!value) {
            delete resultQuery.filter;
        }
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    return (
        <KiddofestEdufairListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Kiddofest Edufair List"
                    breadcrumb={{ routes }}
                    subTitle="Table of Kiddofest Edufair List"
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="/kiddofest/edufair/add">+ Add New Edufair</Link>
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataEdufair.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataEdufair.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </KiddofestEdufairListStyle>
    );
}
