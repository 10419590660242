import {
    Card,
    Col,
    Descriptions,
    PageHeader,
    Divider,
    Row,
    Table,
    Tag,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OrderGiftDetailStyle from './index.style';
import serviceOrderGiftDetail from '../index.service';
import OrderGiftDetailLoading from './index.loading';
import { formatMoney } from '../../../utils/format-money';
import { formatDate } from '../../../utils/format-date';
import moment from 'moment';

const OrderGiftDetail = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [isLoading, setLoading] = React.useState(true);
    const [dataOrderDetail, setDataOrderDetail] = React.useState({});

    React.useEffect(() => {
        if (params.id) {
            handleLoadData(params.id);
        }
    }, [params.id]);

    const handleLoadData = async (id) => {
        setLoading(true);
        const result = await serviceOrderGiftDetail.getDataGiftDetail(id);
        if (result.isSuccess && result.response) {
            setDataOrderDetail(result.response.payload);
        }
        setLoading(false);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Gift Order',
        },
        {
            path: 'detail',
            breadcrumbName: 'Detail',
        },
    ];

    const columns = [
        {
            key: 'name',
            title: 'Nama Penerima',
            render: (_, record) => record.name_receiver,
        },
        {
            key: 'email',
            title: 'Email',
            render: (_, record) => record.email_receiver,
        },
        {
            key: 'phone',
            title: 'No Hp',
            render: (_, record) => record.phone_receiver,
        },
        {
            key: 'voucher',
            title: 'Kode Voucher',
            render: (_, record) => record.voucher,
        },
        {
            key: 'voucher_expired',
            title: 'Masa Voucher',
            render: (_, record) => record.voucher_expired,
        },
    ];

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'Completed') color = 'green';
        else if (status === 'Pending') color = 'geekblue';
        else if (status === 'Cancelled') color = 'volcano';
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    return (
        <OrderGiftDetailStyle>
            <PageHeader
                onBack={() => navigate(-1)}
                className="site-page-header"
                title="Order Detail"
                breadcrumb={{ routes }}
                subTitle="Table of gift order detail"
            />
            {isLoading ? (
                <OrderGiftDetailLoading />
            ) : (
                <Row gutter={16}>
                    <Col span={24}>
                        <Card loading={isLoading} title="Order Gift Details" bordered>
                            <Descriptions title="" bordered>
                                <Descriptions.Item label="Order ID" span={1}>
                                    {dataOrderDetail?.id}
                                </Descriptions.Item>

                                <Descriptions.Item label="Date">
                                    {moment
                                        .utc(dataOrderDetail?.date_order)
                                        .format('DD MMMM YYYY')}
                                </Descriptions.Item>

                                <Descriptions.Item label="Updated Timestamp">
                                    {formatDate(dataOrderDetail?.updated_at, 'LL')}
                                </Descriptions.Item>

                                <Descriptions.Item label="Nama Gift Produk">
                                    {dataOrderDetail?.items?.[0]?.product_name}
                                </Descriptions.Item>

                                <Descriptions.Item label="Kategori">
                                    {dataOrderDetail?.items?.[0]?.categorie}
                                </Descriptions.Item>

                                <Descriptions.Item label="Harga Utama">
                                    {formatMoney(dataOrderDetail?.price)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Harga Diskon">
                                    {dataOrderDetail?.sale_price
                                        ? formatMoney(dataOrderDetail?.sale_price)
                                        : '-'}
                                </Descriptions.Item>

                                <Descriptions.Item label="Service Pay">
                                    {formatMoney(dataOrderDetail?.payment_charge)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Payment Gateway Vendor">
                                    {dataOrderDetail?.payment_vendor}
                                </Descriptions.Item>

                                <Descriptions.Item label="Pembayaran Via">
                                    {dataOrderDetail?.payment_method}
                                </Descriptions.Item>

                                <Descriptions.Item label="Payment Gateway Status">
                                    {handleLabelStatus(dataOrderDetail?.status_payment)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Payment Gateway Info">
                                    {dataOrderDetail?.payment_gateway_linkpay && (
                                        <>
                                            <p>Link pay: </p>
                                            {dataOrderDetail.payment_gateway_linkpay}
                                            <Divider />
                                        </>
                                    )}
                                    {dataOrderDetail?.payment_gateway_send && (
                                        <>
                                            <p>Payment gateway send: </p>
                                            {dataOrderDetail.payment_gateway_send}
                                            <Divider />
                                        </>
                                    )}
                                    {dataOrderDetail?.payment_gateway_webhook && (
                                        <>
                                            <p>Payment gateway webhook: </p>
                                            {dataOrderDetail.payment_gateway_webhook}
                                            <Divider />
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>

                    <Col span={8}>
                        <Card loading={isLoading} title="Data Pembeli : User Kiddo" bordered>
                            <Descriptions bordered>
                                <Descriptions.Item label="Nama Penerima" span={3}>
                                    {dataOrderDetail?.name_buyer}
                                </Descriptions.Item>

                                <Descriptions.Item label="No Hp" span={3}>
                                    {dataOrderDetail?.phone_buyer}
                                </Descriptions.Item>

                                <Descriptions.Item label="Email" span={3}>
                                    {dataOrderDetail?.email_buyer}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>

                    <Col span={16}>
                        <Card loading={isLoading} title="Data Penerima Gift" bordered>
                            <Table
                                loading={isLoading}
                                columns={columns}
                                dataSource={dataOrderDetail?.items}
                                rowKey="id"
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </OrderGiftDetailStyle>
    );
};

export default OrderGiftDetail;
