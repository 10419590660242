import React from "react";
import { PageHeader, Form, Select, Row, Col, Tabs, Button } from "antd";
import { useNavigate } from "react-router-dom";
import ArticlePilihanStyle from "./index.style";
import FormPilihan from "../../../components/Artikel/FormPilihan";

const ArticlePilihan = () => {
	const history = useNavigate();
	const { TabPane } = Tabs;

	const routes = [
		{
			path: "index",
			breadcrumbName: "Artikel Management",
		},
		{
			path: "artikel",
			breadcrumbName: "Artikel Pilihan",
		},
	];



	return (
		<ArticlePilihanStyle>
			<PageHeader
				onBack={() => history.goBack()}
				className="site-page-header"
				title="Artikel Pilihan"
				breadcrumb={{ routes }}
				style={{ paddingLeft: 0 }}
			/>
			<Row>
				<Col span={24}>
					<Tabs defaultActiveKey="1" >
						<TabPane tab="Piliha Editor" key="1">
							<FormPilihan />
						</TabPane>
						<TabPane tab="Do It Yourself" key="2">
							<FormPilihan />
						</TabPane>
						<TabPane tab="Aktivitas Anak" key="3">
							<FormPilihan />
						</TabPane>
						<TabPane tab="Parenting" key="4">
							<FormPilihan />
						</TabPane>
						<TabPane tab="Komunitas" key="5">
							<FormPilihan />
						</TabPane>
						<TabPane tab="Resep" key="6">
							<FormPilihan />
						</TabPane>
					</Tabs>
				</Col>
			</Row>
		</ArticlePilihanStyle>
	);
};

export default ArticlePilihan;
