import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import ReactQuill from 'react-quill';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
    PageHeader,
    Row,
    Col,
    Form,
    Input,
    Select,
    Button,
    Upload,
    message,
    Modal,
    Spin,
} from 'antd';

import {
    EditorStyle,
    PhotoContentContainer,
    UploadMainImageStyle,
} from './articleForm.style';
import useAsync from '../../utils/customHooks/useAsync';
import articleService from '../../pages/Article/index.service';
import EditorToolbar, {
    modules,
    formats,
} from '../EditorToolbar/Editortoolbar';
import ImgCrop from 'antd-img-crop';
import { useNavigate  } from 'react-router-dom';

const ArticleForm = ({ onSubmit, isLoading, setIsLoading, initValue }) => {
    const [form] = Form.useForm();
    const history = useNavigate();
    const { Option } = Select;
    const { TextArea } = Input;

    const quillref = useRef(null);

    const [photosModal, setPhotoModal] = useState(false);

    const [isDraft, setIsDraft] = useState(false);

    const [loadingPhoto, setLoadingPhoto] = useState(false);
    const [loadingUploadPhoto, setloadingUploadPhoto] = useState(false);

    const [textContent, setTextContent] = useState('');
    const [mainPhoto, setMainPhoto] = useState();
    const [contentPhoto, setContentPhoto] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState();
    const [categoryTag, setCategoryTag] = useState({
        category: [],
        tag: [],
    });
    const [categoryTagId, setCategoryTagId] = useState([]);

    const handleSLug = (e) => {
        let val = e.target.value;
        form.setFieldsValue({
            articleSlug: val.replace(/\s+/g, '-').toLowerCase(),
        });
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
                key: 'updatable',
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
                key: 'updatable',
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
        // return false;
    };

    useEffect(() => {
        getImageContent();
    }, [initValue]);

    useEffect(() => {
        if (initValue) {
            getInitValue();
        }
    }, [initValue]);

    useEffect(() => {
        handleCombineId();
    }, [categoryTag]);

    const getInitValue = () => {
        form.setFieldsValue({
            category: initValue.article_category,
            title: initValue.title,
            content: initValue?.content === undefined ? '' : initValue?.content,
            tags: initValue.article_tag,
            author: initValue.author,
            publish_status: initValue.publish_status,
            preview_text: initValue.preview_text,
            seo_key_phrase: initValue.seo_key_phrase,
            seo_title: initValue.seo_title,
            seo_metadata_description: initValue.seo_metadata_description,
            articleSlug: initValue.slug,
            image: initValue.image,
            estimated_range_read: initValue.estimated_range_read,
        });
        setTextContent(
            initValue?.content === undefined ? '' : initValue?.content,
        );
        setMainPhoto(initValue.image);
        setCategoryTag({
            category: categoryOptions.filter((item) =>
                initValue?.article_category?.includes(item.value),
            ),
            tag: tagsOptions.filter((item) =>
                initValue?.article_tag?.includes(item.value),
            ),
        });
    };

    const handleCombineId = () => {
        let tempArr = [];
        categoryTag.category.forEach((item) => {
            tempArr.push(parseInt(item.key));
        });
        categoryTag.tag.forEach((item) => {
            if (item?.key) {
                tempArr.push(parseInt(item.key));
            }
        });
        setCategoryTagId(tempArr);
    };

    const getImageContent = async () => {
        setLoadingPhoto(true);
        const result = await articleService.getArticlePhotoContent();
        if (result.isSuccess) {
            setContentPhoto(result.response.payload);
        } else {
            message.error('Gagal mendapatkan gambar');
            console.error(result.message);
        }
        setLoadingPhoto(false);
    };

    const getCategories = useCallback(async () => {
        const result = await articleService.getArticleCategory();
        if (result.isSuccess) {
            return result.response.payload;
        } else {
            message.error('Error mendapatkan kategori');
        }
    }, []);

    const getTags = useCallback(async () => {
        const result = await articleService.getArticleTags();
        if (result.isSuccess) {
            return result.response.payload;
        } else {
            message.error('Error mendapatkan tags');
        }
    }, []);

    const getAuthor = useCallback(async () => {
        const result = await articleService.getArticleAuthor();
        if (result.isSuccess) {
            return result.response.payload;
        } else {
            message.error('Error mendapatkan Author');
        }
    }, []);

    const categoriesData = useAsync(getCategories);
    const tagsData = useAsync(getTags);
    const authorData = useAsync(getAuthor);

    const categoryOptions = useMemo(() => {
        return (
            categoriesData?.value?.map((item) => ({
                key: item.id,
                text: item.name,
                value: item.name,
            })) ?? []
        );
    }, [categoriesData]);

    const tagsOptions = useMemo(() => {
        return (
            tagsData?.value?.map((item) => ({
                key: item.id,
                text: item.name,
                value: item.name,
            })) ?? []
        );
    }, [tagsData]);

    const authorOption = useMemo(() => {
        return authorData?.value ?? [];
    }, [authorData]);

    const uploadMainPic = async ({ file }) => {
        setloadingUploadPhoto(true);
        const formData = new FormData();
        formData.append('src', file);
        formData.append('type', 'title');
        const result = await articleService.postArticlePhoto(formData);
        if (result.isSuccess) {
            let res = result.response.payload;
            setMainPhoto(res);
            form.setFieldsValue({
                image: res,
            });
        } else {
            message.error('Gagal upload photo');
        }

        setloadingUploadPhoto(false);
    };

    const uploadContentPic = async ({ file }) => {
        const formData = new FormData();
        formData.append('src', file);
        formData.append('type', 'content');
        const result = await articleService.postArticlePhoto(formData);
        if (result.isSuccess) {
            let res = result.response;
            setMainPhoto(res);
            getImageContent();
        } else {
            message.error('Gagal upload photo');
        }
        setloadingUploadPhoto(false);
    };

    const handleUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading', key: 'updatable' });
            setloadingUploadPhoto(true);
        } else {
            setloadingUploadPhoto(false);
        }
    };

    const uploadButton = () => {
        return (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
    };

    const addImagetoEditor = () => {
        const range = quillref.current.getEditorSelection();
        const position = range ? range.index : 0;
        const quillObj = quillref.current.getEditor();
        quillObj.insertEmbed(position, 'image', selectedPhoto.url);
        setSelectedPhoto();
        setPhotoModal(false);
    };

    const onFinish = (value) => {
        const data = {
            title: value.title,
            author: value.author,
            content: value.content,
            seo_title: value.seo_title,
            estimated_range_read: value.estimated_range_read,
            seo_key_phrase: value.seo_key_phrase,
            seo_metadata_description: value.seo_metadata_description,
            preview_text: value.preview_text,
            publish_status: value.publish_status,
            article_category: value.category,
            article_tag: value.tags,
            article_category_tag_id: categoryTagId,
            image: value.image,
        };

        if (initValue) {
            data.id = initValue.id;
        }

        onSubmit(data);
    };

    return (
        <>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col span={16}>
                        <Form.Item
                            label="Judul Artikel"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Judul Artikel wajib diisi',
                                },
                            ]}>
                            <Input onChange={handleSLug} />
                        </Form.Item>
                        <Form.Item
                            label="Slug"
                            name="articleSlug"
                            rules={[
                                {
                                    required: true,
                                    message: 'Slug wajib diisi',
                                },
                            ]}>
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            label="SEO Title"
                            name="seo_title"
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'SEO Title wajib diisi',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="SEO Key Phrase"
                            name="seo_key_phrase"
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'SEO Key Phrase wajib diisi',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="SEO Description"
                            name="seo_metadata_description"
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'SEO Description wajib diisi',
                                },
                            ]}>
                            <TextArea />
                        </Form.Item>
                        <div>
                            <Button
                                type="link"
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    zIndex: 999,
                                }}
                                onClick={() => {
                                    setPhotoModal(true);
                                }}>
                                Tambah Foto +
                            </Button>
                            <Form.Item
                                label="Konten Artikel"
                                name="content"
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    {
                                        required: isDraft,
                                        message: 'Isi Artikel wajib diisi',
                                    },
                                ]}>
                                <EditorStyle>
                                    <EditorToolbar />
                                    <ReactQuill
                                        theme="snow"
                                        ref={quillref}
                                        value={textContent}
                                        modules={modules}
                                        formats={formats}
                                        onChange={(val) => {
                                            setTextContent(val);
                                            form.setFieldsValue({
                                                content: val || null,
                                            });
                                        }}
                                        placeholder="Tulis kontent artikel disini"
                                    />
                                </EditorStyle>
                            </Form.Item>
                        </div>
                        <Modal
                            title="Foto Content"
                            visible={photosModal}
                            onCancel={() => setPhotoModal(false)}
                            footer={[
                                <Button
                                    key="add"
                                    disabled={!selectedPhoto}
                                    onClick={addImagetoEditor}>
                                    Add Image
                                </Button>,
                            ]}>
                            <Spin spinning={loadingPhoto}>
                                <PhotoContentContainer>
                                    {contentPhoto && contentPhoto.length > 0
                                        ? contentPhoto.map((item) => {
                                              return (
                                                  <img
                                                      src={item.url}
                                                      alt="article"
                                                      className={`content-photo ${
                                                          selectedPhoto?.id ===
                                                              item.id &&
                                                          'content-photo-active'
                                                      }`}
                                                      style={{
                                                          width: '100px',
                                                          height: '105px',
                                                          objectFit: 'cover',
                                                          borderRadius: '5px',
                                                      }}
                                                      onClick={() =>
                                                          setSelectedPhoto(item)
                                                      }
                                                  />
                                              );
                                          })
                                        : null}
                                    <Upload
                                        accept="image/*"
                                        listType="picture-card"
                                        showUploadList={false}
                                        beforeUpload={beforeUpload}
                                        customRequest={uploadContentPic}
                                        onChange={handleUploadChange}>
                                        {uploadButton()}
                                    </Upload>
                                </PhotoContentContainer>
                            </Spin>
                        </Modal>
                        <Form.Item
                            label="Text Preview"
                            name="preview_text"
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'Judul Artikel wajib diisi',
                                },
                            ]}>
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Foto Artikel"
                            name="image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Foto Artikel wajib diisi',
                                },
                            ]}>
                            <UploadMainImageStyle>
                                <ImgCrop
                                    rotate
                                    beforeCrop={beforeUpload}
                                    aspect={16 / 9}>
                                    <Upload
                                        accept="image/*"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        customRequest={uploadMainPic}
                                        beforeUpload={beforeUpload}
                                        onChange={handleUploadChange}>
                                        {mainPhoto ? (
                                            <img
                                                src={mainPhoto.url}
                                                alt="avatar"
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                }}
                                            />
                                        ) : (
                                            uploadButton()
                                        )}
                                    </Upload>
                                </ImgCrop>
                            </UploadMainImageStyle>
                        </Form.Item>
                        <Form.Item
                            label="Estimasi waktu dibaca"
                            name="estimated_range_read"
                            extra="contoh: 7 menit dibaca"
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'Estimasi waktu wajib dipilih',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Kategori"
                            name="category"
                            extra="maksimal 3 kategori"
                            style={{ marginBottom: 0 }}
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'Kategori wajib diisi',
                                },
                            ]}>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{ width: '100%' }}
                                onChange={(value, option) => {
                                    setCategoryTag((prevState) => ({
                                        ...prevState,
                                        category: [...option],
                                    }));
                                }}>
                                {/* TODO Category option */}
                                {categoryOptions.map((el) => {
                                    return (
                                        <Option
                                            key={el.key}
                                            value={el.value}
                                            disabled={
                                                categoryTag.category.length >= 3
                                                    ? true
                                                    : false
                                            }>
                                            {el.text}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Tags"
                            name="tags"
                            extra="pisahkan dengan tanda koma"
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'Tags wajib diisi',
                                },
                            ]}>
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                onChange={(value, option) => {
                                    setCategoryTag((prevState) => ({
                                        ...prevState,
                                        tag: [...option],
                                    }));
                                }}
                                tokenSeparators={[',']}>
                                {/* TODO tags option */}
                                {tagsOptions.map((item) => {
                                    return (
                                        <Option
                                            key={item.key}
                                            value={item.value}>
                                            {item.text}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Nama Penulis"
                            name="author"
                            rules={[
                                {
                                    required: isDraft,
                                    message: 'Nama Penulis wajib diisi',
                                },
                            ]}>
                            <Select allowClear>
                                {authorOption.map((item) => {
                                    return (
                                        <Option key={item} value={item}>
                                            {item}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Publish Status"
                            name="publish_status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama Penulis wajib diisi',
                                },
                            ]}>
                            <Select
                                allowClear
                                onChange={(val) => {
                                    setIsDraft(val !== 'draft');
                                }}>
                                <Option key="Draft" value="draft">
                                    Draft
                                </Option>
                                <Option key="Published" value="published">
                                    Published
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item style={{ marginTop: '20px' }}>
                            <Button
                                style={{ marginRight: 10 }}
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}>
                                Simpan
                            </Button>
                            <Button
                                onClick={() => {
                                    history.goBack();
                                }}>
                                Batal
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default ArticleForm;
