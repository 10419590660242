import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    PageHeader,
    message,
    Row,
    Select,
    Upload,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceKiddofestBanner from '../index.service';
import KiddofestBannerEditStyle from './index.style';

const { Option } = Select;

export default function KiddofestBannerEdit() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = useState([]);
    const [dataBanner, setDataBanner] = useState();

    useEffect(() => {
        onGetData(id);
    }, [id]);

    const onGetData = async (id) => {
        try {
            const res = await serviceKiddofestBanner.getdetailBanner(id);
            if (res.isSuccess) {
                const resultData = res.response.payload;
                const dataForm = {
                    id: resultData.id,
                    categori_banner: resultData.category,
                    name_button: resultData.name_button,
                    link: resultData.link_button,
                    image: resultData.image_url,
                };
                form.setFieldsValue(dataForm);
                setFileUpload([
                    {
                        uid: '1',
                        name: 'Image',
                        status: 'done',
                        url: resultData.image_url,
                        size: 1999,
                    },
                ]);
                setDataBanner(resultData);
            } else {
                notifyError(res.message);
            }
        } catch (error) {
            notifyError('Failed to load banner data');
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const routes = [
        {
            path: '',
            breadcrumbName: 'MASTER BANNER',
        },
        {
            path: '',
            breadcrumbName: 'EDIT',
        },
    ];

    const onFinish = async (value) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('id', dataBanner.id);
        formData.append('category', value.categori_banner);
        formData.append('name_button', value.name_button);
        formData.append('link_button', value.link);
        if (typeof value.image === 'string') {
            formData.append('image_url', value.image);
        } else if (value.image && value.image.file) {
            formData.append('file', value.image.file.originFileObj);
        }

        try {
            const res = await serviceKiddofestBanner.updateDataBanner(formData);
            if (res.isSuccess) {
                notifySuccess('Sukses update banner');
                navigate('/kiddofest/banner');
            } else {
                notifyError('Failed to update banner');
            }
        } catch (error) {
            notifyError('Something went wrong during update');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <KiddofestBannerEditStyle>
            <PageHeader
                onBack={() => navigate(-1)}
                className="site-page-header"
                title="EDIT DATA MASTER BANNER"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <Form.Item
                            name="categori_banner"
                            label="Edit Kategori Banner"
                            rules={[
                                {
                                    required: true,
                                    message: 'Kategori banner wajib diisi',
                                },
                            ]}>
                            <Select placeholder="Pilih kategori banner">
                                <Option key="1" value="hero_banner">Hero Banner</Option>
                                <Option key="2" value="logo_festival">Logo Festival</Option>
                                <Option key="3" value="banner_slider">Banner Slider</Option>
                                <Option key="4" value="gambar_edufair">Gambar Edufair</Option>
                                <Option key="5" value="gambar_foodbazar">Gambar FoodBazaar</Option>
                                <Option key="6" value="banner_acara">Banner Acara</Option>
                                <Option key="7" value="sponsor_site">Logo Site Sponsor Kiddofest</Option>
                                <Option key="8" value="mitra_site">Logo Site Mitra Kiddofest</Option>
                                <Option key="9" value="sponsor_beranda">Logo Sponsor Beranda</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item name="name_button" label="Edit Nama Button / Banner">
                            <Input placeholder="Edit Nama button atau banner" />
                        </Form.Item>

                        <Form.Item name="link" label="Edit Link URL">
                            <Input placeholder="Masukkan link url atau '-' jika tidak ada" />
                        </Form.Item>

                        <Form.Item
                            name="image"
                            label="Edit Banner"
                            rules={[
                                {
                                    required: true,
                                    message: 'Banner wajib diisi',
                                },
                            ]}>
                            <Upload
                                listType="picture"
                                customRequest={dummyRequest}
                                accept="image/*"
                                onChange={(info) => {
                                    const isLt2M = info.file.size / 1024 / 1024 < 2;
                                    if (!isLt2M) {
                                        message.error('Banner harus lebih kecil dari 2 MB!');
                                    } else {
                                        setFileUpload(info.fileList);
                                    }
                                }}
                                fileList={fileUpload}
                            >
                                {fileUpload.length === 0 && (
                                    <Button icon={<UploadOutlined />}>Upload Banner</Button>
                                )}
                            </Upload>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                style={{ marginRight: 10, width: 200 }}
                            >
                                Simpan
                            </Button>
                            <Button style={{ width: 200 }} onClick={() => navigate(-1)}>
                                Batal
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </KiddofestBannerEditStyle>
    );
}
