import React, { useState, useEffect } from 'react';
import { Button, Form, Modal } from 'antd';
import PictureForm from '../PictureForm';
import galleryService from '../index.service';

const UpdatePicture = ({ active, getImage, setActive, pictureDetail, setPictureDetail }) => {
  const [form] = Form.useForm();
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    if (pictureDetail) {
      form.setFieldsValue(pictureDetail); // Set form initial values
    }
  }, [pictureDetail, form]);

  const updatePict = async (data) => {
    setLoadingUpdate(true);
    const dataPut = {
      ...data,
      id: pictureDetail.id,
    };
    const result = await galleryService.updatePicture(dataPut);
    if (result.isSuccess) {
      setActive(false);
      setPictureDetail();
      getImage();
    }
    setLoadingUpdate(false);
  };

  const deletePict = async () => {
    const ret = window.confirm('Are you sure you want to delete this picture?');
    if (ret) {
      setLoadingDelete(true);
      const result = await galleryService.deletePicture(pictureDetail.id);
      if (result.isSuccess) {
        setActive(false);
        setPictureDetail();
        getImage();
      }
      setLoadingDelete(false);
    }
  };

  return (
    <Modal
      open={active} // Replaced 'visible' with 'open'
      width={800}
      onCancel={() => {
        setActive(false);
        setPictureDetail();
        form.resetFields(); // Reset form on modal close
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setActive(false);
            form.resetFields();
          }}
        >
          Cancel
        </Button>,
        <Button
          key="delete"
          type="primary"
          loading={loadingDelete} // Correctly used `loading`
          onClick={() => {
            deletePict();
            form.resetFields();
          }}
          danger
        >
          Delete
        </Button>,
        <Button
          key="update"
          type="primary"
          loading={loadingUpdate}
          onClick={() => {
            form.validateFields().then((value) => {
              updatePict(value);
              form.resetFields();
            });
          }}
        >
          Update
        </Button>,
      ]}
    >
      <PictureForm form={form} initValue={pictureDetail} />
    </Modal>
  );
};

export default UpdatePicture;
