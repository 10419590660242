import React from 'react';
import FlashSaleAddStyle from './index.style';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    PageHeader,
    Row,
    Upload,
    message,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import serviceFlashSale from '../index.service';
import { notifyError, notifySuccess } from '../../../utils';
import DebounceSelectComponent from '../../../components/DebounceSelect';

const FlashSaleAdd = () => {
    const navigate = useNavigate(); // Ganti 'history' menjadi 'navigate'
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = React.useState(false);
    const [file, setFile] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [dataProduct, setDataProduct] = React.useState([]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'Flash Sale',
        },
        {
            path: '',
            breadcrumbName: 'Create',
        },
    ];

    const uploadButton = (
        <div>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const onFinish = async (values) => {
        let resultsThumbnail = '';

        if (!!values.file) {
            const formData = new FormData();
            formData.append('photo', values.file.file.originFileObj);
            const uploadThumbnail = await serviceFlashSale.onUploadImage(
                formData,
            );
            if (uploadThumbnail.isSuccess) {
                resultsThumbnail = uploadThumbnail.response.data;
            }
        }

        const resultValues = {
            name: values.name,
            thumbnails: resultsThumbnail,
            start_date: `${moment(values.start_date).format(
                'YYYY-MM-DDTHH:mm:ss',
            )}Z`,
            finish_date: `${moment(values.finish_date).format(
                'YYYY-MM-DDTHH:mm:ss',
            )}Z`,
            products: values.product.map((item) => item.value),
        };
        const results = await serviceFlashSale.createFlashSale(resultValues);
        if (results.isSuccess) {
            notifySuccess('Success add new flashsale');
            navigate('/flashsale'); // Ganti 'history.push' menjadi 'navigate'
        } else {
            notifyError(results.message);
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'You can only upload JPG/PNG file!',
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading...' }, 1000);
            setIsLoading(true);
            setFile(info.file.originFileObj);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            return;
        }
    };

    return (
        <FlashSaleAddStyle>
            <PageHeader
                onBack={() => navigate(-1)} // Ganti 'history.goBack' menjadi 'navigate(-1)'
                className="site-page-header"
                title="Form Add Flash Sale"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={16} sm={16} xs={16}>
                        <Form.Item
                            name="name"
                            label="Judul Flash Sale"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Judul Flash Sale wajib diisi',
                                },
                            ]}>
                            <Input
                                size="large"
                                type={'text'}
                                placeholder="Masukkan judul flash sale"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={16} sm={16} xs={16}>
                        <div className="form-uploader">
                            <h4>
                                <span style={{ color: 'red' }}>*</span> Gambar
                            </h4>
                            <Form.Item name="file">
                                <Upload
                                    accept="image/*"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    customRequest={dummyRequest}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}>
                                    {file ? (
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={16} sm={16} xs={16}>
                        <Form.Item
                            name="product"
                            label="Product"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}>
                            <DebounceSelectComponent
                                onChange={(value) =>
                                    form.setFieldValue('product', value)
                                }
                                value={form.getFieldValue('product')}
                                placeholder="Select Product"
                                fetchList={async (value) => {
                                    const params = {
                                        queries: {
                                            page: 1,
                                            rows: 10,
                                        },
                                        filters: {
                                            title: value,
                                        },
                                    };
                                    const result =
                                        await serviceFlashSale.getProduct(
                                            params,
                                        );
                                    if (result.isSuccess) {
                                        const resultData =
                                            result.response.payload &&
                                            result.response.payload.length >
                                                0 &&
                                            result.response.payload.map(
                                                (item) => ({
                                                    label: item.title,
                                                    value: item.id,
                                                }),
                                            );
                                        return resultData;
                                    }
                                    return [];
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            name="start_date"
                            label="Mulai FlashSale"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Mulai Flash Sale wajib diisi',
                                },
                            ]}>
                            <DatePicker showTime />
                        </Form.Item>
                    </Col>
                    <Col md={8} sm={8} xs={8}>
                        <Form.Item
                            name="finish_date"
                            label="Selesai FlashSale"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Selesai Flash Sale wajib diisi',
                                },
                            ]}>
                            <DatePicker showTime />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="submit">
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={loading}
                            style={{
                                marginRight: 10,
                                marginTop: '24px',
                                width: 200,
                            }}
                            disabled={loading ? true : false}>
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => {
                                navigate(-1); // Ganti 'history.goBack' menjadi 'navigate(-1)'
                            }}>
                            Batal
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </FlashSaleAddStyle>
    );
};

export default FlashSaleAdd;
