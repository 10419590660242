import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import galleryService from '../index.service';
import { ModalAddPictureStyle } from '../index.style';

const PictureForm = ({ form, initValue, picture, setPicture }) => {
  const [loadingUploadPhoto, setLoadingUploadPhoto] = useState(false);
  const [mainImage, setMainImage] = useState('');

  useEffect(() => {
    if (initValue) {
      setMainImage(initValue.url);
      form.setFieldsValue({
        title: initValue?.title ?? '',
        caption: initValue?.caption ?? '',
        alternative_text: initValue?.alternative_text ?? '',
        description: initValue?.description ?? '',
        url: initValue?.url ?? '',
      });
    }
  }, [initValue, form]);

  const handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      message.loading({ content: 'Uploading...', key: 'updatable' });
    }
  };

  const uploadPic = async ({ file }) => {
    setLoadingUploadPhoto(true);
    const formData = new FormData();
    formData.append('file', file);
    const res = await galleryService.uploadPicture(formData);
    if (res.isSuccess) {
      const result = res.response.payload.url;
      setPicture(result);
      form.setFieldsValue({
        url: result,
      });
      setMainImage(result);
    } else {
      message.error('Failed to upload the image.');
    }
    setLoadingUploadPhoto(false);
  };

  const uploadButton = () => (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url);
    message.success('URL copied to clipboard');
  };

  return (
    <ModalAddPictureStyle>
      <Form layout="vertical" form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Picture"
              name="url"
              rules={[
                {
                  required: true,
                  message: 'The picture is required',
                },
              ]}
            >
              <Upload
                accept="image/*"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={uploadPic}
                onChange={handleUploadChange}
                disabled={loadingUploadPhoto || !!mainImage} // Disable when image is already uploaded
              >
                {mainImage ? (
                  <img src={mainImage} alt="uploaded" style={{ width: '100%' }} />
                ) : (
                  uploadButton()
                )}
              </Upload>
            </Form.Item>
          </Col>

          <Col span={12}>
            {mainImage && (
              <Form.Item label="Photo URL">
                <Input value={mainImage} disabled />
                <div className="copy-button">
                  <Button type="link" onClick={() => handleCopyLink(mainImage)}>
                    Copy link
                  </Button>
                </div>
              </Form.Item>
            )}
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'The title is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Caption" name="caption">
              <Input />
            </Form.Item>
            <Form.Item label="Text Alternative" name="alternative_text">
              <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </ModalAddPictureStyle>
  );
};

export default PictureForm;
