import React, { useState, useEffect } from "react";
import { notifyError } from '../../../utils';
import { PageHeader } from "antd";
import { useNavigate } from 'react-router-dom';
import UserDetailStyle from "./index.style";
import serviceUsers from "../index.service";
import UsersForm from "../../../components/Users/Form";

const UserDetail = (props) => {
    const history = useNavigate();
    const [userData, setUserData] = useState(null);
    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Users List',
        },
        {
            path: 'detail',
            breadcrumbName: 'Detail',
        },
    ];

    useEffect(() => {
        handleLoadData(props.match.params.id)
    }, [])

    const handleLoadData = async (id) => {
        const result = await serviceUsers.getUserDetail(id);
        if (result.isSuccess && result.response.payload !== null) {
            setUserData(result.response.payload);
        } else {
            notifyError(result.response.message);
        }
    };
    return (
        <UserDetailStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="User Detail"
                    breadcrumb={{ routes }}
                    subTitle="Table of User Detail"
                    style={{ paddingBottom: 0 }}
                />
            </div>
            <div className="content">
                <UsersForm initialValue={userData} />
            </div>
        </UserDetailStyle>
    );
}

export default UserDetail;