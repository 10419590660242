import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './layouts/MainLayout';
import ArticleList from './pages/Article';
import ArticleDetail from './pages/Article/Detail';
import ArticleCreate from './pages/Article/Create';
import Banner from './pages/Banner';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import MerchantGallery from './pages/MerchantGallery';
import OrderList from './pages/OrderList';
import OrderListDetail from './pages/OrderList/Detail';
import PageNotFound from './pages/PageNotFound';
import ProductList from './pages/Product/List';
import ProductDetail from './pages/Product/Detail';
import ProductDuplicate from './pages/Product/Duplicate';
import Settings from './pages/Settings';
import ProductCreate from './pages/Product/Create';
import TrackerTransaction from './pages/TrackerReport/Transaction';
import TrackerUserProfile from './pages/TrackerReport/UserProfile';
import TrackerMerchantAndProduct from './pages/TrackerReport/MerchantAndProduct';
import ListOfTicket from './pages/OperationAndFinance/ListOfTicket';
import MerchantSaldo from './pages/OperationAndFinance/MerchantSaldo';
import ArticlePilihan from './pages/Article/Pilihan';
import MerchantList from './pages/Merchant/List';
import MerchantCreate from './pages/Merchant/Create';
import MerchantDetail from './pages/Merchant/Detail';
import UsersList from './pages/Users/List';
import UserDetail from './pages/Users/Detail';
import PictureGallery from './pages/PictureGallery';
import Homepage from './pages/Homepage';
import JournalImmunization from './pages/Journal/Immunization/List';
import ImmunizationAdd from './pages/Journal/Immunization/Add';
import ImmunizationDetail from './pages/Journal/Immunization/Detail';
import ImmunizationEdit from './pages/Journal/Immunization/Edit';
import CodePromoMarketplaceList from './pages/CodePromo/Marketplace/List';
import CodePromoMarkeplaceAdd from './pages/CodePromo/Marketplace/Add';
import CodePromoPremiumList from './pages/CodePromo/Premium/List';
import CodePromoMarkeplaceEdit from './pages/CodePromo/Marketplace/Edit';
import CodePromoPremiumAdd from './pages/CodePromo/Premium/Add';
import CodePromoPremiumEdit from './pages/CodePromo/Premium/Edit';
import KiddofestBannerCreate from './pages/Kiddofest/Banner/Create';
import KiddofestBanner from './pages/Kiddofest/Banner';
import KiddofestBannerEdit from './pages/Kiddofest/Banner/Edit';
import KiddofestGalleryCreate from './pages/Kiddofest/Gallery/Create';
import KiddofestGallery from './pages/Kiddofest/Gallery';
import KiddofestGalleryEdit from './pages/Kiddofest/Gallery/Edit';
import KiddofestTestimoni from './pages/Kiddofest/Testimoni/List';
import KiddofestTestimoniAdd from './pages/Kiddofest/Testimoni/Add';
import KiddofestTestimoniEdit from './pages/Kiddofest/Testimoni/Edit';
import KiddofestEdufairList from './pages/Kiddofest/Edufair/List';
import KiddofestEdufairCreate from './pages/Kiddofest/Edufair/Create';
import KiddofestEdufairEdit from './pages/Kiddofest/Edufair/Edit';
import RegistrationMerchantKiddofest from './pages/Kiddofest/Registration/List';
import KiddofestListSchedule from './pages/Kiddofest/Schedule/List';
import KiddofestScheduleCreate from './pages/Kiddofest/Schedule/Create';
import KiddofestScheduleEdit from './pages/Kiddofest/Schedule/Edit';
import KiddofestCoupon from './pages/Kiddofest/Coupon/List';
import LMSTeamList from './pages/LMS/Team/List';
import LMSTeamCreate from './pages/LMS/Team/Add';
import LMSTeamEdit from './pages/LMS/Team/Edit';
import LMSTestimoni from './pages/LMS/Testimoni/List';
import LMSTestimoniCreate from './pages/LMS/Testimoni/Add';
import LMSTestimoniEdit from './pages/LMS/Testimoni/Edit';
import InboxPage from './pages/Inbox';
import LMSCertificate from './pages/LMS/Certificate/List';
import LMSParticipant from './pages/LMS/Participant/LIst';
import LMSQuiz from './pages/LMS/Quiz/List';
import LMSQuizCreate from './pages/LMS/Quiz/Add';
import LMSClassList from './pages/LMS/Class/List';
import LMSClassCreate from './pages/LMS/Class/Add';
import LMSQuizEdit from './pages/LMS/Quiz/Edit';
import LMSClassEdit from './pages/LMS/Class/Edit';
import MerchantHistorySubscription from './pages/Merchant/History';
import FlashSaleList from './pages/FlashSale/List';
import FlashSaleAdd from './pages/FlashSale/Add';
import FlashSaleEdit from './pages/FlashSale/Edit';
import KioBanner from './pages/Kio/MasterBanner';
import KioBannerCreate from './pages/Kio/MasterBanner/Create';
import KioBannerEdit from './pages/Kio/MasterBanner/Edit';
import KioMasterEpisode from './pages/Kio/MasterEpisode';
import MasterEpisodeCreate from './pages/Kio/MasterEpisode/Create';
import KioSubEpisodeList from './pages/Kio/MasterEpisode/SubEpisode';
import SubEpisodeCreate from './pages/Kio/MasterEpisode/SubEpisode/Create';
import SubEpisodeEdit from './pages/Kio/MasterEpisode/SubEpisode/Edit';
import MasterEpisodeEdit from './pages/Kio/MasterEpisode/Edit';
import CampaignBanner from './pages/Campaign/Banner/list';
import CampaignPage from './pages/Campaign/Page/List';
import CampaignAdd from './pages/Campaign/Page/Add';
import CampaignEdit from './pages/Campaign/Page/Edit';
import CampaignBannerAdd from './pages/Campaign/Banner/add';
import CampaignBannerEdit from './pages/Campaign/Banner/edit';
import GiftCategory from './pages/Gift/Category/List';
import GiftCategoryAdd from './pages/Gift/Category/Add';
import GiftCategoryEdit from './pages/Gift/Category/Edit';
import OrderGift from './pages/OrderGift';
import OrderGiftDetail from './pages/OrderGift/DetailOrder';
import GiftProductList from './pages/Gift/Product/List';
import GiftProductAdd from './pages/Gift/Product/Add';
import GiftProductEdit from './pages/Gift/Product/Edit';
import ProviderPrefixList from './pages/PPOB/ProviderPrefix/List';
import ProviderPrefixAdd from './pages/PPOB/ProviderPrefix/Add';
import OrderPpob from './pages/PPOB/OrderPPOB/List';
import ProviderPrefixEdit from './pages/PPOB/ProviderPrefix/Edit';
import PPOBProductList from './pages/PPOB/Products/List';
import OrderPpobDetail from './pages/PPOB/OrderPPOB/Detail';
import ProductPPOB from './pages/PPOB/Products/Add';
import ProductEditPPOB from './pages/PPOB/Products/Edit';
import { Helmet } from 'react-helmet';

const App = () => {
    // CSP Rules
    const cspPolicy =
        "default-src 'self' *.kiddo.id; script-src 'self' *.googleapis.com; style-src 'self' 'unsafe-inline' *.kiddo.id *.googleapis.com; connect-src 'self' *.kiddo.id; form-action 'self'; img-src 'self' * blob: data: https://imagekiddo.s3.ap-southeast-1.amazonaws.com;  media-src 'self' *; object-src 'none';";
    return (
        <Router>
            <Helmet>
                <meta httpEquiv="Content-Security-Policy" content={cspPolicy} />
            </Helmet>
            <Routes>
                {/* Use `element` prop to pass JSX elements */}
                <Route path="/" element={<Login />} />

                {/* Protected Route */}
                <Route
                    path="/dashboard/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="/dashboard"
                                        element={<Dashboard />}
                                    />
                                    {/* Handle 404 page inside the nested routes */}
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Order List Route */}
                <Route
                    path="/order-list/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<OrderList />} />
                                    <Route
                                        path="detail/:id"
                                        element={<OrderListDetail />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Operation and Finance Routes */}
                <Route
                    path="/operation-and-finance/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="list-of-ticket"
                                        element={<ListOfTicket />}
                                    />
                                    <Route
                                        path="merchant-saldo"
                                        element={<MerchantSaldo />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Article Routes */}
                <Route
                    path="/article/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<ArticleList />} />
                                    <Route
                                        path="create"
                                        element={<ArticleCreate />}
                                    />
                                    <Route
                                        path="pilihan"
                                        element={<ArticlePilihan />}
                                    />
                                    <Route
                                        path="detail/:id"
                                        element={<ArticleDetail />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Homepage Route */}
                <Route
                    path="/homepage"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<Homepage />} />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Picture Gallery Route */}
                <Route
                    path="/picture-gallery"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<PictureGallery />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Inbox Route */}
                <Route
                    path="/inbox/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<InboxPage />} />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Flash Sale Routes */}
                <Route
                    path="/flashsale/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<FlashSaleList />}
                                    />
                                    <Route
                                        path="add"
                                        element={<FlashSaleAdd />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<FlashSaleEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Kio Banner Routes */}
                <Route
                    path="/kio/banner/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<KioBanner />} />
                                    <Route
                                        path="add"
                                        element={<KioBannerCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<KioBannerEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Kio Episode Routes */}
                <Route
                    path="/kio/episode/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<KioMasterEpisode />}
                                    />
                                    <Route
                                        path="add"
                                        element={<MasterEpisodeCreate />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<MasterEpisodeEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Kio Sub Episode Routes */}
                <Route
                    path="/kio/sub/episode/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=":id"
                                        element={<KioSubEpisodeList />}
                                    />
                                    <Route
                                        path="create/:id"
                                        element={<SubEpisodeCreate />}
                                    />
                                    <Route
                                        path="edit/:id/:sub"
                                        element={<SubEpisodeEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Campaign Banner Routes */}
                <Route
                    path="/campaign/banner/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<CampaignBanner />}
                                    />
                                    <Route
                                        path="add"
                                        element={<CampaignBannerAdd />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Campaign Banner Edit Route */}
                <Route
                    path="/campaign/banner/edit/:id"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<CampaignBannerEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Campaign Page Routes */}
                <Route
                    path="/campaign/page/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<CampaignPage />} />
                                    <Route
                                        path="add"
                                        element={<CampaignAdd />}
                                    />
                                    <Route
                                        path="edit/:id"
                                        element={<CampaignEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Gift Category Routes */}
                <Route
                    path="/gift/category/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<GiftCategory />} />
                                    <Route
                                        path="add"
                                        element={<GiftCategoryAdd />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<GiftCategoryEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Gift Product Routes */}
                <Route
                    path="/gift/product/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<GiftProductList />}
                                    />
                                    <Route
                                        path="add"
                                        element={<GiftProductAdd />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<GiftProductEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Order Gift Routes */}
                <Route
                    path="/order-gift/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<OrderGift />} />
                                    <Route
                                        path="detail/:id"
                                        element={<OrderGiftDetail />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* PPOB Provider Routes */}
                <Route
                    path="/ppob/provider/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<ProviderPrefixList />}
                                    />
                                    <Route
                                        path="add"
                                        element={<ProviderPrefixAdd />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<ProviderPrefixEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* PPOB Order Routes */}
                <Route
                    path="/ppob/order/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<OrderPpob />} />
                                    <Route
                                        path=":id"
                                        element={<OrderPpobDetail />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* PPOB Product Routes */}
                <Route
                    path="/ppob/product/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<PPOBProductList />}
                                    />
                                    <Route
                                        path="add"
                                        element={<ProductPPOB />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<ProductEditPPOB />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />
                {/* Banner Route */}
                <Route
                    path="/banner/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<Banner />} />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Merchant Gallery Route */}
                <Route
                    path="/merchant-gallery/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<MerchantGallery />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Product Routes */}
                <Route
                    path="/product/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<ProductList />} />
                                    <Route
                                        path="create"
                                        element={<ProductCreate />}
                                    />
                                    <Route
                                        path="detail/:id"
                                        element={<ProductDetail />}
                                    />
                                    <Route
                                        path="duplicate/:id"
                                        element={<ProductDuplicate />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Settings Route */}
                <Route
                    path="/settings/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<Settings />} />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Merchant Routes */}
                <Route
                    path="/merchant/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<MerchantList />} />
                                    <Route
                                        path="create"
                                        element={<MerchantCreate />}
                                    />
                                    <Route
                                        path="detail/:id"
                                        element={<MerchantDetail />}
                                    />
                                    <Route
                                        path="history/:id"
                                        element={
                                            <MerchantHistorySubscription />
                                        }
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Users Routes */}
                <Route
                    path="/users/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route path="" element={<UsersList />} />
                                    <Route
                                        path="detail/:id"
                                        element={<UserDetail />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Journal Immunization Routes */}
                <Route
                    path="/journal/immunization/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path=""
                                        element={<JournalImmunization />}
                                    />
                                    <Route
                                        path="create"
                                        element={<ImmunizationAdd />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<ImmunizationEdit />}
                                    />
                                    <Route
                                        path="detail/:id"
                                        element={<ImmunizationDetail />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Code Promo Routes */}
                <Route
                    path="/codepromo/marketplace/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="list"
                                        element={<CodePromoMarketplaceList />}
                                    />
                                    <Route
                                        path="add"
                                        element={<CodePromoMarkeplaceAdd />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<CodePromoMarkeplaceEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/codepromo/premium/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="list"
                                        element={<CodePromoPremiumList />}
                                    />
                                    <Route
                                        path="add"
                                        element={<CodePromoPremiumAdd />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<CodePromoPremiumEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Kiddofest Routes */}
                <Route
                    path="/kiddofest/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="banner"
                                        element={<KiddofestBanner />}
                                    />
                                    <Route
                                        path="gallery"
                                        element={<KiddofestGallery />}
                                    />
                                    <Route
                                        path="testimoni"
                                        element={<KiddofestTestimoni />}
                                    />
                                    <Route
                                        path="edufair"
                                        element={<KiddofestEdufairList />}
                                    />
                                    <Route
                                        path="registration"
                                        element={
                                            <RegistrationMerchantKiddofest />
                                        }
                                    />
                                    <Route
                                        path="schedule"
                                        element={<KiddofestListSchedule />}
                                    />
                                    <Route
                                        path="coupon"
                                        element={<KiddofestCoupon />}
                                    />
                                    <Route
                                        path="banner/add"
                                        element={<KiddofestBannerCreate />}
                                    />
                                    <Route
                                        path="banner/:id"
                                        element={<KiddofestBannerEdit />}
                                    />
                                    <Route
                                        path="gallery/add"
                                        element={<KiddofestGalleryCreate />}
                                    />
                                    <Route
                                        path="gallery/:id"
                                        element={<KiddofestGalleryEdit />}
                                    />
                                    <Route
                                        path="testimoni/add"
                                        element={<KiddofestTestimoniAdd />}
                                    />
                                    <Route
                                        path="testimoni/:id"
                                        element={<KiddofestTestimoniEdit />}
                                    />
                                    <Route
                                        path="edufair/add"
                                        element={<KiddofestEdufairCreate />}
                                    />
                                    <Route
                                        path="edufair/:id"
                                        element={<KiddofestEdufairEdit />}
                                    />
                                    <Route
                                        path="schedule/add"
                                        element={<KiddofestScheduleCreate />}
                                    />
                                    <Route
                                        path="schedule/:id"
                                        element={<KiddofestScheduleEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Learnerscamp Routes */}
                <Route
                    path="/learnerscamp/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="team"
                                        element={<LMSTeamList />}
                                    />
                                    <Route
                                        path="testimoni"
                                        element={<LMSTestimoni />}
                                    />
                                    <Route
                                        path="certificate"
                                        element={<LMSCertificate />}
                                    />
                                    <Route
                                        path="participant"
                                        element={<LMSParticipant />}
                                    />
                                    <Route path="quiz" element={<LMSQuiz />} />
                                    <Route
                                        path="class"
                                        element={<LMSClassList />}
                                    />
                                    <Route
                                        path="team/create"
                                        element={<LMSTeamCreate />}
                                    />
                                    <Route
                                        path="team/:id"
                                        element={<LMSTeamEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Learnerscamp Testimoni Routes */}
                <Route
                    path="/learnerscamp/testimoni/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="create"
                                        element={<LMSTestimoniCreate />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<LMSTestimoniEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Learnerscamp Quiz Routes */}
                <Route
                    path="/learnerscamp/quiz/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="create"
                                        element={<LMSQuizCreate />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<LMSQuizEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Learnerscamp Class Routes */}
                <Route
                    path="/learnerscamp/class/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="create"
                                        element={<LMSClassCreate />}
                                    />
                                    <Route
                                        path=":id"
                                        element={<LMSClassEdit />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />

                {/* Tracker Report Routes */}
                <Route
                    path="/tracker-report/*"
                    element={
                        <ProtectedRoute>
                            <MainLayout>
                                <Routes>
                                    <Route
                                        path="transaction"
                                        element={<TrackerTransaction />}
                                    />
                                    <Route
                                        path="user-profile"
                                        element={<TrackerUserProfile />}
                                    />
                                    <Route
                                        path="merchant-and-product"
                                        element={<TrackerMerchantAndProduct />}
                                    />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </MainLayout>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
