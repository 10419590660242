import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Space, Table, Tag } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatStringDate } from '../../../utils/format-date';
import { formatMoney } from '../../../utils/format-money';
import serviceListOfTicket from './index.service';
import ListOfTicketStyle from './index.style';

const ListOfTicket = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
    });
    const [dataTicket, setDataTicket] = React.useState([]);
    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Order List',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];
    const [dataFilterMerchant, setDataFilterMerchant] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => handleResetSearch(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
    });

    const handleStatus = (status) => {
        let color;
        if (status === 'sudah_ditukar') {
            color = 'green';
        }
        if (status === 'belum_ditukar') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.split('_').join(' ')}
            </Tag>
        );
    };

    const handleStatusPayment = (status) => {
        let color;
        if (status === 'paid') {
            color = 'green';
        }
        if (status === 'unpaid') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.split('_').join(' ')}
            </Tag>
        );
    };

    const columns = [
        {
            title: 'Order ID',
            dataIndex: 'order_id',
            key: 'order_id',
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.ticket_order_id
                ? queryString?.sorts?.ticket_order_id === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            render: (_, record) => {
                return (
                    <a href={`/order-list/detail/${record.order_id}`}>
                        {record.order_id}
                    </a>
                );
            },
            ...getColumnSearchProps('ticket_order_id'),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.ticket_date
                ? queryString?.sorts?.ticket_date === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            render: (_, record) => {
                return formatStringDate(
                    record.date,
                    'YYYY-MM-DD hh:mm:ss',
                    'LL',
                );
            },
        },
        {
            title: 'Status Penukaran',
            dataIndex: 'exchange_status',
            key: 'exchange_status',
            filters: [
                { text: 'Belum Ditukar', value: 'belum_ditukar' },
                { text: 'Sudah Ditukar', value: 'sudah_ditukar' },
            ],
            filteredValue: !!queryString?.filters?.ticket_exchange_status
                ? [queryString?.filters?.ticket_exchange_status]
                : null,
            render: (_, record) => handleStatus(record.exchange_status),
        },
        {
            title: 'Username',
            dataIndex: 'user_name',
            key: 'user_name',
            ...getColumnSearchProps('ticket_user_name'),
        },
        {
            title: 'User Email',
            dataIndex: 'user_email',
            key: 'user_email',
            ...getColumnSearchProps('ticket_user_email'),
        },
        {
            title: 'User Phone',
            dataIndex: 'user_phone_number',
            key: 'user_phone_number',
            ...getColumnSearchProps('ticket_user_phone_number'),
        },
        {
            title: 'Merchant',
            dataIndex: 'merchant_id',
            key: 'merchant_id',
        },
        {
            title: 'Merchant',
            dataIndex: 'merchant_name',
            key: 'merchant_name',
            filters: dataFilterMerchant,
            filterMultiple: false,
            filteredValue: !!queryString?.filters?.ticket_merchant_name
                ? [queryString?.filters?.ticket_merchant_name]
                : null,
        },
        {
            title: 'Merchant Email',
            dataIndex: 'merchant_email',
            key: 'merchant_email',
            ...getColumnSearchProps('ticket_merchant_email'),
        },
        {
            title: 'Commision',
            dataIndex: 'commission',
            key: 'commission',
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.ticket_commission
                ? queryString?.sorts?.ticket_commission === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            render: (_, record) => {
                return formatMoney(record.commission);
            },
        },
        {
            title: 'Status Pembayaran',
            dataIndex: 'payment_status',
            key: 'payment_status',
            filters: [
                { text: 'Belum dibayar', value: 'unpaid' },
                { text: 'Sudah dibayar', value: 'paid' },
            ],
            filterMultiple: false,
            filteredValue: !!queryString?.filters?.ticket_payment_status
                ? [queryString?.filters?.ticket_payment_status]
                : null,
            render: (_, record) => handleStatusPayment(record.payment_status),
        },
    ];

    const handleDataMerchantFilter = (data = []) => {
        if (data && data.length > 0) {
            setDataFilterMerchant(() => [
                ...(data.length > 0 &&
                    data.map((item) => {
                        return {
                            text: item,
                            value: item,
                        };
                    })),
            ]);
        }
    };

    const handleLoadData = async (param) => {
        setIsLoading(true);
        let resultMerchant = await serviceListOfTicket.onGetMerchantName();
        if (resultMerchant.isSuccess) {
            handleDataMerchantFilter(resultMerchant.response.payload);
        }
        let result = await serviceListOfTicket.onGetData(param);
        if (result.isSuccess) {
            setDataTicket(result.response);
            setIsLoading(false);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            ...resultQuery.filters,
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleResetFilter = () => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            if (sorter.field === 'ticket_id') {
                paramResult.sorts = {
                    ticket_order_id:
                        sorter.order === 'descend' ? 'desc' : 'asc',
                };
            } else if (sorter.field === 'date') {
                paramResult.sorts = {
                    ticket_date: sorter.order === 'descend' ? 'desc' : 'asc',
                };
            } else if (sorter.field === 'order_id') {
                paramResult.sorts = {
                    ticket_order_id:
                        sorter.order === 'descend' ? 'desc' : 'asc',
                };
            } else if (sorter.field === 'commission') {
                paramResult.sorts = {
                    ticket_commission:
                        sorter.order === 'descend' ? 'desc' : 'asc',
                };
            } else {
                paramResult.sorts = {
                    [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
                };
            }
        }

        if (!!filters.exchange_status) {
            paramResult.filters = {
                ticket_exchange_status: filters.exchange_status[0],
            };
        } else if (filters.payment_status) {
            paramResult.filters = {
                ticket_payment_status: filters.payment_status[0],
            };
        } else if (filters.merchant_name) {
            paramResult.filters = {
                ticket_merchant_name: filters.merchant_name[0],
            };
        } else {
            delete paramResult.filters;
        }

        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    return (
        <ListOfTicketStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="List Of Ticket"
                    breadcrumb={{ routes }}
                    subTitle="Table List Of Ticket"
                />
                <Button onClick={handleResetFilter}>Reset Filter</Button>
            </div>
            <Table
                scroll={{ x: 1500, y: 1000 }}
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataTicket.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataTicket.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </ListOfTicketStyle>
    );
};

export default ListOfTicket;
