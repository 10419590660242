import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Upload,
} from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';
import KiddofestTestimonicreateStyle, { WysiwygStyle } from './index.style';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceKiddofestTestimoni from '../index.service';

export default function KiddofestTestimoniAdd() {
    const [form] = Form.useForm();
    const history = useNavigate();
    const routes = [
        {
            path: '',
            breadcrumbName: 'TESTIMONI KIDDOFEST',
        },
        {
            path: '',
            breadcrumbName: 'Form',
        },
    ];
    const [file, setFile] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [rtValue, setRtValue] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            message.loading({ content: 'Uploading...' }, 1000);
            setLoading(true);
            setIsLoading(true);
            setFile(info.file.originFileObj);
            setTimeout(() => {
                setIsLoading(false);
                setLoading(false);
            }, 2000);
            return;
        }
    };

    const beforeUpload = (file) => {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({
                content: 'Anda hanya dapat mengunggah file JPG / PNG!',
            });
            return isJpgOrPng;
        }
        const isLt2M = file.size / 1024 / 1024 < 0.5;
        if (!isLt2M) {
            message.error({
                content: 'Ukuran file maksimum adalah 500Kb',
            });
            return isLt2M;
        }
        return isJpgOrPng && isLt2M;
        // return false;
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('title', values.title);
        formData.append('file', values.image.file.originFileObj);
        formData.append('desc', values.desc);
      

        const response = await serviceKiddofestTestimoni.postTestimoniData(formData);
        if (response.isSuccess) {
            setIsLoading(false);
            notifySuccess('Sukses menambahkan testimoni');
            history.push('/kiddofest/testimoni');
        } else {
            setIsLoading(false);
            notifyError(response.message);
        }
    };

    return (
        <KiddofestTestimonicreateStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Kembali ke Table Testimoni"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={16} xs={16}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="name"
                                label="Nama Testimoni"
                                required
                                validateTrigger={['onChange']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nama Testimoni wajib diisi',
                                    },
                                ]}>
                                <Input placeholder="Masukkan Nama Testimoni" />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="title"
                                label="Jabatan"
                                required
                                validateTrigger={['onChange']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Jabatan wajib diisi',
                                    },
                                ]}>
                                <Input placeholder="Masukkan Jabatan Orang yang memberikan Testimoni" />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="desc"
                                label="Deskripsi Testimoni"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Deksripsi Testimoni wajib diisi',
                                    },
                                ]}>
                                <WysiwygStyle>
                                    <ReactQuill
                                        theme="snow"
                                        value={rtValue}
                                        onChange={(val) => {
                                            if (val === '<p><br></p>') {
                                                val = null;
                                            }
                                            setRtValue(val);
                                            form.setFieldsValue({
                                                desc: val || null,
                                            });
                                        }}
                                        placeholder="Masukkan Deskrispsi Testimoni"
                                    />
                                </WysiwygStyle>
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={8} sm={8} xs={8}>
                        <div className="foto_utama">
                            <h4>
                                <span style={{ color: 'red' }}>*</span> Foto
                            </h4>
                            <Form.Item
                                name="image"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Foto utama wajib diisi!',
                                    },
                                ]}>
                                <Upload
                                    accept="image/*"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    customRequest={dummyRequest}
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}>
                                    {file ? (
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Form.Item>
                            <small>
                                Foto akan ditampilkan paling depan. Ukuran file
                                maksimum 500Kb dan resolusi maksimum 500x500
                                pixel.
                            </small>
                        </div>
                    </Col>
                    <Form.Item name="submit">
                        <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                shape="round"
                                loading={isLoading}
                                style={{ marginRight: 10, width: 200 }}
                                disabled={isLoading ? true : false}>
                                Simpan
                            </Button>
                            <Button
                                shape="round"
                                style={{ width: 200 }}
                                onClick={() => {
                                    history.goBack();
                                }}>
                                Batal
                            </Button>
                        </div>
                    </Form.Item>
                </Row>
            </Form>
        </KiddofestTestimonicreateStyle>
    );
}
