import axios from 'axios';
import localStorages from '../utils/localStorages';
import Cookies from 'js-cookie'; // Import js-cookie for cookie management
import qs from 'qs';

const getAuthToken = (options) => {
    let result = {};
    const token = Cookies.get('auth_token'); // Retrieve token from cookies

    if (options.token && token) {
        result = {
            Authorization: 'Bearer ' + token,
        };
    }

    result['Content-Type'] = options.formData
        ? 'multipart/form-data'
        : 'application/json';
    return result;
};

const collectResponse = async (response, options, status = 'FAILED') => {
    if (status === 'FAILED' && response.status === 401) {
        // Clear cookies and local storage on auth failure
        Cookies.remove('auth_token');
        localStorages().clear(); // This should clear localStorage as well

        window.history.pushState('/', '', '/'); // Redirect to home or login page
    }

    return {
        response: response?.data || false,
        status: status,
        type: response?.status,
        isSuccess: status === 'SUCCESS' || false,
        isError: status === 'FAILED' || false,
        headers: {
            count:
                response?.headers['x-total-result'] &&
                Number(response?.headers['x-total-result']),
        },
        message: options.message,
    };
};

const serviceApi = async (options) => {
    let baseUrl =
        options.appType === 2
            ? process.env.REACT_APP_API_URL2
            : process.env.REACT_APP_API_URL1;
    let axiosConfig = {
        baseURL: baseUrl,
        timeout: 20000,
    };

    if (options.noVersion) {
        options.url = `${options.url}`;
    } else {
        options.url = `/${options.version || 'v1'}${options.url}`;
    }

    axiosConfig.headers = getAuthToken(options);

    options.paramsSerializer = (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
    };

    const instance = axios.create(axiosConfig);
    return await instance(options)
        .then((res) => {
            return collectResponse(res, options, 'SUCCESS');
        })
        .catch((err) => {
            return collectResponse(err.response, options, 'FAILED');
        });
};

export default serviceApi;
