import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Input, PageHeader, Pagination, Table, message } from 'antd';
import MerchantHistorySubscriptionStyle from './index.style';
import serviceMerchantHistoryPaket from './index.service';
import { formatDate } from '../../../utils/format-date';
import { formatMoney } from '../../../utils/format-money';

const MerchantHistorySubscription = () => {
    const navigate = useNavigate(); // Updated from history to navigate
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(10);
    const [queryString, setQueryString] = useState({
        queries: {
            page: 1,
            row: 10,
        },
    });
    const [merchantList, setMerchantList] = useState([]);

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Merchant',
        },
        {
            path: 'list',
            breadcrumbName: 'History Paket',
        },
    ];

    const columns = [
        {
            title: 'No',
            width: 40,
            dataIndex: 'key', // Ensuring a unique key for each row
            key: 'no',
            render: (_, __, index) =>
                (queryString.queries.page - 1) * queryString.queries.row + index + 1,
        },
        {
            title: 'Tanggal Transaksi',
            dataIndex: 'start_date',
            key: 'transaction_date',
            render: (date) => formatDate(date, 'DD MMM YYYY'),
        },
        {
            title: 'Nama Paket',
            dataIndex: 'paket',
            key: 'paket_name',
        },
        {
            title: 'Nominal',
            dataIndex: 'price',
            key: 'price',
            render: (data) => formatMoney(data),
        },
        {
            title: 'Masa Berlaku',
            dataIndex: 'expired_date',
            key: 'expired_date',
            render: (date) =>
                date === '0001-01-01 00:00:00'
                    ? 'Selamanya'
                    : formatDate(date, 'DD MMM YYYY'),
        },
    ];

    useEffect(() => {
        handleLoadData(queryString);
    }, [queryString]);

    const handleLoadData = async (queryString) => {
        setIsLoading(true);
        try {
            const result = await serviceMerchantHistoryPaket.onGetListHistoryPaket(
                queryString,
                params.id
            );
            if (result.isSuccess) {
                setMerchantList(result.response);
            } else {
                message.error('Failed to load merchant history data');
            }
        } catch (error) {
            message.error('An error occurred while loading merchant history data');
        } finally {
            setIsLoading(false);
        }
    };

    const onPaginationChange = (page, pageSize) => {
        setQueryString({
            ...queryString,
            queries: {
                page,
                row: pageSize,
            },
        });
        setCurrentPage(page);
        setTotalPage(pageSize);
    };

    return (
        <>
            <div className="header">
                <PageHeader
                    onBack={() => navigate(-1)} // Use navigate(-1) to go back
                    className="site-page-header"
                    title="Merchant History"
                    breadcrumb={{ routes }}
                    subTitle="Table of merchant subscription history"
                    style={{ paddingLeft: 0 }}
                />
            </div>
            <MerchantHistorySubscriptionStyle>
                <Table
                    rowKey="id" // Ensured rowKey is a field from your data
                    loading={isLoading}
                    bordered
                    columns={columns}
                    dataSource={merchantList}
                    pagination={false}
                />
                <div className="pagination">
                    <Pagination
                        pageSize={queryString.queries.row}
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={merchantList.length}
                        onChange={onPaginationChange}
                    />
                </div>
            </MerchantHistorySubscriptionStyle>
        </>
    );
};

export default MerchantHistorySubscription;
