import React from 'react';
import CampaignBannerEditStyle from './index.style';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Select,
    Upload,
} from 'antd';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceCampaignBanner from '../index.service';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';

const { Option } = Select;

const CampaignBannerEdit = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);
    const params = useParams();
    const [dataBanner, setDataBanner] = React.useState();

    React.useEffect(() => {
        onGetDataCampaignBanner(params.id);
    }, []);

    const onGetDataCampaignBanner = async (id) => {
        const response = await serviceCampaignBanner.getCampaignBanner(id);
        if (response.isSuccess) {
            form.setFieldsValue(response.response.payload);
            setFileUpload([
                {
                    uid: '1',
                    name: 'Image',
                    status: 'done',
                    url: `${response.response.payload.image}?id=${
                        Math.floor(Math.random() * 50) + 1
                    }`,
                    size: 1999,
                },
            ]);
            setDataBanner(response.response.payload);
        }
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const formData = new FormData();
        if (typeof values.image === 'string') {
            formData.append('image', values.image);
        } else {
            formData.append('file', values.image.file.originFileObj);
        }
        formData.append('link_url', values.link_url);
        formData.append('id', dataBanner.id);

        const result = await serviceCampaignBanner.updateBanner(formData);
        if (result.isSuccess) {
            notifySuccess('Sukses Update Banner Campaign');
            history('/campaign/banner');
        } else {
            notifyError(result.message);
        }
        setIsLoading(false);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    const routes = [
        {
            path: '',
            breadcrumbName: 'PAGE CAMPAIGN',
        },
        {
            path: '',
            breadcrumbName: 'FORM',
        },
    ];

    return (
        <CampaignBannerEditStyle>
            <PageHeader
                onBack={() => history(-1)}
                className="site-page-header"
                title="Buat Page Camapign"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Upload Gambar"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Gambar wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onPreview={() => {}}
                                    onChange={(info) => {
                                        const isLt2M =
                                            info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error(
                                                'Gambar harus lebih kecil dari 2 MB!',
                                            );
                                        } else {
                                            setFileUpload(info.fileList);
                                        }
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}>
                                    {fileUpload.length === 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload Gambar
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="link_url"
                                label="Masukkan URL (Optional)"
                                style={{ marginBottom: 0 }}>
                                <Input
                                    size="large"
                                    type={'url'}
                                    placeholder="Masukkan URL"
                                />
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Update
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history(-1);
                                    }}>
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </CampaignBannerEditStyle>
    );
};

export default CampaignBannerEdit;
