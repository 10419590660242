import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    message,
    PageHeader,
    Row,
    Select,
    Upload,
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceKiddofestGallery from '../index.service';
import KiddofestGalleryCreateStyle from './index.style';

const { Option } = Select;

export default function KiddofestGalleryCreate() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'GALERI KIDDOFEST',
        },
        {
            path: '',
            breadcrumbName: 'FORM',
        },
    ];

    const onFinish = async (value) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('year', value.year);
        formData.append('size', value.size);
        formData.append('file', value.image.file.originFileObj);

        const res = await serviceKiddofestGallery.postDataGallery(formData);
        if (res.isSuccess) {
            notifySuccess('Sukses menambahkan foto');
            navigate('/kiddofest/gallery');
        } else {
            notifyError('Something went wrong');
        }
        setIsLoading(false);
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    return (
        <KiddofestGalleryCreateStyle>
            <PageHeader
                onBack={() => navigate(-1)}
                className="site-page-header"
                title="TAMBAH GALERI"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="year"
                                label="Pilih Tahun Foto"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tahun pada foto wajib diisi',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Pilih Tahun foto"
                                    style={{ width: '100%' }}
                                >
                                    <Option value="2018">2018</Option>
                                    <Option value="2019">2019</Option>
                                    <Option value="2020">2020</Option>
                                    <Option value="2022">2022</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="size"
                                label="Pilih Skala Foto"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Skala foto wajib diisi',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Note: Skala 797x350 diberikan 1 ruang, sisanya skala 390x350"
                                    style={{ width: '100%' }}
                                >
                                    <Option value="797x350">Skala Foto 797 x 350</Option>
                                    <Option value="390x350">Skala Foto 390 x 350</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Foto"
                                style={{ marginBottom: 0 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Foto wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}
                            >
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onChange={(info) => {
                                        const isLt2M = info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error('Foto harus lebih kecil dari 2 MB!');
                                            return;
                                        }
                                        setFileUpload(info.fileList);
                                    }}
                                    fileList={fileUpload}
                                >
                                    {fileUpload.length === 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload Foto
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading}
                                >
                                    Simpan
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => navigate(-1)}
                                >
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </KiddofestGalleryCreateStyle>
    );
}
