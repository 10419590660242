import {
    Card,
    Col,
    Descriptions,
    PageHeader,
    Divider,
    Row,
    Table,
    Tag,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OrderPpobDetailStyle from './index.style';
import serviceOrderPPOBDetail from '../index.service';
import OrderPPOBDetailLoading from './index.loading';
import { formatMoney } from '../../../../utils/format-money';
import moment from 'moment';

const OrderPpobDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams(); // Fetch route params using useParams
    const [isLoading, setLoading] = useState(true);
    const [dataOrderDetail, setDataOrderDetail] = useState({});
    const [dataTables, setDataTables] = useState([]);

    useEffect(() => {
        handleLoadData(id);
    }, [id]);

    const handleLoadData = async (orderId) => {
        setLoading(true); // Ensure loading starts before data fetch
        const result = await serviceOrderPPOBDetail.getDataPpobDetail(orderId);
        if (result.isSuccess && result.response) {
            setDataOrderDetail(result.response.payload);
            setDataTables([
                {
                    ppob_name: result.response.payload.ppob_name,
                    user_phone: result.response.payload.user_phone,
                    payment_unix: result.response.payload.payment_unix,
                    ppob_type: result.response.payload.ppob_type,
                    param: result.response.payload.param,
                },
            ]);
        }
        setLoading(false); // Stop loading after data fetch
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'PPOB Order',
        },
        {
            path: 'detail',
            breadcrumbName: 'Detail',
        },
    ];

    const columns = [
        {
            id: 'ppob_name',
            key: 'ppob_name',
            title: 'Nama Produk',
            dataIndex: 'ppob_name',
        },
        {
            title: 'Nomor User',
            dataIndex: 'user_phone',
        },
        {
            title: 'No VA',
            dataIndex: 'reference_number',
            render: (text) => text || '-',
        },
        {
            title: 'Kategori',
            dataIndex: 'ppob_type',
        },
        {
            title: 'Periode (BPJS)',
            dataIndex: 'param',
            render: (text) => text || '-',
        },
    ];

    const handleLabelStatus = (status) => {
        let color;
        switch (status) {
            case 'Completed':
                color = 'green';
                break;
            case 'Pending':
                color = 'geekblue';
                break;
            case 'Cancelled':
                color = 'volcano';
                break;
            default:
                color = 'default';
        }
        return (
            <Tag color={color} key={status}>
                {status?.toUpperCase()}
            </Tag>
        );
    };

    return (
        <OrderPpobDetailStyle>
            <PageHeader
                onBack={() => navigate(-1)} // use navigate(-1) to go back
                className="site-page-header"
                title="Order Detail PPOB"
                breadcrumb={{ routes }}
                subTitle="Form Order Detail"
            />
            {isLoading ? (
                <OrderPPOBDetailLoading />
            ) : (
                <Row gutter={16}>
                    <Col span={24}>
                        <Card
                            title="Order PPOB Details"
                            bordered={true}>
                            <Descriptions bordered>
                                <Descriptions.Item
                                    label="Tanggal Order"
                                    span={2}>
                                    {moment
                                        .utc(dataOrderDetail.created_at)
                                        .format('DD MMMM YYYY')}
                                </Descriptions.Item>

                                <Descriptions.Item label="Order ID">
                                    {dataOrderDetail.order_id}
                                </Descriptions.Item>

                                <Descriptions.Item label="Harga">
                                    {formatMoney(dataOrderDetail.price)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Kode Promo">
                                    {dataOrderDetail.kodepromo || '-'}
                                </Descriptions.Item>

                                <Descriptions.Item label="Harga Diskon">
                                    {dataOrderDetail.sale_price
                                        ? formatMoney(
                                              dataOrderDetail.sale_price,
                                          )
                                        : '-'}
                                </Descriptions.Item>

                                <Descriptions.Item label="Nama Payment Gateway">
                                    {dataOrderDetail.payment_vendor}
                                </Descriptions.Item>

                                <Descriptions.Item label="Nama Pembayaran">
                                    {dataOrderDetail.payment_code}
                                </Descriptions.Item>

                                <Descriptions.Item label="VA BANK">
                                    {dataOrderDetail.payment_unix}
                                </Descriptions.Item>

                                <Descriptions.Item label="Biaya Admin">
                                    {formatMoney(dataOrderDetail.charge)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Status">
                                    {handleLabelStatus(dataOrderDetail.status)}
                                </Descriptions.Item>

                                <Descriptions.Item label="Point Klaim">
                                    {dataOrderDetail.kiddo_point}
                                </Descriptions.Item>

                                <Descriptions.Item label="Link Pembayaran">
                                    {dataOrderDetail.payment_send && (
                                        <>
                                            <p>Link pay:</p>
                                            {dataOrderDetail.payment_send}
                                            <Divider />
                                        </>
                                    )}
                                    {dataOrderDetail.payment_response && (
                                        <>
                                            <p>Payment gateway send:</p>
                                            {dataOrderDetail.payment_response}
                                            <Divider />
                                        </>
                                    )}
                                    {dataOrderDetail.payment_webhook && (
                                        <>
                                            <p>Payment gateway webhook:</p>
                                            {dataOrderDetail.payment_webhook}
                                            <Divider />
                                        </>
                                    )}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>

                    {/* Data User Kiddo */}
                    <Col span={8}>
                        <Card title="Data User Kiddo" bordered={true}>
                            <Descriptions bordered>
                                <Descriptions.Item label="Nama" span={3}>
                                    {dataOrderDetail.user_name}
                                </Descriptions.Item>
                                <Descriptions.Item label="No Hp" span={3}>
                                    {dataOrderDetail.user_phone}
                                </Descriptions.Item>
                                <Descriptions.Item label="Email" span={3}>
                                    {dataOrderDetail.user_email}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>

                    <Col span={16}>
                        <Card title="Data Pembelian Produk" bordered={true}>
                            <Table
                                rowKey="ppob_name"
                                columns={columns}
                                dataSource={dataTables}
                                loading={isLoading}
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </OrderPpobDetailStyle>
    );
};

export default OrderPpobDetail;
