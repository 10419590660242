import { SearchOutlined, LoadingOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    Select,
    message,
    PageHeader,
    Table,
    Tag,
    Menu,
    Dropdown,
    Input,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import serviceProductList from './index.service';
import ProductListStyle from './index.style';
import { formatMoney } from '../../../utils/format-money';
import moment from 'moment';
import { categori, subcat } from '../../../utils/index-data';

const ProductList = () => {
    const history = useNavigate();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [dataProductList, setDataProductList] = useState([]);
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const [dataFilterMerchant, setDataFilterMerchant] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(10);
    const [queryString, setQueryString] = useState({
        queries: {
            page: 1,
            row: 10,
        },
    });
    const [selectedValue, setSelectedValue] = useState(false);
    const [searchValue, setSearchValue] = React.useState({});

    const resetFilter = () => {
        setQueryString({
            queries: {
                page: 1,
                row: 10,
            },
        });
        setCurrentPage(1);
        setTotalPage(10);
    };

    useEffect(() => {
        handleLoadData(queryString);
        getMerchant({ search: 'all' });
    }, [queryString]);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceProductList.getProductList(resultQuery);
        if (result.isSuccess) {
            if (queryString.merchant !== '' && queryString.offset === 0) {
                setDataProductList(result.response);
            } else {
                setDataProductList(result.response);
            }
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    const getMerchant = async (params) => {
        const result = await serviceProductList.getMerchantList(params);
        if (result.isSuccess) {
            handleDataMerchantFilter(result.response.payload);
        } else {
            message.error('Failed to get Merchant data!');
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={searchValue[dataIndex] || ''}
                    onChange={(e) =>
                        setSearchValue((prevState) => ({
                            ...prevState,
                            [dataIndex]: e.target.value,
                        }))
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: '100%' }}
                    icon={<SearchOutlined />}
                    size="small"
                >
                    Search
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;
        if (!sorter.field) {
            delete paramResult.sorts;
        } else {
            const sorterMapping = {
                title: 'product_title',
                stock: 'product_stock',
                modified_at: 'product_modified_at',
            };
            paramResult.sorts = {
                [sorterMapping[sorter.field] || sorter.field]:
                    sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        paramResult.filters = {
            ...paramResult.filters,
            product_publish_status: filters.publish_status || null,
            category: filters.category || null,
        };

        setQueryString(paramResult);
    };

    const getColumnSelectProps = (dataIndex) => ({
        filterDropdown: ({ confirm }) => (
            <div style={{ padding: 8 }}>
                <Select
                    showSearch
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    value={searchValue[dataIndex] || ''}
                    onSelect={(val) => handleSearch(val, confirm, dataIndex, true)}
                >
                    {dataFilterMerchant.map((el, idx) => (
                        <Option key={idx} value={el.value}>
                            {el.text}
                        </Option>
                    ))}
                </Select>
                <Button type="primary" block onClick={resetFilter}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const handleSearch = (selectedKeys, confirm, dataIndex, isComponentSelect = false) => {
        let resultQuery = { ...queryString };
        resultQuery.queries = { row: 10, page: 1 };
        resultQuery.filters = {
            [dataIndex]: isComponentSelect ? selectedKeys : searchValue[dataIndex],
        };
        setQueryString(resultQuery);
    };

    const handleLabelStatus = (status) => {
        const colorMap = {
            publish: 'green',
            pending: 'geekblue',
            draft: 'volcano',
        };
        return (
            <Tag color={colorMap[status]} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    const handleDataMerchantFilter = (data = []) => {
        setDataFilterMerchant(
            data.map((item) => ({ text: item.name, value: item.name }))
        );
    };

    const handleResetFilter = () => {
        setSearchValue({});
        setQueryString({
            queries: { page: 1, row: 10 },
        });
        form.setFieldsValue({
            queries: { page: 1, row: 10 },
        });
    };

    const refreshPage = () => {
        setCurrentPage(1);
        setQueryString({
            queries: {
                ...queryString.queries,
                page: 1,
            },
        });
    };

    const routes = [
        { path: 'index', breadcrumbName: 'Product' },
        { path: 'list', breadcrumbName: 'List' },
    ];

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            render: (_, record) => (
                <Link to={`/product/detail/${record.id}`}>{record.id}</Link>
            ),
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (_, record) => <img src={record.image} alt={record.name} width="100" />,
        },
        {
            title: 'Product Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Merchant',
            dataIndex: 'merchant',
            key: 'merchant',
            ...getColumnSelectProps('merchant_name'),
        },
        {
            title: 'Categories',
            dataIndex: 'category',
            key: 'category',
            filters: [...categori, ...subcat],
            render: (_, record) =>
                record.categories.includes('Kelas Online')
                    ? record.categories
                          .map((cat) => (cat === 'Kelas Online' ? 'Video Learning' : cat))
                          .toString()
                    : record.categories.toString(),
            filteredValue: queryString?.filters?.category || null,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (_, record) => <span>{formatMoney(record.price)}</span>,
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
            sorter: true,
        },
        {
            title: 'Status',
            dataIndex: 'publish_status',
            key: 'publish_status',
            filters: [
                { text: 'DRAFT', value: 'draft' },
                { text: 'PUBLISH', value: 'publish' },
                { text: 'PENDING', value: 'pending' },
            ],
            render: (_, record) => handleLabelStatus(record.publish_status),
            filteredValue: queryString?.filters?.product_publish_status || null,
        },
        {
            title: 'Modified',
            dataIndex: 'modified_at',
            key: 'modified_at',
            sorter: true,
            render: (_, record) =>
                record.product_modified_at
                    ? moment(record.product_modified_at).format('DD-MM-YYYY HH:mm:ss')
                    : '-',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item>
                                <a
                                    href={`${process.env.REACT_APP_API_COMMERCE}${record.slug}`}
                                    target="_blank">
                                    <Button style={{ width: '100%' }} type="dashed">
                                        Preview
                                    </Button>
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <Button onClick={() => history(`/product/detail/${record.id}`)}>
                                Detail
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button
                                    onClick={() =>
                                        history(`/product/duplicate/${record.id}`)
                                    }
                                    style={{ width: '100%' }}
                                    type="dashed">
                                    Duplicate
                                </Button>
                            </Menu.Item>
                            <Menu.Item>
                                <Button
                                    style={{ width: '100%' }}
                                    type="dashed"
                                    onClick={async () => {
                                        const ret = window.confirm(
                                            'Are you sure you want to delete this product?'
                                        );
                                        if (ret) {
                                            const result = await serviceProductList.deleteProduct(record.id);
                                            if (result.isSuccess) {
                                                refreshPage(); // Refresh the page to reflect changes
                                            } else {
                                                message.error('Failed to delete product!');
                                            }
                                        }
                                    }}
                                >
                                    Delete
                                </Button>
                            </Menu.Item>
                        </Menu>
                    }
                    trigger={['click']}
                >
                    <Button>
                        <EllipsisOutlined />
                    </Button>
                </Dropdown>
            ),
        },
    ];

    return (
        <div>
            <PageHeader title="Product List" breadcrumb={{ routes }} />
            <ProductListStyle>
                <Button onClick={handleResetFilter} style={{ float: 'right' }}>
                    Reset Filter
                </Button>
                <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={dataProductList.payload}
                    onChange={handleChange}
                    pagination={{
                        current: currentPage,
                        total: dataProductList.total_payload,
                        onChange: (page) => {
                            setQueryString({
                                ...queryString,
                                queries: { ...queryString.queries, page },
                            });
                        },
                    }}
                />
            </ProductListStyle>
        </div>
    );
};

export default ProductList;
