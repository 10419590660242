import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Divider, Checkbox } from 'antd';
import LoginStyle from './index.style';
import Logo from '../../assets/Logo/index.logo';
import { useApp } from '../../contexts/app';
import localStorages from '../../utils/localStorages'; // Import localStorages utility
import { Navigate, useNavigate } from 'react-router-dom';
import { SET_USER } from '../../contexts/types';
import loginServices from './index.service';
import { notifySuccess, notifyError } from '../../utils';

const TOKEN_KEY = 'auth_token'; // Key for storing token

const Login = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [state, dispatch] = useApp();
    const [remember, setRemember] = useState(false);
    const [initialValues, setInitialValues] = useState({
        username: '',
        password: '',
        remember: true,
    });
    const [loading, setLoading] = useState(false);

    // Check for stored user data or token when component mounts
    useEffect(() => {
        const savedUser = localStorages().cookies.get('APP_USER_COOKIE'); // Get user data from cookies
        const token = localStorages().getToken(); // Check if token exists in cookies

        if (savedUser) {
            setInitialValues(JSON.parse(savedUser));
        }

        if (token) {
            navigate('/dashboard'); // Redirect if user is already logged in
        }
    }, [navigate]);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            // Prepare form data for login request
            const formData = new FormData();
            formData.append('email', values.username);
            formData.append('password', values.password);

            const result = await loginServices.login(formData);

            if (result.response?.code === 200) {
                notifySuccess('Berhasil Login');
                
                // Store token in cookies
                localStorages().saveToken(result.response.token);

                // Store or remove user data based on "Remember Me" status
                if (remember) {
                    localStorages().cookies.save('APP_USER_COOKIE', JSON.stringify(values), { expires: 7 }); // Save in cookies for 7 days
                } else {
                    localStorages().cookies.remove('APP_USER_COOKIE'); // Remove user data from cookies
                }

                const user = {
                    username: result.response.name,
                    token: result.response.token,
                    role: result.response.role,
                    menu: result.response.menu,
                };

                // Dispatch user information to the context
                dispatch({ type: SET_USER, payload: user });

                setLoading(false);
                navigate('/dashboard');
            } else {
                throw new Error(result.response.message);
            }
        } catch (error) {
            setLoading(false);
            notifyError(error.message || 'Login failed');
        }
    };

    const onChangeCheckbox = () => {
        setRemember(!remember);
    };

    // If user is already logged in, redirect to dashboard
    if (state?.user) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <LoginStyle>
            <Logo />
            <div className="container">
                <h4>Login Form</h4>
                <Divider />
                <Form
                    form={form}
                    onFinish={onFinish}
                    initialValues={initialValues}
                    layout="vertical"
                >
                    <Form.Item
                        name="username"
                        label="Username"
                        rules={[{ required: true, message: 'Username harus diisi' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[{ required: true, message: 'Password harus diisi' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox
                            checked={remember}
                            onChange={onChangeCheckbox}
                        >
                            Remember me
                        </Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </LoginStyle>
    );
};

export default Login;
