import { SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import {
    Button,
    Form,
    Select,
    PageHeader,
    Table,
    Tag,
    Dropdown,
    Input,
    Menu,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import serviceGiftProductList from './index.service';
import GiftProductListStyle from './index.style';
import { formatMoney } from '../../../../utils/format-money';
import moment from 'moment';
import ModalRemove from '../../../../components/ModalRemove';
import serviceGiftCategory from '../../Category/index.service';

const GiftProductList = () => {
    const history = useNavigate();
    const { Option } = Select;
    const [isLoading, setIsLoading] = useState(false);
    const [dataGiftProductList, setDataGiftProductList] = useState([]);
    const [dataCategory, setDataCategory] = useState([]);
    const [queryString, setQueryString] = useState({
        queries: { page: 1, row: 10 },
        sort: { 'gp.created_at': 'desc' },
    });
    const [searchValue, setSearchValue] = useState({});

    useEffect(() => {
        onGetCategory();
        handleLoadData(queryString);
    }, []);

    const onGetCategory = async () => {
        try {
            const res = await serviceGiftCategory.getListCategory();
            if (res.isSuccess && res?.response?.payload?.length > 0) {
                setDataCategory(res.response.payload.map((item) => ({
                    value: item.id,
                    text: item.name,
                })));
            }
        } catch (error) {
            console.error('Failed to fetch categories', error);
        }
    };

    const handleLoadData = async (params) => {
        setIsLoading(true);
        try {
            const result = await serviceGiftProductList.getGiftProductList(params);
            if (result.isSuccess) {
                setDataGiftProductList(result.response);
            }
        } catch (error) {
            console.error('Failed to load data', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ selectedKeys, confirm }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={searchValue[dataIndex] || ''}
                    onChange={(e) => setSearchValue((prevState) => ({
                        ...prevState,
                        [dataIndex]: e.target.value,
                    }))}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: '100%' }}
                    icon={<SearchOutlined />}
                    size="small"
                >
                    Search
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const getColumnSelectProps = (dataIndex) => ({
        filterDropdown: ({ confirm }) => (
            <div style={{ padding: 8 }}>
                <Select
                    showSearch
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    optionFilterProp="children"
                    value={searchValue[dataIndex] || ''}
                    onSelect={(val) => handleSearch(val, confirm, dataIndex, true)}
                >
                    {dataCategory.map((el, idx) => (
                        <Option key={idx} value={el.value}>
                            {el.text}
                        </Option>
                    ))}
                </Select>
                <Button type="primary" block onClick={resetFilter}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    });

    const handleSearch = (selectedKeys, confirm, dataIndex, isComponentSelect = false) => {
        const resultQuery = { ...queryString };
        resultQuery.queries = { row: 10, page: 1 };

        if (isComponentSelect) {
            setSearchValue((prevState) => ({ ...prevState, [dataIndex]: selectedKeys }));
            resultQuery.filters = { ...resultQuery.filters, [dataIndex]: selectedKeys };
        } else {
            resultQuery.filters = { ...resultQuery.filters, [dataIndex]: searchValue[dataIndex] };
        }

        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const resetFilter = () => {
        setSearchValue({});
        setQueryString({
            queries: { page: 1, row: 10 },
        });
        handleLoadData(queryString);
    };

    const handleLabelStatus = (status) => {
        if (!status) return null;
        let color;
        if (status === 'publish') color = 'green';
        if (status === 'pending') color = 'geekblue';
        if (status === 'draft') color = 'volcano';
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
    };

    const handleChange = (pagination, filters, sorter) => {
        const paramResult = { ...queryString };
        paramResult.queries = { row: pagination.pageSize, page: pagination.current };

        if (sorter.order) {
            paramResult.sorts = { [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc' };
        } else {
            delete paramResult.sorts;
        }

        if (filters.status) {
            paramResult.filters = { ...paramResult.filters, status: filters.status };
        } else {
            delete paramResult.filters;
        }

        setQueryString(paramResult);
        handleLoadData(paramResult);
    };

    const routes = [
        { path: 'index', breadcrumbName: 'Gift Product' },
        { path: 'list', breadcrumbName: 'List' },
    ];

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            sortOrder: queryString?.sorts?.id ? (queryString.sorts.id === 'desc' ? 'descend' : 'ascend') : null,
            render: (_, record) => <Link to={`/gift/product/${record.id}`}>{record.id}</Link>,
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text) => <img style={{ width: '60px' }} src={text} alt="Gift" />,
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            sortOrder: queryString?.sorts?.title ? (queryString.sorts.title === 'desc' ? 'descend' : 'ascend') : null,
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Category',
            dataIndex: 'category_id',
            key: 'category_id',
            filters: dataCategory,
            onFilter: (value, record) => record.category_id === value,
            sorter: true,
            sortOrder: queryString?.sorts?.category_id ? (queryString.sorts.category_id === 'desc' ? 'descend' : 'ascend') : null,
            render: (_, record) => <div>{record.category_name}</div>,
            ...getColumnSelectProps('category_id'),
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            sorter: true,
            sortOrder: queryString?.sorts?.price ? (queryString.sorts.price === 'desc' ? 'descend' : 'ascend') : null,
            render: (text) => formatMoney(text),
        },
        {
            title: 'Modified At',
            dataIndex: 'modified_at',
            key: 'modified_at',
            sorter: true,
            sortOrder: queryString?.sorts?.modified_at ? (queryString.sorts.modified_at === 'desc' ? 'descend' : 'ascend') : null,
            render: (text) => <div>{moment(text).format('DD/MM/YYYY')}</div>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Publish', value: 'publish' },
                { text: 'Pending', value: 'pending' },
                { text: 'Draft', value: 'draft' },
            ],
            onFilter: (value, record) => record.status === value,
            render: (_, record) => handleLabelStatus(record.status),
        },
        {
            title: 'Actions',
            key: 'operation',
            render: (_, record) => (
                <Dropdown
                    placement="bottomLeft"
                    overlay={
                        <Menu>
                            <Menu.Item onClick={() => history(`/gift/product/${record.id}`)}>
                                Edit
                            </Menu.Item>
                            <Menu.Item  onClick={async () => {
                                ModalRemove({
                                    configAPI: {
                                        url: `/gift/products/${record.id}`,
                                        method: 'DELETE',
                                        version: 'v1',
                                        token: true,
                                        successMessage:
                                            'Sukses Hapus Data Kategori Gift',
                                    },
                                    onReGetData: () => {
                                        handleLoadData(queryString);
                                    },
                                    title: 'Konfirmasi Hapus Data Kategori',
                                    desc: 'Apakah anda yakin, ingin mengapus data kategori ini ?',
                                });
                            }}>Delete</Menu.Item>
                        </Menu>
                    }
                >
                    <Button type="default" icon={<EllipsisOutlined />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <GiftProductListStyle>
            <PageHeader
                className="site-page-header"
                breadcrumb={{ routes }}
                title="List Gift Products"
            />
            <div className="container-content">
                <div className="flex flex-row justify-end" style={{ marginBottom: '20px' }}>
                    <Button type="primary" onClick={() => history('/gift/product/add')}>
                        Create Gift Product
                    </Button>
                </div>
                <Table
                    rowKey="id"
                    loading={isLoading}
                    dataSource={dataGiftProductList?.payload || []}
                    columns={columns}
                    pagination={{
                        total: dataGiftProductList.total || 0,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    onChange={handleChange}
                />
            </div>
        </GiftProductListStyle>
    );
};

export default GiftProductList;
