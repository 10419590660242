import MasterEpisodeCreateStyle from './index.style';
import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Upload,
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceKioMasterEpisode from '../index.service';

const MasterEpisodeCreate = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'MASTER EPISODE',
        },
        {
            path: '',
            breadcrumbName: 'FORM',
        },
    ];

    const onFinish = async (value) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('title', value.title);
        formData.append('photo', value.image.file.originFileObj);
        const res = await serviceKioMasterEpisode.postDataEpisode(formData);
        if (res.isSuccess) {
            setIsLoading(false);
            notifySuccess('Sukses menambahkan episode');
            history.push('/kio/episode');
        } else {
            setIsLoading(false);
            notifyError('Something went wrong');
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    return (
        <MasterEpisodeCreateStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Create Episode Kio"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="title"
                                label="Nama"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan Nama"
                                />
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Gambar Thumbnail"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Thumbnail wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onPreview={() => {}}
                                    onChange={(info) => {
                                        const isLt2M =
                                            info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error(
                                                'Thumbnail harus lebih kecil dari 2 MB!',
                                            );
                                        } else {
                                            setFileUpload(info.fileList);
                                        }
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}>
                                    {fileUpload.length == 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload Thumbnail
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Simpan
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history.goBack();
                                    }}>
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </MasterEpisodeCreateStyle>
    );
};

export default MasterEpisodeCreate;
