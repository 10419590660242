import { Button, Col, Form, Input, PageHeader, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceJournalImmunization from '../index.service';
import ImmunizationDetailStyle from './index.style';

const ImmunizationDetail = () => {
    const history = useNavigate();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const [rtValue, setRtValue] = useState('');
    const [detailDataImmunization, setDetailDataImmunization] = useState({});

    const routes = [
        {
            path: '',
            breadcrumbName: 'Immunization',
        },
        {
            path: '',
            breadcrumbName: 'Detail',
        },
    ];

    useEffect(() => {
        onGetDataImmunization(params.id);
    }, []);

    const onGetDataImmunization = async (id) => {
        const result = await serviceJournalImmunization.onGetDetailImmunization(
            id,
        );
        if (result.isSuccess) {
            let tempResultData = result.response.data;
            delete tempResultData.created_at;
            delete tempResultData.deleted_at;
            delete tempResultData.updated_at;
            setDetailDataImmunization(tempResultData);
            form.setFieldsValue(tempResultData);
        }
    };

    const onFinish = async (values) => {
        setIsLoading(true);
        const result = await serviceJournalImmunization.updateImmunization(
            params.id,
            values,
        );
        if (result.isSuccess) {
            notifySuccess('Success Update Data Immunization');
            history.push('/journal/immunization');
        } else {
            notifyError(result.message);
        }
        setIsLoading(false);
    };

    return (
        <ImmunizationDetailStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="Immunization Detail"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <p className="title">Umur</p>
                            <p className="value">
                                {detailDataImmunization.age}
                            </p>
                            {/* <Form.Item
                                name="age"
                                label="Umur"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Age wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    type={'number'}
                                    placeholder="Masukkan Umur Aktivitas"
                                />
                            </Form.Item> */}
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <p className="title">Nama</p>
                            <p className="value">
                                {detailDataImmunization.name}
                            </p>
                            {/* <Form.Item
                                name="name"
                                label="Nama"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Name wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Nama Aktivitas"
                                />
                            </Form.Item> */}
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <p className="title">Didapat Sebelum</p>
                            <p className="value">
                                {detailDataImmunization.have_before}
                            </p>
                            {/* <Form.Item
                                name="have_before"
                                label="Didapat Sebelum"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Didapat Sebelum wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    type={'number'}
                                    placeholder="Masukkan"
                                />
                            </Form.Item> */}
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <p className="title">Recommend</p>
                            <p className="value">
                                {detailDataImmunization.recommend}
                            </p>
                            {/* <Form.Item
                                name="recommend"
                                label="Recommend"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Recommend wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Recommend"
                                />
                            </Form.Item> */}
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <p className="title">Type</p>
                            <p className="value">
                                {detailDataImmunization.recommend_type}
                            </p>
                            {/* <Form.Item
                                name="recommend_type"
                                label="Type"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Recommend wajib diisi',
                                    },
                                ]}>
                                <Input
                                    size="large"
                                    type={'text'}
                                    placeholder="Masukkan Nama Aktivitas"
                                />
                            </Form.Item> */}
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <p className="title">Detail</p>
                            <p className="value">
                                {detailDataImmunization.detail}
                            </p>
                            {/* <Form.Item
                                name="detail"
                                label="Detail"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Detail Immunization wajib diisi',
                                    },
                                ]}> */}
                            {/* <ReactQuill
                                    theme="snow"
                                    value={rtValue}
                                    onChange={(val) => {
                                        if (val === '<p><br></p>') {
                                            val = null;
                                        }
                                        setRtValue(val);
                                        form.setFieldsValue({
                                            detail: val || null,
                                        });
                                    }}
                                    placeholder="Jelaskan Detail Immunization"
                                /> */}
                            {/* </Form.Item> */}
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <p className="title">Direct URL</p>
                            <p className="value">
                                {detailDataImmunization.direct_url}
                            </p>
                            {/* <Form.Item
                                name="direct_url"
                                label="Direct URL"
                                style={{ marginBottom: 0 }}>
                                <Input
                                    size="large"
                                    type={'url'}
                                    placeholder="Masukkan URL"
                                />
                            </Form.Item> */}
                        </div>
                    </Col>
                </Row>
            </Form>
        </ImmunizationDetailStyle>
    );
};

export default ImmunizationDetail;
