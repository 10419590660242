import React from 'react';
import serviceKiddofestCoupon from '../index.service';
import KiddofestCouponListStyle from './index.style';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, PageHeader, Popconfirm, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { Link, useNavigate } from 'react-router-dom';
import ModalRemove from '../../../../components/ModalRemove';
import { notifyError, notifySuccess } from '../../../../utils';
import moment from 'moment';

export default function KiddofestCoupon() {
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useNavigate();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filters: {
            'kiddofest_coupon.coupon': '',
        },
    });
    const [dataCoupon, setDataCoupon] = React.useState([]);
    const [loadingClaimCoupon, setLoadingClaimCoupon] = React.useState(false);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceKiddofestCoupon.getListCoupon(resultQuery);
        if (result.isSuccess) {
            setDataCoupon(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const onClaimCoupon = async (value) => {
        setLoadingClaimCoupon(true);
        const res = await serviceKiddofestCoupon.onClaimCoupon(value.id);
        if (res.isSuccess) {
            notifySuccess('Success Claim Coupon');
            handleLoadData(queryString);
        } else {
            notifyError(res.message);
        }
        setLoadingClaimCoupon(false);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: 80,
            key: 'name',
        },
        {
            title: 'No KTP',
            dataIndex: 'ktp',
            key: 'ktp',
            width: 80,
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
            render: (item, record, index) =>
                item === 'L' ? 'Laki-laki' : 'Perempuan',
        },
        {
            title: 'Date of Birth',
            dataIndex: 'birthday',
            key: 'birthday',
            width: 150,
            render: (item, record, index) => moment(item).format('DD-MMM-YYYY'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'No Handphone',
            dataIndex: 'handphone',
            key: 'handphone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Coupon',
            dataIndex: 'coupon',
            key: 'coupon',
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                if (record.status === 'N') {
                    return (
                        <Popconfirm
                            title="Are you sure claim this Coupon ?"
                            onConfirm={() => onClaimCoupon(record)}>
                            <Button
                                loading={loadingClaimCoupon}
                                className="btn-claim">
                                Claim Coupon
                            </Button>
                        </Popconfirm>
                    );
                } else {
                    return <Button className="btn-coupon">Coupon Taken</Button>;
                }
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Kiddofest Coupon',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filters['kiddofest_coupon.coupon'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <KiddofestCouponListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Kiddofest Coupon"
                    breadcrumb={{ routes }}
                    subTitle="Table of Kiddofest Coupon"
                />
            </div>
            <div className="btn-top-table">
                {/* <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="/kiddofest/schedule/add">+ Add Schedule</Link>
                </Button> */}
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataCoupon.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataCoupon.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </KiddofestCouponListStyle>
    );
}
