import React, { useContext } from 'react';
import { AppStateContext } from '../contexts/app';
import { Navigate } from 'react-router-dom';
import localStorages from '../utils/localStorages';

export default function ProtectedRoute({ children }) {
    const state = useContext(AppStateContext);

    // Check for user login status from either the context or local storage
    const isUserLoggedIn = state?.user || localStorages().get('APP_STATE')?.user;

    if (!isUserLoggedIn) {
        return <Navigate to="/" />;
    }

    return children;
}
