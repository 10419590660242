import { SearchOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Space, Table, Tag } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../../../utils/format-money';
import ListOfTicketStyle from '../ListOfTicket/index.style';
import serviceMerchantSaldo from './index.service';

const MerchantSaldo = () => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
    });
    const [dataMerchantSaldo, setDataMerchantSaldo] = React.useState([]);
    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Operation and Finance',
        },
        {
            path: 'merchant-saldo',
            breadcrumbName: 'Merchant Saldo',
        },
    ];
    const [dataFilterMerchant, setDataFilterMerchant] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleDataMerchantFilter = (data = []) => {
        if (data && data.length > 0) {
            setDataFilterMerchant(() => [
                ...(data.length > 0 &&
                    data.map((item) => {
                        return {
                            text: item,
                            value: item,
                        };
                    })),
            ]);
        }
    };

    const handleLoadData = async (param) => {
        setIsLoading(true);
        let resultMerchant = await serviceMerchantSaldo.onGetMerchantName();
        if (resultMerchant.isSuccess) {
            handleDataMerchantFilter(resultMerchant.response.payload);
        }
        const result = await serviceMerchantSaldo.onGetData(param);
        if (result.isSuccess) {
            setDataMerchantSaldo(result.response);
            setIsLoading(false);
        }
    };

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'Completed') {
            color = 'green';
        }
        if (status === 'Pending') {
            color = 'geekblue';
        }
        if (status === 'Cancelled') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    const columns = [
        {
            title: 'Merchant ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => {},
            sortOrder: !!queryString?.sorts?.merchant_id
                ? queryString?.sorts?.merchant_id == 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Nama Merchant',
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend', 'ascend'],
            filters: dataFilterMerchant,
            filteredValue: !!queryString?.haves?.merchant_name
                ? [queryString?.haves?.merchant_name]
                : null,
        },
        {
            title: 'Saldo Available',
            dataIndex: 'saldo_available',
            key: 'saldo_available',
            sortOrder: !!queryString?.sorts?.merchant_saldo_available
                ? queryString?.sorts?.merchant_saldo_available == 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            sorter: (a, b) => {},
            render: (_, record) => {
                {
                    return formatMoney(record.saldo_available);
                }
            },
        },
        {
            title: 'Saldo Siap Bayar',
            dataIndex: 'saldo_ready_withdraw',
            key: 'saldo_ready_withdraw',
            sortOrder: !!queryString?.sorts?.merchant_saldo_ready_withdraw
                ? queryString?.sorts?.merchant_saldo_ready_withdraw == 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            sorter: (a, b) => {},
            render: (_, record) => {
                return formatMoney(record.saldo_ready_withdraw);
            },
        },
        {
            title: 'Saldo Harus Dibayar',
            dataIndex: 'saldo_to_withdraw',
            key: 'saldo_to_withdraw',
            sortOrder: !!queryString?.sorts?.merchant_saldo_to_withdraw
                ? queryString?.sorts?.merchant_saldo_to_withdraw == 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            sorter: (a, b) => {},
            render: (_, record) => {
                return formatMoney(record.saldo_to_withdraw);
            },
        },
        {
            title: 'Status Penarikan Saldo',
            dataIndex: 'saldo_to_withdraw_status',
            key: 'saldo_to_withdraw_status',
            render: (status) => handleLabelStatus(status),
        },
        {
            title: 'Jumlah Tiket Sudah Ditukar',
            dataIndex: 'ticket_exchanged',
            key: 'ticket_exchanged',
            ellipsis: true,
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Jumlah Tiket Belum Ditukar',
            dataIndex: 'ticket_not_exchanged',
            key: 'ticket_not_exchanged',
            sorter: (a, b) => {},
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.merchant_ticket_unexchanged
                ? queryString?.sorts?.merchant_ticket_unexchanged == 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else if (sorter.field === 'id') {
            paramResult.sorts = {
                merchant_id: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        } else if (sorter.field === 'saldo_available') {
            paramResult.sorts = {
                merchant_saldo_available:
                    sorter.order === 'descend' ? 'desc' : 'asc',
            };
        } else if (sorter.field === 'saldo_ready_withdraw') {
            paramResult.sorts = {
                merchant_saldo_ready_withdraw:
                    sorter.order === 'descend' ? 'desc' : 'asc',
            };
        } else if (sorter.field === 'saldo_to_withdraw') {
            paramResult.sorts = {
                merchant_saldo_to_withdraw:
                    sorter.order === 'descend' ? 'desc' : 'asc',
            };
        } else if (sorter.field === 'ticket_exchanged') {
            paramResult.sorts = {
                merchant_ticket_exchanged:
                    sorter.order === 'descend' ? 'desc' : 'asc',
            };
        } else if (sorter.field === 'ticket_not_exchanged') {
            paramResult.sorts = {
                merchant_ticket_unexchanged:
                    sorter.order === 'descend' ? 'desc' : 'asc',
            };
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (!!!filters.name) {
            delete paramResult.haves;
        } else if (!!filters.name) {
            paramResult.haves = {
                merchant_name: filters.name[0],
            };
        }

        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const handleResetFilter = () => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    return (
        <ListOfTicketStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Merchant Saldo"
                    breadcrumb={{ routes }}
                    subTitle="Table of merchant saldo"
                />
                <Button onClick={handleResetFilter}>Reset Filter</Button>
            </div>
            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataMerchantSaldo.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataMerchantSaldo.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </ListOfTicketStyle>
    );
};

export default MerchantSaldo;
