import React from 'react';
import {
    Form,
    Image,
    Button,
    Upload,
    Tooltip,
    Input,
    Col,
    Row,
    message,
    Select,
} from 'antd';
import { DeleteFilled, UploadOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import serviceBanner from './Banner.service';

const InputItem = ({ bannerList, setBannerList, setIsUpdated, loadBanner }) => {
    const deleteBanner = async (id) => {
        const ret = window.confirm('Are you sure delete this Banner?');
        if (ret == true) {
            const result = await serviceBanner.deleteBanner(id);

            if (result.isSuccess) {
                loadBanner(null, 'all');
                return;
            }
        }
    };

    const positionHandling = (result) => {
        const { destination, source } = result;

        if (!result.destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const newArray = Array.from(bannerList);
        newArray.splice(
            destination.index,
            0,
            newArray.splice(source.index, 1)[0],
        );
        setBannerList(
            newArray.map((item, i) => {
                return {
                    ...item,
                    position: i,
                };
            }),
        );
        setIsUpdated(true);
    };

    const handleTargetUrl = (value, i) => {
        setIsUpdated(true);
        const newArray = Array.from(bannerList);
        newArray[i].target_url = value;
        setBannerList(newArray);
    };

    const handleChangePosition = (value, i) => {
        setIsUpdated(true);
        const newArray = Array.from(bannerList);
        newArray[i].position = Number(value);
        setBannerList(newArray);
    };

    const handleChangeCategory = (value, i) => {
        setIsUpdated(true);
        const newArray = Array.from(bannerList);
        newArray[i].category = value;
        setBannerList(newArray);
    };

    return (
        <>
            <DragDropContext onDragEnd={(result) => positionHandling(result)}>
                <Droppable
                    droppableId="droppable-1"
                    type="Banner"
                    direction="horizontal">
                    {(provided, _) => (
                        <div
                            style={{
                                display: 'flex',
                                overflow: 'auto',
                                whiteSpace: 'nowrap',
                                width: '100%',
                            }}
                            ref={provided.innerRef}
                            {...provided.droppableProps}>
                            {/* {inputBanner} */}
                            {bannerList &&
                                bannerList.length > 0 &&
                                bannerList
                                    .sort((a, b) => a.position - b.position)
                                    .map((item, i) => {
                                        return (
                                            <Draggable
                                                key={i}
                                                draggableId={`draggable-${i}`}
                                                index={i}>
                                                {(provided, snapshot) => (
                                                    <Col
                                                        span={6}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            ...provided
                                                                .draggableProps
                                                                .style,
                                                            boxShadow:
                                                                snapshot.isDragging
                                                                    ? '0 0 .4rem #666'
                                                                    : 'none',
                                                        }}>
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    '#FFF',
                                                                padding: '1rem',
                                                                position:
                                                                    'relative',
                                                                width: '100%',
                                                            }}>
                                                            <Image
                                                                style={{
                                                                    objectFit:
                                                                        'contain',
                                                                }}
                                                                width={'100%'}
                                                                height={'200px'}
                                                                src={
                                                                    item.image_url
                                                                }
                                                                fallback="/img/fbimage.png"
                                                            />
                                                            <Button
                                                                type="primary"
                                                                onClick={() =>
                                                                    deleteBanner(
                                                                        item.id,
                                                                    )
                                                                }
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    right: '10px',
                                                                    top: '10px',
                                                                }}
                                                                danger>
                                                                <DeleteFilled
                                                                    width={32}
                                                                />
                                                            </Button>

                                                            <Form.Item
                                                                label="Target Url"
                                                                name={`targeturl${i}`}>
                                                                <Tooltip
                                                                    title={
                                                                        item.url
                                                                    }>
                                                                    <Input
                                                                        value={
                                                                            item.target_url
                                                                        }
                                                                        onChange={(
                                                                            value,
                                                                        ) =>
                                                                            handleTargetUrl(
                                                                                value
                                                                                    .target
                                                                                    .value,
                                                                                i,
                                                                            )
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Position Banner"
                                                                name={`position${i}`}>
                                                                <Tooltip
                                                                    title={
                                                                        item.position
                                                                    }>
                                                                    <Input
                                                                        type="number"
                                                                        value={
                                                                            item.position
                                                                        }
                                                                        onChange={(
                                                                            value,
                                                                        ) =>
                                                                            handleChangePosition(
                                                                                value
                                                                                    .target
                                                                                    .value,
                                                                                i,
                                                                            )
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Category Banner"
                                                                name={`category${i}`}>
                                                                <Tooltip
                                                                    title={
                                                                        item.category
                                                                    }>
                                                                    <Select
                                                                        // style={{
                                                                        //     width: 120,
                                                                        // }}
                                                                        value={
                                                                            item.category
                                                                        }
                                                                        onChange={(
                                                                            value,
                                                                        ) => {
                                                                            handleChangeCategory(
                                                                                value,
                                                                                i,
                                                                            );
                                                                        }}
                                                                        options={[
                                                                            {
                                                                                label: 'Article Home',
                                                                                value: 'article_home',
                                                                            },
                                                                            {
                                                                                label: 'Pop Up',
                                                                                value: 'pop_up',
                                                                            },
                                                                            {
                                                                                label: 'Home Banner Kotak',
                                                                                value: 'home_banner_kotak',
                                                                            },
                                                                            {
                                                                                label: 'Home Fix Merchant',
                                                                                value: 'home_fix_merchant',
                                                                            },
                                                                            {
                                                                                label: 'Home Banner Kotak 2',
                                                                                value: 'home_banner_kotak_2',
                                                                            },
                                                                            {
                                                                                label: 'Home Banner Top 2',
                                                                                value: 'home_banner_top_2',
                                                                            },
                                                                            {
                                                                                label: 'Home Fix Banner Merchant',
                                                                                value: 'home_fix_banner_merchant',
                                                                            },
                                                                            {
                                                                                label: 'Home Fix Banner Community',
                                                                                value: 'home_fix_banner_community',
                                                                            },
                                                                            {
                                                                                label: 'Home Fix 2',
                                                                                value: 'home_fix_2',
                                                                            },
                                                                            {
                                                                                value: 'PCP Banner',
                                                                                label: 'pcp_banner',
                                                                            },
                                                                            {
                                                                                value: 'Home Banner Promo',
                                                                                label: 'home_banner_promo',
                                                                            },
                                                                            {
                                                                                label: 'Pop Up Temp',
                                                                                value: 'pop_up_temp',
                                                                            },
                                                                            {
                                                                                label: 'Banner LMS',
                                                                                value: 'banner_lms',
                                                                            },
                                                                            {
                                                                                label: 'Banner Catalog Desktop TOP',
                                                                                value: 'bannerdesktopcatalog_top',
                                                                            },
                                                                            {
                                                                                label: 'Banner Catalog Desktop Button',
                                                                                value: 'bannerdesktop_static_button',
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Tooltip>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default InputItem;
