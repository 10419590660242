import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Input, PageHeader, Table, Tooltip } from 'antd';
import {
    DeleteFilled,
    FileSearchOutlined,
    FormOutlined,
} from '@ant-design/icons';
import CodePromoMarkerplaceListStyle from './index.style';
import serviceCodePromoMarkerplace from '../index.service';
import { notifyError } from '../../../../utils';
import ModalRemove from '../../../../components/ModalRemove';
import moment from 'moment';

const { Search } = Input;

export default function CodePromoMarketplaceList() {
    const navigate = useNavigate(); // useNavigate hook
    const [dataCodePromoMarketplace, setDataCodePromoMarketplace] =
        React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        sorts: {
            'discount.id': 'DESC',
        },
    });

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        const result =
            await serviceCodePromoMarkerplace.getCodePromoMarketplaceList(
                params,
            );
        if (result.isSuccess) {
            setDataCodePromoMarketplace(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (item) => moment(item).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Code Voucher',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Coupun Total',
            dataIndex: 'coupon_total',
            key: 'coupon_total',
        },
        {
            title: 'Coupon Exist',
            dataIndex: 'coupon_exist',
            key: 'coupon_exist',
            ellipsis: true,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (item) => moment(item).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Expired Date',
            dataIndex: 'expired_date',
            key: 'expired_date',
            render: (item) => moment(item).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    navigate(`/codepromo/marketplace/${record.id}`) // Updated to navigate
                                }
                            />
                        </Tooltip>
                        {/* <Tooltip title="Detail">
                            <FileSearchOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    navigate(`/journal/immunization/detail/${record.id}`)
                                }
                            />
                        </Tooltip> */}
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/promo-codes/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage: 'Success Remove Code Promo',
                                        },
                                        onReGetData: () => handleLoadData(queryString),
                                        title: 'Delete Confirmation',
                                        desc: 'Are You Sure to Delete This Promo Code',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Promo Code Marketplace',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    return (
        <CodePromoMarkerplaceListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => navigate(-1)} // Updated to navigate with goBack functionality
                    className="site-page-header"
                    title="Promo Code Marketplace"
                    breadcrumb={{ routes }}
                    subTitle="Table of promo code list"
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="/codepromo/marketplace/add">
                        + Create Coupon Marketplace
                    </Link>
                </Button>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataCodePromoMarketplace.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataCodePromoMarketplace.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </CodePromoMarkerplaceListStyle>
    );
}
