import React, { useState, useEffect } from 'react';
import { Col, Input, message, Modal, PageHeader, Row, Form, Button, Spin, Upload } from 'antd';
import { ModalPictureStyle, PictureGalleryStyle } from './index.style';
import { useNavigate } from 'react-router-dom';
import articleService from '../Article/index.service';
import { UploadOutlined } from '@ant-design/icons';
import PictureForm from './PictureForm';
import galleryService from './index.service';
import UpdatePicture from './UpdatePicture';

const PictureGallery = () => {
  const navigate = useNavigate(); // Correct hook usage
  const [imageContent, setImageContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureModal, setPictureModal] = useState(false);
  const [addPictureModal, setAddPictureModal] = useState(false);
  const [pictureDetail, setPictureDetail] = useState();
  const [addPictureLoading, setAddPictureLoading] = useState(false);
  const [picture, setPicture] = useState();

  const [form] = Form.useForm();

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "/picture-gallery",
      breadcrumbName: "Picture Gallery",
    },
  ];

  const getImage = async () => {
    setIsLoading(true);
    const result = await galleryService.getPicture();

    if (result.isSuccess) {
      setImageContent(result.response.payload);
    } else {
      message.error('Failed to fetch images');
    }

    setIsLoading(false);
  };

  const addPicture = async (data) => {
    setAddPictureLoading(true);
    const result = await galleryService.addPicture(data);

    if (result.isSuccess) {
      setPicture();
      form.resetFields();
      setAddPictureModal(false);
      getImage();
    } else {
      message.error('Failed to add picture');
    }

    setAddPictureLoading(false);
  };

  useEffect(() => {
    getImage();
  }, []);

  return (
    <PictureGalleryStyle>
      <PageHeader
        onBack={() => navigate(-1)} // Corrected navigation
        className="site-page-header"
        title="Picture Gallery"
        breadcrumb={{ routes }}
        style={{ paddingLeft: 0 }}
      />
      <Spin spinning={isLoading} tip="Loading Image...">
        <div className="add-media">
          <Button onClick={() => setAddPictureModal(true)} icon={<UploadOutlined />}>
            Add Image
          </Button>
        </div>
        <div className="image-container">
          {imageContent && imageContent.length > 0 &&
            imageContent.map((item, index) => (
              <div
                className="image-content"
                key={index} // Added key prop
                onClick={() => {
                  setPictureModal(true);
                  setPictureDetail(item);
                }}
              >
                <img src={item.url} alt="" />
              </div>
            ))
          }
        </div>
      </Spin>
      <Modal
        open={addPictureModal} // Replaced `visible` with `open`
        width={800}
        onCancel={() => {
          form.resetFields();
          setAddPictureModal(false);
          setPicture();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              form.resetFields();
              setAddPictureModal(false);
              setPicture();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            loading={addPictureLoading}
            onClick={() => {
              form.validateFields().then((value) => {
                addPicture(value);
              });
            }}
          >
            Save
          </Button>,
        ]}
      >
        <PictureForm form={form} picture={picture} setPicture={setPicture} />
      </Modal>
      <UpdatePicture
        active={pictureModal}
        pictureDetail={pictureDetail}
        getImage={getImage}
        setPictureDetail={setPictureDetail}
        setActive={setPictureModal}
      />
    </PictureGalleryStyle>
  );
};

export default PictureGallery;
