import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Table, Tooltip } from 'antd';
import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { notifyError } from '../../../../utils';
import serviceKiddofestRegistrationBooth from '../index.service';
import RegistrationMerchantKiddofestStyle from './index.style';

const { Search } = Input;

export default function RegistrationMerchantKiddofest() {
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useNavigate();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filter: {
            'kiddofest_boot.name_brand': '',
        },
    });
    const [dataRegistrationBooth, setDataRegistrationBooth] = React.useState(
        [],
    );

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result =
            await serviceKiddofestRegistrationBooth.getListRegistrationBooth(
                resultQuery,
            );
        if (result.isSuccess) {
            setDataRegistrationBooth(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Name Brand',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'No Handphone',
            dataIndex: 'handphone',
            key: 'handphone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Kiddofest Registration Booth',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filter['kiddofest_boot.name_brand'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <RegistrationMerchantKiddofestStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="List Registration Booth"
                    breadcrumb={{ routes }}
                    subTitle="Table of Kiddofest Registration Booth"
                />
            </div>
            <div className="btn-top-table">
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataRegistrationBooth.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataRegistrationBooth.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </RegistrationMerchantKiddofestStyle>
    );
}
