import React from 'react';
import LMSParticipantStyle from './index.style';
import { Link, useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import serviceLMSParticipant from '../index.service';
import moment from 'moment';

const LMSParticipant = () => {
    const [isLoading, setLoading] = React.useState(false);
    const history = useNavigate();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filter: {
            'u.name': '',
        },
        sorts: {
            'cp.id': 'DESC',
        },
    });
    const [dataParticipant, setDataParticipant] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setLoading(true);
        let resultQuery = { ...params };
        const result = await serviceLMSParticipant.getListParticipant(
            resultQuery,
        );
        if (result.isSuccess) {
            setDataParticipant(result.response);
            setLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 60,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Nama Kelas',
            dataIndex: 'class_name',
            key: 'class_name',
        },
        {
            title: 'Nama User',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Tanggal Mulai Kelas',
            render: (_, record) =>
                moment(record.start_at).format('DD MMM YYYY'),
        },
        {
            title: 'Tanggal Kadaluarsa',
            render: (_, record) =>
                moment(record.expired_at).format('DD MMM YYYY'),
        },
        {
            title: 'Komentar',
            dataIndex: 'comment',
            key: 'comment',
            render: (_, record) => (!!record.coment ? record.comment : '-'),
        },
        {
            title: 'Upload Tugas 1',
            dataIndex: 'upload_assignment1',
            key: 'upload_assignment1',
            render: (_, record) => {
                if (record.upload_assignment1) {
                    return (
                        <Tooltip title="Edit">
                            <a href={record.upload_assignment1} target="_blank">
                                <Button>Lihat Hasil Tugas</Button>
                            </a>
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
        {
            title: 'Upload Tugas 2',
            dataIndex: 'upload_assignment2',
            key: 'upload_assignment2',
            render: (_, record) => {
                if (record.upload_assignment2) {
                    return (
                        <Tooltip title="Edit">
                            <a href={record.upload_assignment2} target="_blank">
                                <Button>Lihat Hasil Tugas</Button>
                            </a>
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
        {
            title: 'Upload Tugas 3',
            dataIndex: 'upload_assignment3',
            key: 'upload_assignment3',
            render: (_, record) => {
                if (record.upload_assignment3) {
                    return (
                        <Tooltip title="Edit">
                            <a href={record.upload_assignment3} target="_blank">
                                <Button>Lihat Hasil Tugas</Button>
                            </a>
                        </Tooltip>
                    );
                }
                return '-';
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'LMS Peserta',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filter['u.name'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <LMSParticipantStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="LMS Peserta"
                    breadcrumb={{ routes }}
                    subTitle="Table of LMS Peserta"
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}></Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataParticipant.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataParticipant.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </LMSParticipantStyle>
    );
};

export default LMSParticipant;
