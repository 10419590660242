import React, { useState, useEffect } from 'react';
import ProviderPrefixEditStyle from './index.style';
import { Button, Col, Form, Input, PageHeader, Row, Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import serviceProviderPrefix from '../index.service';
import { notifyError, notifySuccess } from '../../../../utils';

const ProviderPrefixEdit = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [dataPrefix, setDataPrefix] = useState([]);
    const [dataDetailProvider, setDataDetailProvider] = useState(null);
    const { id } = useParams();

    const routes = [
        {
            path: '',
            breadcrumbName: 'PROVIDER PREFIX',
        },
        {
            path: '',
            breadcrumbName: 'EDIT',
        },
    ];

    useEffect(() => {
        if (id) {
            onGetDetailProvider(id);
        }
        // Uncomment if needed to fetch prefix data
        // onGetDataPrefix();
    }, [id]);

    const onGetDataPrefix = async () => {
        const results = await serviceProviderPrefix.listProviderPrefix();
        if (results.isSuccess) {
            const prefixes = results.response.payload.map((item) => ({
                label: item.prefix,
                value: item.prefix,
            }));
            setDataPrefix(prefixes);
        } else {
            notifyError(results.response.message);
        }
    };

    const onGetDetailProvider = async (id) => {
        const results = await serviceProviderPrefix.getDetailProvider(id);
        if (results.isSuccess) {
            const { provider, prefix } = results.response.payload;
            const res = {
                provider,
                prefix: prefix.split(','),
            };
            setDataDetailProvider(results.response.payload);
            form.setFieldsValue(res);
        } else {
            notifyError(results.response.message);
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        const resultValues = {
            id: Number(id),
            provider: values.provider,
            prefix: values.prefix.join(','),
        };
        const results = await serviceProviderPrefix.updateProviderPrefix(resultValues);
        if (results.isSuccess) {
            notifySuccess('Successfully updated provider prefix');
            navigate('/ppob/provider');
        } else {
            notifyError(results.message);
        }
        setLoading(false);
    };

    return (
        <ProviderPrefixEditStyle>
            <PageHeader
                onBack={() => navigate(-1)}
                className="site-page-header"
                title="Update Provider Prefix"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={32} style={{ marginBottom: '24px' }}>
                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="provider"
                            label="Provider Name"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Provider name is required',
                                },
                            ]}>
                            <Input
                                size="large"
                                placeholder="Enter Provider Name"
                            />
                        </Form.Item>
                    </Col>

                    <Col md={18} sm={18} xs={18}>
                        <Form.Item
                            name="prefix"
                            label="Prefix Number"
                            style={{ marginBottom: 0 }}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Prefix number is required',
                                },
                            ]}>
                            <Select
                                mode="tags"
                                allowClear
                                options={dataPrefix}
                                style={{ width: '100%' }}
                                placeholder="Input & Select Prefix"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <div>
                        <Button
                            type="primary"
                            htmlType="submit"
                            shape="round"
                            loading={loading}
                            style={{ marginRight: 10, marginTop: '24px', width: 200 }}
                        >
                            Submit
                        </Button>
                        <Button
                            shape="round"
                            style={{ width: 200 }}
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </ProviderPrefixEditStyle>
    );
};

export default ProviderPrefixEdit;
