import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader, Modal } from 'antd';
import FormMerchant from '../../../components/Merchant/Form';
import MerchantFormStyle from '../../../components/Merchant/Form/index.style';
import serviceFormMerchant from './index.service';

const MerchantCreate = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Merchant',
        },
        {
            path: 'merchant-gallery',
            breadcrumbName: 'Create',
        },
    ];

    const handleCreateMerchant = async (data) => {
        setIsLoading(true);
        try {
            const result = await serviceFormMerchant.postMerchant(data);
            if (result.isSuccess) {
                modalSuccess();
            } else {
                const errorMessage = result.type === 409 
                    ? result.response.message 
                    : 'Gagal menambah data Merchant!';
                Modal.error({
                    title: 'Error',
                    content: errorMessage,
                });
            }
        } catch (error) {
            Modal.error({
                title: 'Error',
                content: 'An unexpected error occurred.',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const modalSuccess = () => {
        Modal.success({
            content: 'Berhasil menambah data Merchant',
            onOk: () => {
                navigate('/merchant');
            },
        });
    };

    return (
        <MerchantFormStyle>
            <PageHeader
                onBack={() => navigate(-1)} // Use navigate(-1) to go back
                className="site-page-header"
                title="Merchant Create"
                breadcrumb={{ routes }}
                style={{ paddingBottom: 0 }}
            />
            <div className="content">
                <FormMerchant
                    handleSubmit={handleCreateMerchant}
                    initVal={null}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                />
            </div>
        </MerchantFormStyle>
    );
};

export default MerchantCreate;
