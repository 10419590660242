import React from 'react';
import LMSClassListStyle from './index.style';
import { Link, useNavigate } from 'react-router-dom';
import ModalRemove from '../../../../components/ModalRemove';
import { notifyError, notifySuccess } from '../../../../utils';
import {
    DeleteFilled,
    EditOutlined,
    FileSearchOutlined,
    FormOutlined,
    SearchOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, PageHeader, Space, Table, Tooltip } from 'antd';
import serviceLMSClass from '../index.service';

const { Search } = Input;

const LMSClassList = () => {
    const history = useNavigate();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filter: {
            'class.name': '',
        },
        sorts: {
            'class.id': 'DESC',
        },
    });
    const [dataQuiz, setDataQuiz] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        handleLoadData(queryString);
        return () => {
            setDataQuiz([]);
        };
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceLMSClass.getListQuiz(resultQuery);
        if (result.isSuccess) {
            setDataQuiz(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            key: 'no',
            width: 60,
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Nama Merchant',
            dataIndex: 'merchant_name',
            key: 'merchant_name',
        },
        {
            title: 'Nama Product',
            dataIndex: 'name_product',
            key: 'name_product',
        },
        {
            title: 'Judul Kelas',
            dataIndex: 'judul_kelas',
            key: 'judul_kelas',
        },
        {
            title: 'Sub Judul Kelas',
            dataIndex: 'sub_judul',
            key: 'sub_judul',
        },
        {
            title: 'Tipe Kelas',
            dataIndex: 'type_class',
            key: 'type_class',
        },
        {
            title: 'Link Zoom',
            dataIndex: 'link_zoom',
            key: 'link_zoom',
        },
        {
            title: 'Link File',
            dataIndex: 'link_file',
            key: 'link_file',
        },
        {
            title: 'Action',
            dataIndex: '',
            width: 100,
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setDataQuiz([]);
                                    history.push(
                                        `/learnerscamp/class/${record.id}`,
                                    );
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={async () => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/class/class/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage:
                                                'Success Remove Data Class',
                                        },
                                        onReGetData: () => {
                                            handleLoadData(queryString);
                                        },
                                        title: 'Delete Confirmation',
                                        desc: 'Are You Sure to Delete Data Class',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Learners Camp Class',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.queries.page = 1;
        resultQuery.filter['class.name'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <LMSClassListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Learners Camp Class"
                    breadcrumb={{ routes }}
                    subTitle="Table of learners camp class "
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="/learnerscamp/class/create">+ Add Class</Link>
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataQuiz.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataQuiz.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </LMSClassListStyle>
    );
};

export default LMSClassList;
