import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, PageHeader, Space, Table, Tag } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifyError } from '../../utils';
import { formatStringDate } from '../../utils/format-date';
import { formatMoney } from '../../utils/format-money';
import serviceOrderList from './index.service';
import OrderListStyle from './index.style';

const OrderList = () => {
    const history = useNavigate();
    const [queryString, setQueryString] = useState({
        queries: {
            row: 10,
            page: 1,
        },
    });
    const [dataOrderList, setDataOrderList] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        const result = await serviceOrderList.getOrderList(resultQuery);
        if (result.isSuccess && result.response.payload.length > 0) {
            setDataOrderList(result.response);
            setIsLoading(false);
        } else {
            notifyError(result.response.message);
        }
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button
                        onClick={() => handleResetSearch(clearFilters)}
                        size="small"
                        style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : '',
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        resultQuery.filters = {
            [dataIndex]: selectedKeys[0],
        };
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    const handleResetSearch = (clearFilters) => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        clearFilters();
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const handleResetFilter = () => {
        const defaultQuery = {
            queries: {
                row: 10,
                page: 1,
            },
        };
        setQueryString(defaultQuery);
        setTimeout(() => {
            handleLoadData(defaultQuery);
        }, 500);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Order List',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    const handleLabelStatus = (status) => {
        let color;
        if (status === 'Completed') {
            color = 'green';
        }
        if (status === 'Pending') {
            color = 'geekblue';
        }
        if (status === 'Cancelled') {
            color = 'volcano';
        }
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    const columns = [
        {
            title: 'Order Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => { },
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.date
                ? queryString?.sorts?.date === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            render: (_, record) => {
                return formatStringDate(
                    record.date,
                    'DD-MMM-YYYY hh:mm:ss',
                    'LL',
                );
            },
        },
        {
            title: 'Order ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => { },
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.id
                ? queryString?.sorts?.id === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            ...getColumnSearchProps('id'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            ...getColumnSearchProps('phone_number'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'COMPLETE', value: 'Completed' },
                { text: 'PENDING', value: 'Pending' },
                { text: 'CANCEL', value: 'Cancelled' },
            ],
            filteredValue: queryString?.filters?.status || null,
            // onFilter: (value, record) => record.status.includes(value),
            ellipsis: true,
            render: (status) => handleLabelStatus(status),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            sorter: (a, b) => { },
            sortDirections: ['descend', 'ascend'],
            sortOrder: !!queryString?.sorts?.amount
                ? queryString?.sorts?.amount === 'desc'
                    ? 'descend'
                    : 'ascend'
                : null,
            render: (_, record) => {
                return formatMoney(record.amount);
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                        <Button
                            onClick={() => history(`/order-list/detail/${record.id}`)} // Changed to `history()`
                            style={{ width: '100%' }}
                            type="dashed">
                            Detail
                        </Button>
                    </div>
                );
            },
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!!!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);
    };

    return (
        <OrderListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history.goBack()}
                    className="site-page-header"
                    title="Order List"
                    breadcrumb={{ routes }}
                    subTitle="Table of order list "
                />
                <Button onClick={handleResetFilter}>Reset Filter</Button>
            </div>
            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataOrderList.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataOrderList.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </OrderListStyle>
    );
};

export default OrderList;
