import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import serviceProductDuplicate from "./index.service";
import UpdateProductStyle from "./index.style";
import { PageHeader, Modal } from "antd";
import FormProduct from "../../../components/Product/FormProduct";

function ProductDuplicate() {
  const navigate = useNavigate();
  const { id } = useParams(); // Access route params
  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const routes = [
    {
      path: "index",
      breadcrumbName: "Product",
    },
    {
      path: "merchant-gallery",
      breadcrumbName: "Update",
    },
  ];

  useEffect(() => {
    getProductDetail(id);
  }, [id]);

  const handleCreateProduct = async (data) => {
    setIsLoading(true);
    const result = await serviceProductDuplicate.createProduct(data);
    if (result.isSuccess) {
      modalSuccess();
      setIsLoading(false);
    } else {
      Modal.error({
        title: "Error",
        content: "Failed to create product!",
      });
      setIsLoading(false);
    }
  };

  const getProductDetail = async (id) => {
    const result = await serviceProductDuplicate.getProductDetail(id);
    if (result.isSuccess) {
      setInitialValues(result.response);
    } else {
      Modal.error({
        title: "Error",
        content: "Failed to retrieve product details!",
      });
    }
  };

  const modalSuccess = () => {
    Modal.success({
      content: "Product successfully created!",
      onOk: () => {
        navigate("/product");
      },
    });
  };

  return (
    <UpdateProductStyle>
      <PageHeader
        onBack={() => navigate(-1)}
        className="site-page-header"
        title="Product Update"
        breadcrumb={{ routes }}
        style={{ paddingLeft: 0 }}
      />
      <div>
        <FormProduct
          handleSubmit={handleCreateProduct}
          initVal={initialValues}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isCreate={false}
          isDuplicate={true}
        />
      </div>
    </UpdateProductStyle>
  );
}

export default ProductDuplicate;
