import { UploadOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    message,
    PageHeader,
    Row,
    Select,
    Upload,
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../../../utils';
import serviceKioBanner from '../index.service';
import KioBannerCreateStyle from './index.style';
const { Option } = Select;

export default function KioBannerCreate() {
    const history = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    const [form] = Form.useForm();
    const [fileUpload, setFileUpload] = React.useState([]);

    const routes = [
        {
            path: '',
            breadcrumbName: 'MASTER BANNER',
        },
        {
            path: '',
            breadcrumbName: 'FORM',
        },
    ];

    const onFinish = async (value) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('link_youtube', value.link);
        formData.append('photo', value.image.file.originFileObj);
        const res = await serviceKioBanner.postDataBanner(formData);
        if (res.isSuccess) {
            setIsLoading(false);
            notifySuccess('Sukses menambahkan Banner');
            history.push('/kio/banner');
        } else {
            setIsLoading(false);
            notifyError('Something went wrong');
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        }, 0);
    };

    return (
        <KioBannerCreateStyle>
            <PageHeader
                onBack={() => history.goBack()}
                className="site-page-header"
                title="UPLOAD BANNER KIO"
                breadcrumb={{ routes }}
                style={{ paddingLeft: 0 }}
            />
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={16} sm={24} xs={24}>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="image"
                                label="Banner"
                                style={{ marginBottom: 0 }}
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: 'Banner wajib diisi',
                                    },
                                ]}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Upload
                                    listType="picture"
                                    customRequest={dummyRequest}
                                    accept="image/*"
                                    onPreview={() => {}}
                                    onChange={(info) => {
                                        const isLt2M =
                                            info.file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            message.error(
                                                'Banner harus lebih kecil dari 2 MB!',
                                            );
                                        } else {
                                            setFileUpload(info.fileList);
                                        }
                                    }}
                                    fileList={fileUpload}
                                    defaultFileList={[...fileUpload]}>
                                    {fileUpload.length == 0 && (
                                        <Button icon={<UploadOutlined />}>
                                            Upload Banner
                                        </Button>
                                    )}
                                </Upload>
                            </Form.Item>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Form.Item
                                name="link"
                                label="Link Youtube"
                                style={{ marginBottom: 0 }}
                                validateTrigger={['onChange', 'onBlur']}>
                                <Input
                                    size="large"
                                    placeholder="Masukkan link youtube banner"
                                />
                            </Form.Item>
                        </div>

                        <Form.Item name="submit">
                            <div>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    shape="round"
                                    loading={isLoading}
                                    style={{ marginRight: 10, width: 200 }}
                                    disabled={isLoading ? true : false}>
                                    Simpan
                                </Button>
                                <Button
                                    shape="round"
                                    style={{ width: 200 }}
                                    onClick={() => {
                                        history.goBack();
                                    }}>
                                    Batal
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </KioBannerCreateStyle>
    );
}
