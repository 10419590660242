import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, PageHeader, Table, Tooltip } from 'antd';
import {
    DeleteFilled,
    FormOutlined,
} from '@ant-design/icons';
import CodePromoPremiumListStyle from './index.style';
import serviceCodePromoPremium from '../index.service';
import { notifyError, notifySuccess } from '../../../../utils';
import moment from 'moment';
import ModalRemove from '../../../../components/ModalRemove';

const { Search } = Input;

const PAGE_SIZE = 10;

export default function CodePromoPremiumList() {
    const navigate = useNavigate();
    const [dataCodePromoPremium, setDataCodePromoPremium] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: PAGE_SIZE,
            page: 1,
        },
        sorts: {
            'mt_discount.id': 'DESC',
        },
    });

    React.useEffect(() => {
        handleLoadData(queryString);
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        try {
            const result = await serviceCodePromoPremium.getCodePromoPremiumList(params);
            if (result.isSuccess) {
                setDataCodePromoPremium(result.response);
            } else {
                notifyError(result.response.message);
            }
        } catch (error) {
            notifyError('Failed to load data');
        } finally {
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * PAGE_SIZE + index + 1,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (item) => moment(item).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Code Voucher',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Coupon Total',
            dataIndex: 'coupon_total',
            key: 'coupon_total',
        },
        {
            title: 'Coupon Exist',
            dataIndex: 'coupon_exist',
            key: 'coupon_exist',
            ellipsis: true,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (item) => moment(item).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Expired Date',
            dataIndex: 'expired_date',
            key: 'expired_date',
            render: (item) => moment(item).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, record) => (
                <div
                    key={record.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}>
                    <Tooltip title="Edit">
                        <FormOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/codepromo/premium/${record.id}`)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteFilled
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                ModalRemove({
                                    configAPI: {
                                        url: `/premium/promo-codes/${record.id}`,
                                        method: 'DELETE',
                                        version: 'v1',
                                        token: true,
                                        successMessage: 'Success Remove Code Promo',
                                    },
                                    onReGetData: () => handleLoadData(queryString),
                                    title: 'Delete Confirmation',
                                    desc: 'Are You Sure to Delete This Promo Code',
                                });
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleChange = async (pagination, filters, sorter) => {
        const paramResult = {
            ...queryString,
            queries: {
                row: pagination.pageSize,
                page: pagination.current,
            },
            sorts: sorter.order
                ? {
                    [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
                }
                : undefined,
            filters: filters.status != null ? { status: filters.status } : undefined,
        };

        setQueryString(paramResult);
        await handleLoadData(paramResult);
    };

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'Promo Code Premium',
        },
        {
            path: 'list',
            breadcrumbName: 'List',
        },
    ];

    return (
        <CodePromoPremiumListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => navigate(-1)}
                    className="site-page-header"
                    title="Promo Code Premium"
                    breadcrumb={{ routes }}
                    subTitle="Table of promo code list"
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="/codepromo/premium/add">
                        + Create Coupon Premium
                    </Link>
                </Button>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    pageSize: queryString.queries.row,
                    total: dataCodePromoPremium.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                columns={columns}
                dataSource={dataCodePromoPremium.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </CodePromoPremiumListStyle>
    );
}
