import React from 'react';
import PPOBProductListStyle from './index.style';
import { useNavigate } from 'react-router-dom';
import servicePPOBProduct from '../index.service';
import { notifyError } from '../../../../utils';
import ModalRemove from '../../../../components/ModalRemove';
import moment from 'moment';
import { formatMoney } from '../../../../utils/format-money';
import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table, Tooltip, Input } from 'antd';

const { Search } = Input;

const PPOBProductList = () => {
    const history = useNavigate();
    const [dataProductList, setDataProductList] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false);
    const [dataProviderPrefix] = React.useState([]);
    const [queryString, setQueryString] = React.useState({
        queries: {
            page: 1,
            row: 10,
        },
        sorts: {
            created_at: 'desc',
        },
        filter: {
            ppob_name: '',
        },
    });

    React.useEffect(() => {
        onLoadData();
    }, []);

    const onLoadData = async () => {
        setLoading(true);
        const results = await servicePPOBProduct.listProduct(queryString);
        if (results.isSuccess) {
            setDataProductList(results.response);
            setLoading(false);
        } else {
            notifyError(results.response.message);
        }
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 70,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * 10 + index + 1,
        },
        {
            title: 'Nama Produk PPOB',
            dataIndex: 'ppob_name',
            key: 'ppob_name',
        },
        {
            title: 'Kategori PPOB',
            dataIndex: 'ppob_type',
            key: 'ppob_type',
        },
        {
            title: 'Harga Modal',
            dataIndex: 'deposit_price',
            key: 'deposit_price',
            render: (_, record) => {
                return <span>{formatMoney(record.deposit_price)}</span>;
            },
        },
        {
            title: 'Harga Jual',
            dataIndex: 'selling_price',
            key: 'selling_price',
            render: (_, record) => {
                return <span>{formatMoney(record.selling_price)}</span>;
            },
        },
        {
            title: 'Margin',
            dataIndex: 'margin',
            key: 'margin',
            render: (_, record) => {
                return <span>{formatMoney(record.margin)}</span>;
            },
        },
        {
            title: 'Tanggal & Jam Update',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (item) => moment.utc(item).format('DD MMM YYYY HH:mm:ss'),
        },

        {
            title: 'Aksi',
            render: (_, record) => {
                return (
                    <div
                        key={record.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                        }}>
                        <Tooltip title="Edit">
                            <FormOutlined
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    history(`/ppob/product/${record.id}`)
                                }
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteFilled
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    ModalRemove({
                                        configAPI: {
                                            url: `/ppob/product/${record.id}`,
                                            method: 'DELETE',
                                            version: 'v1',
                                            token: true,
                                            successMessage:
                                                'Sukses menghapus data produk',
                                        },
                                        onReGetData: () => {
                                            onLoadData(queryString);
                                        },
                                        title: 'Konfirmasi Hapus',
                                        desc: 'Apakah Anda yakin ingin menghapus data Produk ?',
                                    });
                                }}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'PRODUK PPOB',
        },
        {
            path: 'list',
            breadcrumbName: 'LIST',
        },
    ];

    const handleChange = (pagination, filters, sorter) => {
        let paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (filters.status != null) {
            paramResult.filters = {
                status: filters.status,
            };
        } else {
            delete paramResult.filters;
        }
        setQueryString(paramResult);
        setTimeout(() => {
            onLoadData(paramResult);
        }, 500);
    };

    const onSearchByName = (value) => {
        let resultQuery = { ...queryString };
        resultQuery.queries.page = 1;
        resultQuery.filter['ppob_name'] = value;
        setQueryString(resultQuery);
        onLoadData(resultQuery);
    };

    return (
        <PPOBProductListStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history(-1)}
                    className="site-page-header"
                    title="PPOB "
                    breadcrumb={{ routes }}
                    subTitle="Table PRODUK PPOB "
                />
            </div>
            <div className="btn-top-table">
                <Button
                    onClick={() => history('/ppob/product/add')}
                    type="primary"
                    style={{ marginBottom: 10 }}>
                    Tambah Produk PPOB
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Search by name"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    className: 'pagination',
                    total: dataProductList.total_payload,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                    defaultPageSize: 10,
                }}
                bordered
                columns={columns}
                dataSource={dataProductList.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </PPOBProductListStyle>
    );
};

export default PPOBProductList;
