import { DeleteFilled, FormOutlined } from '@ant-design/icons';
import { Button, PageHeader, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ModalRemove from '../../../components/ModalRemove';
import { notifyError } from '../../../utils';
import serviceKiddofestGallery from './index.service';
import KiddofestGalleryStyle from './index.style';

export default function KiddofestGallery() {
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useNavigate();
    const [queryString, setQueryString] = React.useState({
        queries: {
            row: 10,
            page: 1,
        },
        filters: {
            'kiddofest_gallery.year': '',
        },
    });
    const [dataGallery, setDataGallery] = React.useState([]);

    React.useEffect(() => {
        handleLoadData(queryString);
        return () => {
            setDataGallery([]);
        };
    }, []);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        const resultQuery = { ...params };
        const result = await serviceKiddofestGallery.getListGallery(resultQuery);
        if (result.isSuccess) {
            setDataGallery(result.response);
        } else {
            notifyError(result.response.message);
        }
        setIsLoading(false);  // Reset loading state
    };

    const handleChange = (pagination, filters, sorter) => {
        const paramResult = { ...queryString };
        paramResult.queries.row = pagination.pageSize;
        paramResult.queries.page = pagination.current;

        if (!sorter.order) {
            delete paramResult.sorts;
        } else {
            paramResult.sorts = {
                [sorter.field]: sorter.order === 'descend' ? 'desc' : 'asc',
            };
        }

        if (filters.status != null) {
            paramResult.filters.status = filters.status;
        } else {
            delete paramResult.filters.status;
        }

        setQueryString(paramResult);

        setTimeout(() => {
            handleLoadData(paramResult);
        }, 500);  // Optional delay to simulate search debouncing
    };

    const columns = [
        {
            title: 'No',
            dataIndex: '',
            width: 60,
            key: 'no',
            render: (item, record, index) =>
                (queryString.queries.page - 1) * queryString.queries.row + index + 1,
        },
        {
            title: 'Tahun',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: 'Skala Foto',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Foto',
            dataIndex: 'image',
            key: 'image',
            render: (value) => <img src={value} width={180} alt="Gallery" />,
        },
        {
            title: 'Aksi',
            dataIndex: '',
            render: (_, record) => (
                <div
                    key={record.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Tooltip title="Edit">
                        <FormOutlined
                            style={{ cursor: 'pointer' }}
                            onClick={() => history(`/kiddofest/gallery/${record.id}`)}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteFilled
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                ModalRemove({
                                    configAPI: {
                                        url: `/kiddofest/gallery/${record.id}`,
                                        method: 'DELETE',
                                        version: 'v1',
                                        token: true,
                                        successMessage: 'Sukses Hapus Data',
                                    },
                                    onReGetData: () => {
                                        handleLoadData(queryString);
                                    },
                                    title: 'Konfirmasi Hapus Foto!',
                                    desc: 'Apakah anda yakin ingin menghapus foto ini?',
                                });
                            }}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const routes = [
        {
            path: 'index',
            breadcrumbName: 'GALERI KIDDOFEST',
        },
        {
            path: 'list',
            breadcrumbName: 'LIST TABLE',
        },
    ];

    const onSearchByName = (value) => {
        const resultQuery = { ...queryString };
        resultQuery.filters['kiddofest_gallery.year'] = value;
        setQueryString(resultQuery);
        handleLoadData(resultQuery);
    };

    return (
        <KiddofestGalleryStyle>
            <div className="header">
                <PageHeader
                    onBack={() => history(-1)}
                    className="site-page-header"
                    title="GALERI KIDDOFEST"
                    breadcrumb={{ routes }}
                    subTitle=": MOMENT KIDDOFEST DIMULAI TAHUN 2018"
                />
            </div>
            <div className="btn-top-table">
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="/kiddofest/gallery/add">+ Tambah Galeri</Link>
                </Button>
                <div className="container-search">
                    <Search
                        placeholder="Cari berdasarkan tahun"
                        onSearch={(value) => onSearchByName(value)}
                        enterButton
                        allowClear
                    />
                </div>
            </div>

            <Table
                loading={isLoading}
                pagination={{
                    current: queryString.queries.page,
                    defaultCurrent: 1,
                    total: dataGallery.total_payload,
                    pageSize: queryString.queries.row,
                    showTotal: (total, range) =>
                        `${range[0]}-${range[1]} of ${total} items`,
                }}
                bordered
                columns={columns}
                dataSource={dataGallery.payload}
                showSorterTooltip={true}
                onChange={handleChange}
            />
        </KiddofestGalleryStyle>
    );
}
