import React, { useState, useEffect } from 'react';
import {
    Button,
    Input,
    PageHeader,
    Pagination,
    Select,
    Space,
    Table,
    Tag,
    message,
} from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import MerchantListStyle from './index.style';
import serviceMerchantList from './index.service';
import { SearchOutlined } from '@ant-design/icons';
import { formatDate } from '../../../utils/format-date';

const MerchantList = () => {
    const history = useNavigate();
    const { Option } = Select;
    const [merchantList, setMerchantList] = useState([]);
    const [dataFilterMerchant, setDataFilterMerchant] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(10);
    const [queryString, setQueryString] = useState({
        filters: {},
        queries: {
            page: 1,
            row: 10,
        },
    });
    const [searchValue, setSearchValue] = useState({});

    useEffect(() => {
        handleLoadData(queryString);
        getMerchant({ search: 'all' });
    }, [queryString]);

    const handleLoadData = async (params) => {
        setIsLoading(true);
        let resultQuery = { ...params };
        try {
            const result = await serviceMerchantList.getMerchantList(resultQuery);
            if (result.isSuccess) {
                setMerchantList(result.response);
            } else {
                setMerchantList([]);
                message.error('Failed to load merchant data');
            }
        } catch (error) {
            message.error('An error occurred while loading merchant data');
        } finally {
            setIsLoading(false);
        }
    };

    const getMerchant = async (params) => {
        try {
            const result = await serviceMerchantList.getMerchantList2(params);
            if (result.isSuccess) {
                handleDataMerchantFilter(result.response.payload);
            } else {
                message.error('Failed to fetch merchant data!');
            }
        } catch (error) {
            message.error('An error occurred while fetching merchant data');
        }
    };

    const handleDataMerchantFilter = (data = []) => {
        if (data.length > 0) {
            setDataFilterMerchant(
                data.map((item) => ({
                    text: item.name,
                    value: item.name,
                }))
            );
        }
    };

    const getColumnSelectProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Select
                    showSearch
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                    optionFilterProp="children"
                    value={searchValue[dataIndex] || ''}
                    onSelect={(val) => handleSearch(val, confirm, dataIndex, true)}
                >
                    {dataFilterMerchant.map((el, idx) => (
                        <Option key={idx} value={el.value}>
                            {el.text}
                        </Option>
                    ))}
                </Select>
                <Button
                    type="primary"
                    block
                    onClick={() => resetFilter()}
                >
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={searchValue[dataIndex] || ''}
                    onChange={(e) =>
                        setSearchValue(prevState => ({
                            ...prevState,
                            [dataIndex]: e.target.value,
                        }))
                    }
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: '100%' }}
                >
                    Search
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
    });

    const handleSearch = (value, confirm, dataIndex, isComponentSelect = false) => {
        let resultQuery = { ...queryString };
        resultQuery.queries = {
            row: 10,
            page: 1,
        };
        if (isComponentSelect) {
            setSearchValue(prevState => ({
                ...prevState,
                [dataIndex]: value,
            }));
            resultQuery.filters = {
                [dataIndex]: value,
            };
        } else {
            resultQuery.filters = {
                [dataIndex]: searchValue[dataIndex],
            };
        }
        setQueryString(resultQuery);
        confirm(); // Ensure the dropdown is closed after selecting
    };

    const handleResetSearch = (clearFilters) => {
        resetFilter();
    };

    const handlePremiumLabel = (status) => {
        let color;
        if (status === 'Active') color = 'green';
        if (status === 'Inactive') color = 'volcano';
        return (
            <Tag color={color} key={status}>
                {status !== 'Not A Premium Merchant' && status.toUpperCase()}
            </Tag>
        );
    };

    const handleStatus = (status) => {
        let color;
        if (status === 'Approved') color = 'green';
        if (status === 'Pending') color = 'geekblue';
        return (
            <Tag color={color} key={status}>
                {status.toUpperCase()}
            </Tag>
        );
    };

    const onGenerateMerchantPacket = (value) => {
        switch (value) {
            case 0: return '';
            case 1: return 'Bronze';
            case 2: return 'Silver';
            case 3: return 'Gold';
            default: return '';
        }
    };

    const routes = [
        { path: 'index', breadcrumbName: 'Merchant' },
        { path: 'list', breadcrumbName: 'List' },
    ];

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => (
                <Link to={`/merchant/detail/${record.id}`}>{record.id}</Link>
            ),
            ...getColumnSearchProps('id_merchant'),
        },
        {
            title: 'Nama Merchant',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSelectProps('merchant_name'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('merchant_email'),
        },
        {
            title: 'Nama Paket',
            key: 'packet_name',
            render: (value) => onGenerateMerchantPacket(value.premium_package_id),
        },
        {
            title: 'Status Akun',
            dataIndex: 'approval_status',
            key: 'approval_status',
            filters: [
                { text: 'APPROVED', value: 'approved' },
                { text: 'PENDING', value: 'not approved' },
            ],
            filteredValue: queryString?.filters?.approval_status || null,
            filterMultiple: false,
            ellipsis: true,
            render: (status) => handleStatus(status),
            align: 'center',
        },
        {
            title: 'Status Transaksi',
            dataIndex: 'status_transaction',
            key: 'status_transaction',
        },
        {
            title: 'Mulai Paket',
            dataIndex: 'premium_start_date',
            key: 'premium_start_date',
            render: (value) => value === '0001-01-01 00:00:00' ? '' : formatDate(value),
        },
        {
            title: 'Exp. Paket',
            dataIndex: 'premium_expired_date', // Fixed typo from 'premium_expired_dare'
            key: 'premium_expired_date',
            render: (value) => value === '0001-01-01 00:00:00' ? '' : formatDate(value),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            filteredValue: queryString?.filters?.merchant_type || null,
            filters: [
                { text: 'Tutor', value: 'tutor' },
                { text: 'Community', value: 'community' },
                { text: 'Shop', value: 'shop' },
                { text: 'School', value: 'school' },
            ],
        },
        {
            title: 'Premium Status',
            dataIndex: 'premium_merchant_status',
            key: 'premium_merchant_status',
            render: (status) => handlePremiumLabel(status),
            align: 'center',
        },
        {
            title: 'Jumlah Aktivitas',
            dataIndex: 'activity_count',
            key: 'activity_count',
            align: 'center',
        },
        {
            title: 'History Paket',
            dataIndex: '',
            render: (_, record) => (
                <div
                    key={record.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                    }}
                >
                    <Button
                        onClick={() => history(`/merchant/history/${record.id}`)}
                        style={{ width: '100%' }}
                        type="dashed"
                    >
                        Detail
                    </Button>
                </div>
            ),
        },
    ];

    const onPaginationChange = (page, pageSize) => {
        setQueryString({
            ...queryString,
            queries: {
                page,
                row: pageSize,
            },
        });
        setCurrentPage(page);
        setTotalPage(pageSize);
    };

    const resetFilter = () => {
        setSearchValue({});
        setQueryString({
            filters: {},
            queries: {
                page: 1,
                row: 10,
            },
        });
        setCurrentPage(1);
        setTotalPage(10);
    };

    const handleTableChange = (pagination, filters) => {
        let paramResult = { ...queryString };
        if (filters.approval_status) {
            setQueryString({
                filters: {
                    ...paramResult.filters,
                    approval_status: filters.approval_status[0],
                },
                queries: {
                    ...paramResult.queries,
                },
            });
            return;
        }
        if (filters.type) {
            setQueryString({
                filters: {
                    ...paramResult.filters,
                    merchant_type: filters.type,
                },
                queries: {
                    ...paramResult.queries,
                },
            });
            return;
        }
        setQueryString(paramResult);
    };

    return (
        <div>
            <div className="header">
                <PageHeader
                    className="site-page-header"
                    title="Merchant List"
                    breadcrumb={{ routes }}
                    subTitle="Table of merchant list "
                    style={{ paddingLeft: 0 }}
                />
                <Button
                    onClick={resetFilter}
                    style={{ float: 'right' }}
                >
                    Reset Filter
                </Button>
            </div>
            <MerchantListStyle>
                <Button type="primary" style={{ marginBottom: 10 }}>
                    <Link to="merchant/create">+ Add New Merchant</Link>
                </Button>
                <Table
                    rowKey="id" // Adjusted to match data structure
                    loading={isLoading}
                    bordered
                    columns={columns}
                    dataSource={merchantList?.payload || []}
                    pagination={false}
                    onChange={handleTableChange}
                />
                <div className="pagination">
                    <Pagination
                        pageSize={totalPage}
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={merchantList?.total_payload || 0}
                        onChange={onPaginationChange}
                    />
                </div>
            </MerchantListStyle>
        </div>
    );
};

export default MerchantList;
